import './EditDeviceForm.scss';

import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import Button from '@mui/material/Button';
import { IDeviceView } from './EditDevice';
import { IDeviceEdit } from '../../interfaces/dto/IDeviceEdit';
import SensorsList from './SensorsList';
import { Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import { DeviceType } from '../../enums/DeviceType';
import { useDevicesApi } from '../../hooks/useDevicesApi';

export interface IFormSensorValues {
    id: number;
    type: string;
    port: number;
    equipmentId: number;
    equipmentName: string;
}

export interface IFormValues {
    iccid: string;
    phoneNumber: string;
    type: string;
    sensors: IFormSensorValues[];
}

const getInitialValues = (device: IDeviceView): IFormValues => {
    const initialValues: IFormValues = {
        iccid: '',
        type: 'Unknown',
        phoneNumber: '',
        sensors: []
    };

    if (device) {
        initialValues.iccid = device.iccid;
        initialValues.phoneNumber = device.phoneNumber;
        initialValues.type = device.type;
        initialValues.sensors = [...device.sensors];
    }

    return initialValues;
};

const getValidationSchema = (): any => {
    let validationSchema: any = Yup.object().shape({});
    return validationSchema;
};

interface IEditDeviceFormProps {
    device: IDeviceView;
    onSave: (farmId: number) => void;
    onWWDeviceSetup: () => void;
    onCancel: (farmId: number) => void;
}

const EditDeviceForm: React.FC<IEditDeviceFormProps> = ({
    device,
    onSave,
    onWWDeviceSetup,
    onCancel,
}) => {
    const { setDevice, assignSensorToEquipment } = useDevicesApi();

    const initialValues = getInitialValues(device);
    const validationSchema = getValidationSchema();

    const isWashWatchDevice = device.type === DeviceType.WashWatch;

    const handleEdit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        const deviceSettings: IDeviceEdit = {
            farmId: device.farmId,
            phoneNumber: values.phoneNumber
        };

        await setDevice(device.id, deviceSettings);

        values.sensors
            .forEach(async (sensor) => {
                await assignSensorToEquipment(device.id, sensor.equipmentId, sensor.id);
            });


        helpers.setSubmitting(false);
        onSave(device.farmId);
    };

    const handleSubmit = (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            helpers.setSubmitting(true);
            handleEdit(values, helpers);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, submitForm, isSubmitting, isValid }) => {
                return (
                    <Form className="mm-edit-device-form" noValidate autoComplete="off">
                        <div className="mm-edit-device-form__header">
                            <div className="mm-edit-device-form__header__subtitle">{device.farmName}</div>
                            <div className="mm-edit-device-form__header__title">{device.iccid}</div>                            
                        </div>
                        <div className="mm-edit-device-form__content">
                            <div className="mm-edit-device-form__first-block">
                                <Stack direction="column" gap={2}>
                                    <Field
                                        name="type"
                                        component={TextField}
                                        size="small"
                                        disabled
                                        variant="standard"
                                        label="Device type"
                                        fullWidth
                                    />
                                    <Field
                                        name="iccid"
                                        component={TextField}
                                        size="small"
                                        disabled
                                        variant="standard"
                                        label="Hardware ID"
                                        fullWidth
                                    />
                                    {isWashWatchDevice && (
                                        <Field
                                            name="phoneNumber"
                                            component={TextField}
                                            size="small"
                                            variant="standard"
                                            label="Phone number"
                                            fullWidth
                                        />
                                    )}
                                </Stack>
                            </div>
                            <div className="mm-edit-device-form__second-block">
                                <SensorsList
                                    values={values}
                                    dairyEquipments={device.dairyEquipments}
                                />
                            </div>
                        </div>
                        <div className="mm-edit-device-form__footer">
                            <div className='mm-edit-device-form__footer__actions'>
                                <Button
                                    variant="contained"
                                    disabled={!isValid || isSubmitting}
                                    onClick={submitForm}>
                                    Save & Exit
                                </Button>
                                {isWashWatchDevice && (
                                <Button
                                    variant="contained"
                                    disabled={!isValid || isSubmitting}
                                    onClick={() => onWWDeviceSetup()}>
                                    Update WashWatch Device
                                </Button>
                                )}
                            </div>
                            <Button
                                variant="outlined"
                                onClick={() => onCancel(device.farmId)}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditDeviceForm;
