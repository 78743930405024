import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { IEquipmentInfo } from '../interfaces/dto/IEquipmentInfo';
import { IEquipmentEdit } from '../interfaces/dto/IEquipmentEdit';
import { ISensorLog } from '../interfaces/dto/ISensorLog';
import { IAlert } from '../interfaces/dto/IAlert';
import { LogType } from './equipments';

export interface IGetSensorLogQueryParams {
    farmId?: number;
    logType?: LogType;
    startDateTime?: string;
    endDateTime?: string;
    pageSize?: number;
    pageIndex?: number;
    sortExpression?: string;
}

export const useEquipmentsApi = () => {
    const axios = useAxiosPrivate();

    const getEquipment = useCallback(
        async (
            equipmentId: number,
            evaluated: boolean
        ): Promise<AxiosResponse<IEquipmentInfo, any>> => {
            return axios.get<IEquipmentInfo>(`/2/equipment/${equipmentId}?evaluated=${evaluated}`);
        },
        [axios]
    );

    const setEquipment = useCallback(
        async (equipmentId: number, equipment: IEquipmentEdit) => {
            return axios.put(`/2/equipment/${equipmentId}`, equipment);
        },
        [axios]
    );

    const createEquipment = useCallback(
        async (equipment: IEquipmentEdit): Promise<AxiosResponse<{ equipmentId: number }, any>> => {
            return axios.post(`/2/equipment`, equipment);
        },
        [axios]
    );

    const getEquipmentAlerts = useCallback(
        async (equipmentId: number) => {
            return axios.get<IAlert[]>(`/2/equipment/${equipmentId}/alerts`);
        },
        [axios]
    );

    const getEquipmentLog = useCallback(
        async (equipmentId: number, params?: IGetSensorLogQueryParams) => {
            return axios.get<ISensorLog[]>(`/2/equipment/${equipmentId}/log`, {
                params,
            });
        },
        [axios]
    );

    return {
        getEquipment,
        getEquipmentAlerts,
        getEquipmentLog,
        setEquipment,
        createEquipment,
    };
};
