import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { IAlert } from '../interfaces/dto/IAlert';

export const useAlertsApi = () => {
    const axios = useAxiosPrivate();

    const getAlerts = useCallback(
        async (
            pageIndex: number = 1,
            pageSize: number = 50,
            sortExpression: string = '-Timestamp'
        ): Promise<AxiosResponse<IAlert[], any>> => {
            const params = {
                pageIndex,
                pageSize,
                sortExpression,
            };

            return axios.get<IAlert[]>(`/1/alerts`, { params });
        },
        [axios]
    );

    return {
        getAlerts,
    };
};
