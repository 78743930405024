import React from 'react';
import { Objects } from '../../utils/objects';
import './Button.scss';

export interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    type?: 'button' | 'submit' | 'reset';
    children: React.ReactNode;
}

export const Button: React.FC<IButtonProps> = ({
    className,
    type = 'button',
    children,
    ...rest
}) => {
    let classes = 'mm-button';
    if (Objects.isSet(className)) {
        classes = `${classes} ${className}`;
    }

    return (
        <button className={classes} type={type} {...rest}>
            {children}
        </button>
    );
};
