import React from 'react';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';

interface IUsePerWashGraphProps {
    log: ISensorLog[];
    targetUsePerWash?: number;
}

const UsePerWashGraph: React.FC<IUsePerWashGraphProps> = ({ log, targetUsePerWash }) => {
    const options: any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                label: {
                    formatter: (param: { value: string }) =>
                        dayjs(param.value).format('MM/DD/YYYY'),
                },
            },
            confine: true,
        },
        grid: {
            left: 30,
            right: 0,
            top: 10,
            bottom: 25,
            containLabel: 'true'
        },
        legend: {
            show: true,
            selectedMode: false,
            bottom: 0,
            itemWidth: 45,
            itemGap: 10,
            itemStyle: {
                color: 'transparent',
            },
            data: ['Target Ounces'],
        },
        textStyle: {
            fontFamily: 'Roboto',
            fontWeight: '500',
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
        },
        yAxis: {
            name: 'OZ Pumped',
            nameLocation: 'center',
            nameTextStyle: {
                fontSize: 16,
            },
            nameGap: 35,
            type: 'value',
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: 0,
                minSpan: 1,
                orient: 'horizontal',
                zoomOnMouseWheel: true,
                moveOnMouseMove: true,
                moveOnMouseWheel: false,
                startValue: dayjs().subtract(1, 'month').startOf('day').format(),
            }
        ],
        animation: false,
        series: [
            {
                data: log.map(l => [l.timestamp, (l.value < 0 ? 0 : l.value) * 128]),
                type: 'bar',
                name: 'Pumped, OZ',
                symbol: 'none',
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'blue',
                            },
                            {
                                offset: 0.6,
                                color: 'white',
                            },
                        ],
                        global: false,
                    },
                    opacity: 0.3,
                },
                tooltip: {
                    valueFormatter: (value: Number) => value.toFixed(2),
                },
            },
        ],
        useUtc: true,
    };
 
    const maxValue = Math.max(...log.map(l => Number(l.value * 128)), targetUsePerWash ?? 0);
    options.yAxis.max = Math.min(maxValue, (targetUsePerWash ?? maxValue) * 5);

    if (targetUsePerWash) {
        options.series.push({
            data: [],
            type: 'line',
            name: 'Target Ounces',
            symbol: 'none',
            lineStyle: {
                color: '#ff0000',
                type: 'solid',
            },
            markLine: {
                silent: true,
                symbol: 'none',
                data: [
                    {
                        yAxis: targetUsePerWash, //.toFixed(0),
                        lineStyle: {
                            color: '#ff0000',
                            type: 'solid',
                        },
                    },
                ],
                label: {
                    position: 'insideEndTop'
                }
            },
        });
    }

    return <ReactECharts style={{ height: '100%', width: '100%' }} option={options} />;
};

export default UsePerWashGraph;
