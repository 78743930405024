import { createContext, FC, useState, Dispatch, SetStateAction } from 'react';

export type AppState = {
    isForceRefresh: boolean;
    subscriptionsCount: number;
    setIsForceRefresh: Dispatch<SetStateAction<boolean>>;
    setSubscriptionsCount: Dispatch<SetStateAction<number>>;
};

export const AppContext = createContext<AppState>({
    isForceRefresh: false,
    subscriptionsCount: 0,
    setIsForceRefresh: () => void 0,
    setSubscriptionsCount: () => void 0,
});

type AppProviderProps = {
    children?: React.ReactNode;
};

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
    const [isForceRefresh, setIsForceRefresh] = useState(false);
    const [subscriptionsCount, setSubscriptionsCount] = useState(0);

    return (
        <AppContext.Provider
            value={{
                isForceRefresh,
                subscriptionsCount,
                setIsForceRefresh,
                setSubscriptionsCount,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
