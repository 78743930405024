import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useAuth } from '../../hooks/contexts/useAuth';
import { useLogin } from '../../hooks/useLogin';
import { Input } from '../../components/Input';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import logo from '../../assets/img/logo-full.png';
import './Login.scss';

export const Login: React.FC = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const auth = useAuth();
    const login = useLogin();
    const navigate = useNavigate();

    const loginInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => loginInputRef.current?.focus(), []);

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            await login(userName, password, remember);
            navigate('/', { replace: true });
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleError = (error: unknown): void => {
        let err = error as AxiosError;
        if (err?.response?.status === 401) {
            setError('Incorrect username or password');
        } else {
            setError('Internal server error');
        }
    };

    if (auth.accessToken) {
        return <Navigate to={'/'} replace={true} />;
    }

    return (
        <div className="login-page">
            <div className="login-page__overlay"></div>
            <div className="login-page__form-wrapper">
                <img className="login-page__logo" src={logo} alt="Logo" />
                <form className="login-page__form" onSubmit={handleFormSubmit}>
                    <Input
                        className="login-page__login-input"
                        type="text"
                        placeholder="Login"
                        ref={loginInputRef}
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        disabled={isLoading}
                        required
                    />
                    <Input
                        className="login-page__password-input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        disabled={isLoading}
                        required
                    />
                    <Checkbox
                        className="login-page__remember-checkbox"
                        checked={remember}
                        onChange={e => setRemember(e.target.checked)}
                        disabled={isLoading}
                    >
                        Keep me signed in
                    </Checkbox>
                    {error && <p className="login-page__error-message">{error}</p>}
                    <Button className="login-page__login-button" type="submit">
                        {isLoading ? (
                            <LoadingSpinner
                                color="#f8f8ff"
                                cssOverride={{
                                    display: 'block',
                                    marginBottom: '-7px',
                                }}
                            />
                        ) : (
                            'Sign In'
                        )}
                    </Button>
                </form>
            </div>
        </div>
    );
};
