import './EditPipelineForm.scss';

import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Stack } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import { IEquipmentEdit, INotificationContact } from '../../interfaces/dto/IEquipmentEdit';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { TextField } from 'formik-mui';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { TemperatureUnit } from '../../utils/temperature';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { useEquipmentsApi } from '../../hooks/useEquipmentsApi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EquipmentAlertsSubform, { IEquipmentAlertsFormValues } from './EquipmentAlertsSubform';


// All messages for this component.
const MESSAGES = {
    evaluatedSettingsDialog: {
        content: 'The form fields will be recalculated based on device log for the last 3 days. Do you want to continue?',
        confirm: 'Yes',
        cancel: 'No',
    },
};

export interface IFormValues extends IEquipmentAlertsFormValues {
    name: string;
    washCyclesPerDay: number | null | undefined;
    targetWashTemperature: number;
    targetMilkTemperature: number;
    checkMilkTemperature: boolean;
    warmTimer: number;
    wash1Time: Dayjs | null;
    wash2Time: Dayjs | null;
    wash3Time: Dayjs | null;
}

const getInitialValues = (equipment: IEquipmentInfo): IFormValues => {
    const { name, pipelineSettings, alertCategories } = equipment;

    let initialValues: IFormValues = {
        name: name,
        targetWashTemperature: 0,
        targetMilkTemperature: 0,
        checkMilkTemperature: false,
        warmTimer: 0,
        wash1Time: null,
        wash2Time: null,
        wash3Time: null,
        washCyclesPerDay: 0,
        alertCategories: [],
    };

    if (pipelineSettings) {
        initialValues = {
            name: name,
            targetWashTemperature: pipelineSettings.targetWashTemperature,
            targetMilkTemperature: pipelineSettings.targetMilkTemperature,
            checkMilkTemperature: pipelineSettings.checkMilkTemperature,
            warmTimer: pipelineSettings.warmTimer,
            wash1Time: pipelineSettings.wash1Time ? dayjs(pipelineSettings.wash1Time) : null,
            wash2Time: pipelineSettings.wash2Time ? dayjs(pipelineSettings.wash2Time) : null,
            wash3Time: pipelineSettings.wash3Time ? dayjs(pipelineSettings.wash3Time) : null,
            washCyclesPerDay: pipelineSettings.washCyclesPerDay,
            alertCategories: alertCategories,
        };
    }

    return initialValues;
}

const getValidationSchema = (): any => {
    let validationSchema: any = Yup.object().shape({
        name: Yup.string()
            .required(),
        washCyclesPerDay: Yup.number()
            .positive()
            .integer()
            .label('Wash cycles per day')
            .typeError('Wash cycles per day must be a number'),
        targetWashTemperature: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Highest wash temperature')
            .typeError('Highest wash temperature must be a number'),
        targetMilkTemperature: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Target milk temperature')
            .typeError('Target milk temperature must be a number'),
        warmTimer: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Warm milk timer')
            .typeError('Warm milk timer must be a number'),
        wash1time: Yup.object()
            .nullable()
            .label('Wash 1 time'),
        wash2time: Yup.object()
            .nullable()
            .label('Wash 2 time'),
        wash3time: Yup.object()
            .nullable()
            .label('Wash 3 time'),
    });

    return validationSchema;
}

const formatWashTime = (datetime: Dayjs | null): string | null => {
    if (!datetime || !datetime.isValid()) return null;

    return datetime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

interface IEditPipelineFormProps {
    equipment: IEquipmentInfo;
    farmContacts: IDairyContact[];
    farmName: string;
    onCancel: () => void;
    onSave: () => void;
    onChangeEvaluatedState: () => void;
}

const EditPipelineForm: React.FC<IEditPipelineFormProps> = ({
    equipment,
    farmContacts,
    farmName,
    onCancel,
    onSave,
    onChangeEvaluatedState,
}) => {
    const { farmId, equipmentId } = useParams();
    const { setEquipment, createEquipment } = useEquipmentsApi();
    const [notificationContacts, setNotificationContacts] = useState<INotificationContact[]>(equipment.notificationContacts);

    const initialValues = getInitialValues(equipment);
    const validationSchema = getValidationSchema();
    const [isOpen, setIsOpen] = useState(false);

    const handleEdit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        const equipmentEdit: IEquipmentEdit = {
            farmId: parseInt(farmId!),
            name: values.name,
            type: "Pipeline",
            notificationContacts: notificationContacts,
            alertCategories: values.alertCategories,
            pipelineSettings: {
                washCyclesPerDay: values.washCyclesPerDay ? +values.washCyclesPerDay : null,
                targetWashTemperature: +values.targetWashTemperature,
                targetMilkTemperature: +values.targetMilkTemperature,
                checkMilkTemperature: values.checkMilkTemperature,
                warmTimer: +values.warmTimer,
                wash1Time: formatWashTime(values.wash1Time),
                wash2Time: formatWashTime(values.wash2Time),
                wash3Time: formatWashTime(values.wash3Time)
            },
        };

        if (parseInt(equipmentId!) === 0) {
            await createEquipment(equipmentEdit);
        }
        else {
            await setEquipment(parseInt(equipmentId!), equipmentEdit);
        }


        helpers.setSubmitting(false);
        onSave();
    }

    const handleSubmit = (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            helpers.setSubmitting(true);
            handleEdit(values, helpers);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Dialog open={isOpen}>
                <DialogContent>{MESSAGES.evaluatedSettingsDialog.content}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onChangeEvaluatedState();
                            setIsOpen(false);
                        }}
                    >
                        {MESSAGES.evaluatedSettingsDialog.confirm}
                    </Button>
                    <Button onClick={() => setIsOpen(false)}>
                        {MESSAGES.evaluatedSettingsDialog.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, submitForm, isSubmitting, isValid, setFieldValue }) => {
                    return (
                        <Form
                            className="mm-edit-pipeline-form"
                            noValidate
                            autoComplete="off"
                        >
                            <div className="mm-edit-pipeline-form__header">
                                <div className="mm-edit-pipeline-form__header__subtitle">{farmName}</div>
                                <div className="mm-edit-pipeline-form__header__title">{equipment.id === 0 ? 'New pipeline': equipment.name}</div>
                            </div>
                            <div className="mm-edit-pipeline-form__content">
                                <div className="mm-edit-pipeline-form__first-block">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack direction="column" gap={2}>
                                            <Field
                                                name="name"
                                                label="Name"
                                                component={TextField}
                                                variant="standard"
                                                size="small"
                                                fullWidth
                                            />
                                            <Field
                                                name="washCyclesPerDay"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label="Wash cycles per day"
                                                fullWidth
                                            />
                                            <Field
                                                name="targetWashTemperature"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label={`Highest wash temperature, ${TemperatureUnit.FAHRENHEIT}`}
                                                fullWidth
                                            />
                                            <Field
                                                name="targetMilkTemperature"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label={`Target milk temperature, ${TemperatureUnit.FAHRENHEIT}`}
                                                fullWidth
                                            />
                                            <Field
                                                name="warmTimer"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label="Warm milk timer, minutes"
                                                fullWidth
                                            />
                                            <Field name="wash1Time">
                                                {({ field, form }: FieldProps) => (
                                                    <TimePicker
                                                        label="Wash 1 time"
                                                        value={field.value}
                                                        onChange={value =>
                                                            form.setFieldValue('wash1Time', value)
                                                        }
                                                        disabled={form.isSubmitting}
                                                        renderInput={params => (
                                                            <MuiTextField
                                                                size="small"
                                                                variant="standard"
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="wash2Time">
                                                {({ field, form }: FieldProps) => (
                                                    <TimePicker
                                                        label="Wash 2 time"
                                                        value={field.value}
                                                        onChange={value =>
                                                            form.setFieldValue('wash2Time', value)
                                                        }
                                                        disabled={form.isSubmitting}
                                                        renderInput={params => (
                                                            <MuiTextField
                                                                size="small"
                                                                variant="standard"
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="wash3Time">
                                                {({ field, form }: FieldProps) => (
                                                    <TimePicker
                                                        label="Wash 3 time"
                                                        value={field.value}
                                                        onChange={value =>
                                                            form.setFieldValue('wash3Time', value)
                                                        }
                                                        disabled={form.isSubmitting}
                                                        renderInput={params => (
                                                            <MuiTextField
                                                                size="small"
                                                                variant="standard"
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </Field>
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                                <div className="mm-edit-pipeline-form__second-block">
                                    <EquipmentAlertsSubform
                                        farmContacts={farmContacts}
                                        notificationContacts={notificationContacts}
                                        setNotificationContacts={setNotificationContacts}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                </div>
                            </div>
                            <div className="mm-edit-pipeline-form__footer">
                                <div className="mm-edit-pipeline-form__footer__actions">
                                    <Button
                                        variant="contained"
                                        disabled={!isValid || isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Save & Exit
                                    </Button>
                                    <Button variant="contained" onClick={() => setIsOpen(true)}>
                                        Evaluate
                                    </Button>
                                </div>
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default EditPipelineForm;

