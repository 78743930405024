import React from 'react';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';

interface ILevelRemainingGraphProps {
    log: ISensorLog[];
    capacity?: number;
}

const LevelRemainingGraph: React.FC<ILevelRemainingGraphProps> = ({ log, capacity }) => {
    const options: any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                label: {
                    formatter: (param: { value: string }) =>
                        dayjs(param.value).format('MM/DD/YYYY h:mm A'),
                },
            },
            confine: true,
        },
        grid: {
            left: 25,
            right: 10,
            top: 10,
            bottom: 0,
            containLabel: 'true'
        },
        legend: {
            show: false,
        },
        textStyle: {
            fontFamily: 'Roboto',
            fontWeight: '500',
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
        },
        yAxis: {
            name: 'Gallons',
            nameLocation: 'center',
            nameTextStyle: {
                fontSize: 16,
            },
            nameGap: 30,
            type: 'value',
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: 0,
                minSpan: 1,
                orient: 'horizontal',
                zoomOnMouseWheel: true,
                moveOnMouseMove: true,
                moveOnMouseWheel: false,
                startValue: dayjs().subtract(1, 'month').startOf('day').format()
            }
        ],
        animation: false,
        series: [
            {
                data: log.map(l => [l.timestamp, l.value]),                
                type: 'line',
                name: 'Remaining, gallons',
                symbol: 'none',
                sampling: 'lttb',
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'blue',
                            },
                            {
                                offset: 0.6,
                                color: 'white',
                            },
                        ],
                        global: false,
                    },
                    opacity: 0.3,
                },
                tooltip: {
                    valueFormatter: (value: Number) => value.toFixed(2),
                },
            },
        ],
        useUtc: true,
    };

    if (capacity) {
        options.yAxis.max = capacity;
    }

    return <ReactECharts style={{ height: '100%', width: '100%' }} option={options} />;
};

export default LevelRemainingGraph;
