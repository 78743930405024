import { Autocomplete, TextField, Typography } from "@mui/material";
import { IDairyContact } from "../../interfaces/dto/IDairyContact";

interface IContactsAutocompleteProps {
    title?: string;
    options: IDairyContact[];
    value?: any[],
    onChange: (value: any[]) => void;
};

const ContactsAutocomplete: React.FC<IContactsAutocompleteProps> = ({
    title,
    options,
    value,
    onChange,
}) => {
    return (
        <div>
            {title && (<Typography sx={{ py: 0.5 }}>{title}</Typography>)}            
            <Autocomplete
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField {...params} label="Notification contacts" variant="standard" />
                )}
                size="small"
                options={options}
                multiple
                fullWidth
                disableCloseOnSelect
                onChange={(_, value) => onChange(value)}
                value={value}
            />
        </div>
    );
};

export default ContactsAutocomplete;
