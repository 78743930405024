import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DairyCard.scss';

interface IDairyCardProps {
    id: number;
    name: string;
    address: string;
    contact: string;
    hasAlerts: boolean;
}

export const DairyCard: React.FC<IDairyCardProps> = ({ id, name, address, contact, hasAlerts }) => {
    const navigate = useNavigate();

    const handleArrowIconClick = (): void => {
        navigate(`/dairy-sensors/${id}`);
    };

    return (
        <div className="mm-dairy-card">
            <div className="mm-dairy-card__info">
                <h2 className="mm-dairy-card__text mm-dairy-card__text--header">{name}</h2>
                <div className="mm-dairy-card__content">
                    <p className="mm-dairy-card__text">{address}</p>
                    <p className="mm-dairy-card__text">{contact}</p>
                </div>
            </div>
            {hasAlerts && (
                <span className="mm-dairy-card__icon mm-dairy-card__icon--alert material-icons-outlined">
                    report
                </span>
            )}
            <span
                className="mm-dairy-card__icon mm-dairy-card__icon--arrow material-icons-outlined"
                onClick={handleArrowIconClick}
            >
                chevron_right
            </span>
        </div>
    );
};
