import React, { CSSProperties } from 'react';
import { TemperatureUnit } from '../../utils/temperature';

interface IVerticalMilkTankIconProps {
    currentTemperature?: number;
    level: number;
    hasAlerts: boolean;
}

const ALERT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '80px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const TEMPERATURE_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '22px',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const VerticalMilkTankIcon: React.FC<IVerticalMilkTankIconProps> = ({ currentTemperature, level, hasAlerts }) => {

    function calcMilkLevel(level: number): number {
        const yPosition = (20 + (120 - level * 1.2));
        return yPosition > 0 ? yPosition : 0;
    }

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 84.9 136.1" height="140px" enable-background="new 0 0 84.9 136.1" xmlSpace="preserve">
        <style type="text/css">
            {`.mtv0{fill:none;stroke:#CBCED1;stroke-width:4;stroke-miterlimit:10;}
            .mtv1{fill:#CBCED1;stroke:#353535;stroke-width:0.25;stroke-miterlimit:10;}
            .mtv2{fill:#2793CB;}
            .mtv3{fill:#383536;}
            .mtv4{clip-path:url(#SVGID_00000150823472968089279490000004114791852305579650_);fill:#EEE9E0;}`}
            </style>
        <g>
            <line className="mtv0" x1="81.9" y1="0" x2="81.9" y2="88.5"/>
            <line className="mtv0" x1="75.3" y1="15.1" x2="82" y2="16"/>
            <line className="mtv0" x1="81.4" y1="89" x2="74.9" y2="92"/>
            <circle className="mtv1" cx="81.9" cy="60.3" r="2.9"/>
            <circle className="mtv1" cx="81.9" cy="31.9" r="2.9"/>
            <circle className="mtv1" cx="81.9" cy="46.1" r="2.9"/>
            <circle className="mtv1" cx="81.7" cy="17.8" r="2.9"/>
            <circle className="mtv1" cx="81.9" cy="74.1" r="2.9"/>
            <circle className="mtv1" cx="81.9" cy="88" r="2.9"/>
        </g>
        <g>
            <path className="mtv2" d="M75.2,8.5V133c0,0.6-0.5,1.1-1.1,1.1H3.5c-0.8,0-1.5-0.7-1.5-1.5V16.5c0-3.9,16.4-7.1,36.6-7.1
                c3.4,0,6.6,0.1,9.7,0.2c0.6,0,1.2-0.5,1.2-1.1c0-0.6,0.5-1.1,1.1-1.1h23.5C74.7,7.4,75.2,7.9,75.2,8.5z"/>
            <rect x="51.3" y="2.8" className="mtv3" width="21.7" height="3"/>
            <g>
                <defs>
                    <path id="SVGID_2_" d="M75.2,8.5V133c0,0.6-0.5,1.1-1.1,1.1H3.1c-0.6,0-1.1-0.5-1.1-1.1V16.5c0-3.9,16.4-7.1,36.6-7.1
                        c3.4,0,6.6,0.1,9.7,0.2c0.6,0,1.2-0.5,1.2-1.1c0-0.6,0.5-1.1,1.1-1.1h23.5C74.7,7.4,75.2,7.9,75.2,8.5z"/>
                </defs>
                <clipPath id="SVGID_00000072278246813073192840000013679000110817733016_">
                    <use xlinkHref="#SVGID_2_"  overflow="visible"/>
                </clipPath>
                
                <rect x="1.7" 
                    y={calcMilkLevel(level) } 
                    clip-path="url(#SVGID_00000072278246813073192840000013679000110817733016_)" 
                    fill="#EEE9E0" 
                    width="73.8" 
                    height="140"/>
                <use xlinkHref="#SVGID_2_" overflow="visible" fill="none" stroke="#575052" stroke-width="4" stroke-miterlimit="10"/>
            </g>
            <g>
                <text style={TEMPERATURE_STYLE} x="50%" y="80%">{`${ currentTemperature ? Math.round(currentTemperature) + TemperatureUnit.FAHRENHEIT: "N/A"}`}</text>
            </g>
        </g>
            {hasAlerts && (
                <text style={ALERT_STYLE} x="50%" y="50%">
                    !
                </text>
                )}
        </svg>

    );
};

export default VerticalMilkTankIcon;
