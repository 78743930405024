import React from 'react';
import { Switch } from '../Switch';
import './SwitchList.scss';

interface ISwitchListOption {
    key: string;
    name: string;
    checked: boolean;
}

interface ISwitchListProps {
    options: ISwitchListOption[];
    onChange?: (key: string) => void;
}

export const SwitchList: React.FC<ISwitchListProps> = ({ options, onChange }) => {
    return (
        <div className="mm-switch-list">
            {options.map(option => (
                <div className="mm-switch-list__item" key={option.key}>
                    <label className="mm-switch-list__item-label">{option.name}</label>
                    <Switch
                        name={option.key}
                        checked={option.checked}
                        onChange={() => {
                            if (onChange) {
                                onChange(option.key);
                            }
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
