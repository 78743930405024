import './EditDairyEquipment.scss';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import { useEquipmentsApi } from '../../hooks/useEquipmentsApi';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import EditChemicalTankForm from './EditChemicalTankForm';
import EditPipelineForm from './EditPipelineForm';
import { useChemicalsApi } from '../../hooks/useChemicalsApi';
import { IChemical } from '../../interfaces/dto/IChemical';
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import SelectEquipmentTypeDialog from './SelectEquipmentTypeDialog';
import EditMilkTankForm from './EditMilkTankForm';

const defaultEquipment: IEquipmentInfo = {
    id: 0,
    name: "",
    type: "ChemicalTank",
    chemicalTankSettings: null,
    pipelineSettings: null,
    milkTankSettings: null,
    alertCategories: [],
    notificationContacts: [],
}

const EditDairyEquipment: React.FC = () => {
    const navigate = useNavigate();
    const { farmId, equipmentId: equipmentIdParam } = useParams();
    const equipmentId = parseInt(equipmentIdParam!);
    const { getEquipment } = useEquipmentsApi();
    const { getChemicals } = useChemicalsApi();
    const { getFarm } = useFarmsApi();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [equipment, setEquipment] = useState<IEquipmentInfo | undefined>();
    const [chemicals, setChemicals] = useState<IChemical[]>([]);
    const [farmContacts, setFarmContacts] = useState<IDairyContact[]>([]);
    const [farmName, setFarmName] = useState<string>('New dairy');
    const [equipmentType, setEquipmentType] = useState<string | undefined> ();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [evaluated, setEvaluated] = useState(searchParams.get('evaluated') === 'true');

    useEffect(() => {
        (async () => {
            const fetchDataForEditing = async () => {
                const { data: farm } = await getFarm(parseInt(farmId!));
                const { data: chemicals } = await getChemicals(farm.organizationId);
                setFarmContacts(farm.contacts ?? []);
                setFarmName(farm.name);

                if (equipmentId === 0) {
                    setIsPopupOpen(true);                
                }
                else {
                    const { data } = await getEquipment(equipmentId, evaluated);
                    setEquipmentType(data.type);
                    setEquipment(data);
                }

                setChemicals(chemicals);
            }

            try {
                setIsLoading(true);
                await fetchDataForEditing();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [ getChemicals, getEquipment, getFarm, evaluated ]);

    async function handleSelectEquipmentTypeDialogClose(params: any) {
        setIsPopupOpen(false);
        if (!params) {
            handleCancelClick();
            return;
        }
        setEquipmentType(params.equipmentType);

        setEquipment({
            ...defaultEquipment,
            type: params.equipmentType,
            alertCategories: 
                params.equipmentType === "Pipeline" ? [ 
                    {alertCategory: "MilkTemperature", alertCategoryName: "Milk Temperature", enabled: false },
                    {alertCategory: "ColdWash", alertCategoryName: "Cold Wash", enabled: false }]
                : params.equipmentType === "ChemicalTank" ? [ 
                    {alertCategory: "ChemicalLow", alertCategoryName: "Low Chemical", enabled: false },
                    {alertCategory: "ChemicalUsage", alertCategoryName: "Usage Dropped", enabled: false }]
                : [ 
                    {alertCategory: "MilkTemperature", alertCategoryName: "Milk Temperature", enabled: false },
                    {alertCategory: "ColdWash", alertCategoryName: "Cold Wash", enabled: false },
                    {alertCategory: "MilkTankFull", alertCategoryName: "Milk Tank Full", enabled: false },
                ]            
        });
    }

    const handleCancelClick = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        navigate(`/dairy-sensors/${farmId}`);
    };

    if (isLoading) {
        return (
            <div className="mm-edit-equipment">
                <LoadingSpinnerFullScreen />
            </div>
        );
    }

    return (
        <>
            <div className="mm-edit-equipment">
                {equipment && equipmentType === "ChemicalTank" && (
                    <EditChemicalTankForm
                        chemicals={chemicals}
                        equipment={equipment}
                        farmName={farmName}
                        farmContacts={farmContacts}
                        onSave={handleSaveClick}
                        onCancel={handleCancelClick}
                        onChangeEvaluatedState={(): void => {
                            setEvaluated(true);
                        }}
                    />
                )}
                {equipment && equipmentType === "Pipeline" && (
                    <EditPipelineForm
                        equipment={equipment}
                        farmContacts={farmContacts}
                        farmName={farmName}
                        onSave={handleSaveClick}
                        onCancel={handleCancelClick}
                        onChangeEvaluatedState={(): void => {
                            setEvaluated(true);
                        }}
                    />
                )}
                {equipment && equipmentType === "MilkTank" && (
                    <EditMilkTankForm
                        equipment={equipment}
                        farmContacts={farmContacts}
                        farmName={farmName}
                        onSave={handleSaveClick}
                        onCancel={handleCancelClick}
                        onChangeEvaluatedState={(): void => {
                            setEvaluated(true);
                        }}
                    />
                )}
            </div>
            {isPopupOpen && (
                <SelectEquipmentTypeDialog
                    onClose={async params => await handleSelectEquipmentTypeDialogClose(params)}
                />
            )}
        </>
    );
}

export default EditDairyEquipment;

