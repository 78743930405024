import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { RequireAuth } from './components/RequireAuth';
import { Dairies } from './pages/dairies';
import DairyDetails from './pages/DairyDetails/DairyDetails';
import DairySensors from './pages/DairySensors/DairySensors';
import { Login } from './pages/login';
import { Alerts } from './pages/alerts';
import { Layout } from './components/Layout';
import { useServiceWorker } from './hooks/useServiceWorker';
import './App.scss';
import EditDairyProfile from './pages/EditDairyProfile/EditDairyProfile';
import EditDevice from './pages/EditDevice/EditDevice';
import EditDairyEquipment from './pages/EditDairyEquipment/EditDairyEquipment';

const toastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    icon: false,
    progress: undefined,
    theme: 'dark',
};

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />,
    },
    {
        element: <Layout />,
        children: [{
            element: <RequireAuth />,
            children: [{
                path: '/',
                element: <Dairies />,
            },
            {
                path: '/dairy-details/:id',
                element: <DairyDetails />,
            },
            {
                path: '/dairy-sensors/:id',
                element: <DairySensors />,
            },
            {
                path: '/edit-dairy-profile/:id',
                element: <EditDairyProfile />,
            },
            {
                path: '/new-dairy',
                element: <EditDairyProfile />,
            },
            {
                path: '/edit-device/:id',
                element: <EditDevice />,
            },
            {
                path: '/dairy-details/:farmId/edit-equipment/:equipmentId',
                element: <EditDairyEquipment />,
            },
            {
                path: '/alerts',
                element: <Alerts />,
            }],
        }],
    },
]);

const App: React.FC = () => {
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();

    useEffect(() => {
        if (showReload && waitingWorker) {
            toast(
                () => (
                    <div className="mm-update-message">
                        A new version of application is available. Click{' '}
                        <a onClick={() => reloadPage()}>here</a> to update.
                    </div>
                ),
                toastOptions
            );
        }
    }, [waitingWorker, showReload, reloadPage]);

    return (
        <>
            <RouterProvider router={router} />
            <ToastContainer />
        </>
    );
};

export default App;
