import qs from 'qs';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { IDeviceDetails } from '../interfaces/dto/IDeviceDetails';
import { ISensor } from "../interfaces/dto/ISensor";
import { IDeviceEdit } from '../interfaces/dto/IDeviceEdit';
import { DeviceType } from '../enums/DeviceType';

export interface IDevicesQueryParams {
    organizationId?: number;
    equipmentTypes?: DeviceType[];
    hardwareId: string;
    isFarmAssigned: boolean;
    pageSize?: number;
    pageIndex?: number;
    sortExpression?: string;
}

export const useDevicesApi = () => {
    const axios = useAxiosPrivate();

    const getDevice = useCallback(
        async (
            deviceId: number,
        ): Promise<AxiosResponse<IDeviceDetails, any>> => {
            return axios.get<IDeviceDetails>(`/2/devices/${deviceId}`);
        },
        [axios]
    );

    const getDevices = useCallback(
        async (params: IDevicesQueryParams): Promise<AxiosResponse<IDeviceDetails[], any>> => {
            return axios.get<IDeviceDetails[]>('/2/devices', {
                params,
                paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
            });
        },
        [axios]
    );

    const getSensors = useCallback(
        async (deviceId: number): Promise<AxiosResponse<ISensor[], any>> => {
            return axios.get<ISensor[]>(`/2/devices/${deviceId}/sensors`);
        },
        [axios]
    );

    const setupBasic = useCallback(
        async (equipmentId: number) => {
            return axios.post(`/1/equipments/${equipmentId}/setupBasic`);
        },
        [axios]
    );

    const setupAdvanced = useCallback(
        async (equipmentId: number) => {
            return axios.post(`/1/equipments/${equipmentId}/setupAdvanced`);
        },
        [axios]
    );

    const setDevice = useCallback(
        async (deviceId: number, device: IDeviceEdit) => {
            return axios.put(`/2/devices/${deviceId}`, device);
        },
        [axios]
    );

    const assignSensorToEquipment = useCallback(
        async (deviceId: number, equipmentId: number, sensorId: number) => {
            return axios.put(`/2/devices/${deviceId}/sensors/${sensorId}/`, { id: equipmentId === 0 ? null : equipmentId });
        },
        [axios]
    );

    return {
        getDevice,
        getDevices,
        setDevice,
        getSensors,
        setupBasic,
        setupAdvanced,
        assignSensorToEquipment,
    };
};
