import { axiosPublic } from '../utils/axios';
import { ILoginResponse } from './useLogin';
import { AxiosResponse } from 'axios';

export const useAuthApi = () => {

    const renewTokens = async (
        accessToken: string | null,
        refreshToken: string | null
    ): Promise<AxiosResponse<ILoginResponse, any>> => {
        return axiosPublic.post<ILoginResponse>('/1/auth/refresh', {
            accessToken,
            refreshToken,
        });
    };

    const login = async (
        userName: string,
        password: string
    ): Promise<AxiosResponse<ILoginResponse, any>> => {
        return axiosPublic.post<ILoginResponse>('/1/auth/login', {
            userName,
            password,
        });
    };

    return {
        login,
        renewTokens,
    };
};