// import './SidePanelFilters.scss';
import React from 'react';
import { SidePanel } from './SidePanel';
import HomeFilter from '../HomeFilter';

export const SidePanelFilters: React.FC = () => {
  return (
    <SidePanel title="Filters">
      <HomeFilter />
    </SidePanel>
  )
};
