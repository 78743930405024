import { useCallback } from 'react';
import { IAlertType } from '../interfaces/dto/IAlertType';
import useAxiosPrivate from './useAxiosPrivate';

export const useGetAlertTypes = () => {
    const axios = useAxiosPrivate();

    const getAlertTypes = useCallback(async () => {
        return axios.get<IAlertType[]>(`/1/enums/alertTypes`).then(response => response.data);
    }, []);

    return getAlertTypes;
};
