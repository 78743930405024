import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthProvider';
import { HomeFilterProvider } from './contexts/HomeFilterProvider';
import { AppAlertsProvider } from './contexts/AppAlertsProvider';
import App from './App';
import './index.scss';
import { AppProvider } from './contexts/AppProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <AppProvider>
        <AuthProvider>
            <AppAlertsProvider>
                <HomeFilterProvider>
                    <App />
                </HomeFilterProvider>
            </AppAlertsProvider>
        </AuthProvider>
    </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
