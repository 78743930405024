import { CSSProperties, FC } from 'react';

const ALERT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 32,
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const TEXT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 7,
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};


type IndicatorProps = {
    hasAlerts: boolean;
    maxCapacity: boolean;
};

export const Indicator: FC<IndicatorProps> = ({ hasAlerts, maxCapacity }) =>
    hasAlerts ? (
        <text style={ALERT_STYLE} x="50%" y="60%">
            !
        </text>
    ) : 
    maxCapacity ? (
        <>
            <text style={TEXT_STYLE} x="50%" y="40%" >
                Max
            </text>
            <text style={TEXT_STYLE} x="50%" y="60%">
                capacity
            </text>
        </>
    ) : null;
