import React, { useEffect, useState } from 'react';
import { Storage, StorageKey } from '../../utils/storage';
import { useAlertsApi } from '../../hooks/useAlertsApi';
import { useAppAlerts } from '../../hooks/contexts/useAppAlerts';
import { DairiesMapView } from './DairiesMapView';
import { DairiesListView } from './DairiesListView';
import './Dairies.scss';

export enum DairiesView {
    MAP,
    LIST,
}

export const Dairies: React.FC = () => {
    const { getAlerts } = useAlertsApi();
    const { setHasAlerts } = useAppAlerts();

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await getAlerts(1, 1);
            const hasAlerts = data != null && data.length > 0;
            setHasAlerts(hasAlerts);
        };

        fetchData();
    }, [getAlerts, setHasAlerts]);

    const lastActiveView =
        Storage.getItem(StorageKey.DAIRIES_VIEW) === '1' ? DairiesView.LIST : DairiesView.MAP;
    const [activeView, setActiveView] = useState<DairiesView>(lastActiveView);

    const handleViewChange = (newView: DairiesView) => {
        setActiveView(newView);
        Storage.setItem(StorageKey.DAIRIES_VIEW, newView.toString());
    };

    return (
        <div className="mm-dairies">
            {activeView === DairiesView.MAP && <DairiesMapView onViewChange={handleViewChange} />}
            {activeView === DairiesView.LIST && <DairiesListView onViewChange={handleViewChange} />}
        </div>
    );
};
