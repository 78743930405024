enum AlertCode {
    ALERT_SYSTEM_ALIVE_0 = 'ALERT_SYSTEM_ALIVE_0',
    ALERT_CHEM_QUANT_1 = 'ALERT_CHEM_QUANT_1',
    ALERT_WASH_TEMP_2 = 'ALERT_WASH_TEMP_2',
    ALERT_CHEM_TANK_LOW_3 = 'ALERT_CHEM_TANK_LOW_3',
    ALERT_CHEM_SENS_FAIL_4 = 'ALERT_CHEM_SENS_FAIL_4',
    ALERT_TEMP_SENS_FAIL_5 = 'ALERT_TEMP_SENS_FAIL_5',
    ALERT_CHEM_SENS_RESTORED_6 = 'ALERT_CHEM_SENS_RESTORED_6',
    ALERT_TEMP_SENS_RESTORED_7 = 'ALERT_TEMP_SENS_RESTORED_7',
    ALERT_POWER_UP_8 = 'ALERT_POWER_UP_8',
    ALERT_WARM_MILK_9 = 'ALERT_WARM_MILK_9',
    ALERT_SD_CARD_STATUS_10 = 'ALERT_SD_CARD_STATUS_10',
    ALERT_WARM_REMINDER_11 = 'ALERT_WARM_REMINDER_11',
    ALERT_EVAL_PERIOD_FINISHED_101 = 'ALERT_EVAL_PERIOD_FINISHED_101',
    ALERT_MILK_TANK_FULL_REMINDER_102 = 'ALERT_MILK_TANK_FULL_REMINDER_102',
    ALERT_MILK_TANK_FULL_103 = 'ALERT_MILK_TANK_FULL_103',    
}

type AlertCodeTitleMap = {
    [key: string]: string;
};

class AlertService {
    private static map: AlertCodeTitleMap = {
        [AlertCode.ALERT_SYSTEM_ALIVE_0]: 'New device found',
        [AlertCode.ALERT_CHEM_QUANT_1]: 'Usage changed',
        [AlertCode.ALERT_WASH_TEMP_2]: 'Wash too cold',
        [AlertCode.ALERT_CHEM_TANK_LOW_3]: 'Chem running low',
        [AlertCode.ALERT_CHEM_SENS_FAIL_4]: 'Chem sensor failed',
        [AlertCode.ALERT_TEMP_SENS_FAIL_5]: 'Temp sensor failed',
        [AlertCode.ALERT_CHEM_SENS_RESTORED_6]: 'Chemical Sensor Restored',
        [AlertCode.ALERT_TEMP_SENS_RESTORED_7]: 'Temp Sensor Restored',
        [AlertCode.ALERT_POWER_UP_8]: 'Startup',
        [AlertCode.ALERT_WARM_MILK_9]: 'Warm milk',
        [AlertCode.ALERT_SD_CARD_STATUS_10]: 'SD card problem',
        [AlertCode.ALERT_WARM_REMINDER_11]: 'MILK STILL HOT',
        [AlertCode.ALERT_EVAL_PERIOD_FINISHED_101]: 'Device evaluation period finished',
        [AlertCode.ALERT_MILK_TANK_FULL_REMINDER_102]: 'Milk tank is about to be full',
        [AlertCode.ALERT_MILK_TANK_FULL_103]: 'Milk tank is full'
    };

    public static getTitleByCode(code: AlertCode | string): string {
        return this.map[code] ?? code;
    }

    public static getIconByCategory(category: string): string {
        switch(category)
        {
            case "ChemicalLow": return "water_drop";
            case "ChemicalUsage": return "trending_down";
            case "MilkTemperature": return "thermostat";
            case "ColdWash": return "severe_cold";
            case "Equipment": return "construction";
            default: return "notifications";
        }
    }
   
}

export { AlertCode, AlertService };
