import React from 'react';
import ChemicalSensorTile from './ChemicalSensorTile';
import TemperatureSensorTile from './TemperatureSensorTile';
import './SensorsList.scss';
import { IChemicalTankEquipmentInfo, IEquipmentInfo, IMilkTankEquipmentInfo, IPipelineEquipmentInfo } from '../../../interfaces/dto/IEquipmentInfo';
import { EquipmentType } from '../../../enums/EquipmentType';
import MilkTankEquipmentTile from './MilkTankEquipmentTile';
import AddEquipmentTile from './AddEquipmentTile';

interface ISensorsListProps {
    items: IEquipmentInfo[];
    active?: IEquipmentInfo;
    onItemClick: (item: IEquipmentInfo) => void;
    onItemEdit: (item: IEquipmentInfo) => void;
    onAddEquipmentClick: () => void;
}

const SensorsList: React.FC<ISensorsListProps> = ({ items, active, onItemClick, onItemEdit, onAddEquipmentClick }) => {
    return (
        <div className="mm-sensors-list">
            {items &&
                items.map(item => {
                    if (item.type === EquipmentType.CHEMICAL_TANK) {
                        const equipment = item as IChemicalTankEquipmentInfo;                        
                        if(equipment.chemicalTankSettings)
                            return (
                                <ChemicalSensorTile
                                    key={equipment.id}
                                    isActive={equipment.id === active?.id}
                                    tankType={equipment.chemicalTankSettings.tankType}
                                    capacity={equipment.chemicalTankSettings.capacity}
                                    diameter={equipment.chemicalTankSettings.diameter ?? undefined}
                                    chemicalName={equipment.chemicalTankSettings.chemicalName!}
                                    remaining={equipment.lastState?.remaining}
                                    timeToEmpty={equipment.lastState?.timeToEmpty}
                                    hasAlerts={equipment.lastState?.hasAlerts ?? false}
                                    onClick={() => onItemClick(item)}
                                    onEdit={() => onItemEdit(item)}
                                />
                        );
                    } else if (item.type === EquipmentType.PIPELINE) {
                        const equipment = item as IPipelineEquipmentInfo;
                        if(equipment.pipelineSettings)
                            return (
                                <TemperatureSensorTile
                                    key={equipment.id}
                                    isActive={equipment.id === active?.id}
                                    equipmentName={equipment.name}
                                    currentTemperature={equipment.lastState?.temperature}
                                    hasAlerts={equipment.lastState?.hasAlerts ?? false}
                                    onClick={() => onItemClick(item)}
                                    onEdit={() => onItemEdit(item)}
                                />
                        );
                    } else if (item.type === EquipmentType.MILK_TANK) {
                        const equipment = item as IMilkTankEquipmentInfo;
                        if(equipment.milkTankSettings)
                            return (
                                <MilkTankEquipmentTile
                                    key={equipment.id}
                                    equipmentName={equipment.name}
                                    isActive={equipment.id === active?.id}
                                    tankType={equipment.milkTankSettings.tankType}
                                    capacity={equipment.milkTankSettings.capacity}
                                    tankHeight={equipment.milkTankSettings.tankHeight}
                                    level={equipment.lastState?.level ?? 0}
                                    currentTemperature={equipment.lastState?.temperature}
                                    isFull={equipment.lastState?.isFull ?? false}
                                    timeToFull={equipment.lastState?.timeToFull}
                                    hasAlerts={equipment.lastState?.hasAlerts ?? false}
                                    onClick={() => onItemClick(item)}
                                    onEdit={() => onItemEdit(item)}
                                />
                        );
                    } else {
                        return null;
                    }
                })}  
            <AddEquipmentTile
                onClick={() => onAddEquipmentClick()}/> 
        </div>
    );
};

export default SensorsList;
