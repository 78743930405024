import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { IFarm } from '../interfaces/dto/IFarm';
import { IFarmCreate } from '../interfaces/dto/IFarmCreate';
import { IFarmEdit } from '../interfaces/dto/IFarmEdit';
import { IEquipmentInfo } from '../interfaces/dto/IEquipmentInfo';
import { IDeviceDetails } from '../interfaces/dto/IDeviceDetails';

export const useFarmsApi = () => {
    const axios = useAxiosPrivate();

    const getFarm = useCallback(
        async (farmId: number): Promise<AxiosResponse<IFarm, any>> => {
            return axios.get<IFarm>(`/1/farms/${farmId}`);
        },
        [axios]
    );

    const editFarm = useCallback(
        async (farmId: number, farm: IFarmEdit): Promise<AxiosResponse<IFarm, any>> => {
            return axios.put<IFarm>(`/1/farms/${farmId}`, farm);
        },
        [axios]
    );

    const addFarm = useCallback(
        async (farm: IFarmCreate): Promise<AxiosResponse<IFarm, any>> => {
            return axios.post('/1/farms', farm);
        },
        [axios]
    );

    const getFarmDevices = useCallback(
        async (farmId: number): Promise<AxiosResponse<IDeviceDetails[], any>> => {
            return axios.get<IDeviceDetails[]>(`/2/farms/${farmId}/devices`);
        },
        [axios]
    );

    const getFarmEquipment = useCallback(
        async (farmId: number, includeState: boolean): Promise<AxiosResponse<IEquipmentInfo[], any>> => {
            return axios.get<IEquipmentInfo[]>(`/2/farms/${farmId}/equipment?includeState=${includeState}`);
        },
        [axios]
    );

    return {
        getFarm,
        editFarm,
        addFarm,
        getFarmEquipment,
        getFarmDevices,
    };
};
