import React, { useEffect, useState } from 'react';
import { AlertService } from '../../../components/ActiveAlertCard/AlertService';
import { useEquipmentsApi } from '../../../hooks/useEquipmentsApi';
import { IAlert } from '../../../interfaces/dto/IAlert';
import './AlertMessage.scss';

interface IAlertMessageProps {
    equipmentId: number;
}

const AlertMessage: React.FC<IAlertMessageProps> = ({ equipmentId }) => {
    const { getEquipmentAlerts } = useEquipmentsApi();
    const [alerts, setAlerts] = useState<IAlert[]>();

    useEffect(() => {
        const fetchData = async () => {
            const { data: alerts } = await getEquipmentAlerts(equipmentId);
            setAlerts(alerts);
        };

        fetchData();
    }, [equipmentId, getEquipmentAlerts]);

    if (alerts) {
        return (
            <>
                {
                    alerts
                        .sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp))
                        .map(alert => {
                            return (
                                <div className="mm-alert-message">
                                    <span className="mm-alert-message__title">
                                        {AlertService.getTitleByCode(alert.code)}:
                                    </span>
                                    <span className="mm-alert-message__msg">{alert.message}</span>
                                </div>
                            );
                        }
                    )
                }
            </>
        );
    } else {
        return null;
    }
};

export default AlertMessage;
