import { DRUM_TANKS, TOTE_TANKS } from '../common/constants';
import { find } from 'lodash';
import { PERCENT } from '../common/enums';
import { IChemicalTankSettings } from '../interfaces/dto/IChemicalTankSettings';

/**
 * Calculates remaining volume in percent.
 *
 * @param {number} capacity Tank capacity volume.
 * @param {number} remaining Remaining volume.
 * @return {*}  {number} Remaining volume in percent.
 */
export const calcRemainingPercent = (capacity: number, remaining: number): number =>
    (remaining * PERCENT.Full) / capacity;

/**
 * Gets or calculates actual capacity for the tank by type.
 *
 * @param {IChemicalTankSettings} tankSettings Tank settings.
 * @return {*}  {number} Actual capacity of a tank.
 */
export const getOrCalcActualCapacity = (
    tankSettings: IChemicalTankSettings
): number => {
    switch (tankSettings.tankType) {
        case 'Drum':
            return getDrumActualCapacity(tankSettings.capacity);
        case 'Tote':
            return getToteActualCapacity(tankSettings.capacity);
        default:
        case 'Silo':
            return calcSiloActualCapacity(tankSettings.capacity, tankSettings.diameter!);
    }
};

/**
 * Calculates a silo tank actual capacity.
 *
 * @param {number} capacity Formal tank capacity.
 * @param {number} diameter Tank diameter.
 * @return {*}  {number} Actual capacity of the silo tank.
 */
export const calcSiloActualCapacity = (capacity: number, diameter: number): number => {
    const volume = 8 * Math.PI * Math.pow(diameter / 2, 2);
    const value = Math.ceil(volume / 231) + capacity;
    return value;
};

/**
 * Gets the drum tank actual capacity.
 *
 * @param {number} capacity Formal tank capacity.
 * @return {*}  {number} Actual capacity of the drum tank.
 */
const getDrumActualCapacity = (capacity: number): number =>
    find(DRUM_TANKS, { capacity })?.actualCapacity ?? capacity;

/**
 * Gets the tote tank actual capacity.
 *
 * @param {number} capacity Formal tank capacity.
 * @return {*}  {number} Actual capacity of the tote tank.
 */
const getToteActualCapacity = (capacity: number): number =>
    find(TOTE_TANKS, { capacity })?.actualCapacity ?? capacity;
