import React from 'react';
import './AddressCard.scss';

interface IAddressCardProps {
    name: string;
    address1: string;
    address2?: string;
    state: string;
    city: string;
    zip: string;
}

export const AddressCard: React.FC<IAddressCardProps> = ({
    name,
    address1,
    address2,
    city,
    state,
    zip,
}) => {
    return (
        <div className="mm-address-card">
            <div className="mm-address-card__row">
                <span className="mm-address-card__text">{name}</span>
            </div>
            <div className="mm-address-card__row">
                <span className="mm-address-card__text">{address1}</span>
            </div>
            {address2 && (
                <div className="mm-address-card__row">
                    <span className="mm-address-card__text">{address2}</span>
                </div>
            )}
            <div className="mm-address-card__row">
                <span className="mm-address-card__text">{state}</span>
                <span className="mm-address-card__text">{zip}</span>
                <span className="mm-address-card__text">{city}</span>
            </div>
        </div>
    );
};
