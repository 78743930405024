import React from 'react';
import { Objects } from '../../utils/objects';
import './Input.scss';

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    type?: string;
    className?: string;
    ref?: React.ForwardedRef<HTMLInputElement>;
}

export const Input: React.FC<IInputProps> = React.forwardRef(
    ({ type = 'text', className, ...rest }, ref: React.ForwardedRef<HTMLInputElement>) => {
        let classes = 'mm-input';
        if (Objects.isSet(className)) {
            classes = `${classes} ${className}`;
        }

        return <input className={classes} type={type} {...rest} ref={ref} />;
    }
);
