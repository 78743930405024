import React from 'react';
import { IAlert } from '../../interfaces/dto/IAlert';
import './AlertsList.scss';
import { AlertService } from '../../components/ActiveAlertCard/AlertService';

interface IAlertsListProps {
    items: IAlert[];
    onAlertClick: (alert: IAlert) => void;
}

const AlertsList: React.FC<IAlertsListProps> = ({ items, onAlertClick }) => {
    return (
        <div className="mm-alerts-list">
            {items &&
                items.map((alert, index) => (
                    <div className="mm-alerts-list-item" onClick={()=>{onAlertClick(alert)}}>
                        <div className="mm-alerts-list-item__row">
                            <span className="mm-alerts-list-item__subtitle">
                                {alert.farmName ?? alert.iccid ?? 'Application'}
                            </span>
                            <span className="mm-alerts-list-item__timestamp">{new Date(alert.originalTimestamp).toLocaleString()}</span>
                        </div>
                        <div className="mm-alerts-list-item__content">
                            <div className="material-icons-outlined mm-alerts-list-item__icon">{AlertService.getIconByCategory(alert.category)}</div>
                            <div>
                                <div className="mm-alerts-list-item__title">
                                    {AlertService.getTitleByCode(alert.code)}
                                </div>
                                <div className="mm-alerts-list-item__text">
                                    {alert.message}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default AlertsList;
