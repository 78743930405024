import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import classNames from 'classnames';
import { Numbers } from '../../../utils/numbers';
import { LogType } from '../../../hooks/equipments';
import { useEquipmentsApi } from '../../../hooks/useEquipmentsApi';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import LevelRemainingGraph from './LevelRemainingGraph';
import UsePerWashGraph from './UsePerWashGraph';
import './ChemicalGraphs.scss';
import { getOrCalcActualCapacity } from '../../../utils';
import { useForceRefreshEffect } from '../../../hooks/useForceRefresh.hook';
import { IChemicalTankEquipmentInfo } from '../../../interfaces/dto/IEquipmentInfo';
import { IChemicalTankSettings } from '../../../interfaces/dto/IChemicalTankSettings';

dayjs.extend(dayjsUtcPlugin);

const getNameByCode = (logType: LogType): string => {
    if (logType === LogType.RemainingChemical) {
        return 'Level Remaining';
    } else if (logType === LogType.WashReport) {
        return 'Usage';
    } else {
        return '';
    }
};

interface IChemicalGraphsProps {
    equipment: IChemicalTankEquipmentInfo;
}

const ChemicalGraphs: React.FC<IChemicalGraphsProps> = ({ equipment }) => {
    const {
        remaining, timeToEmpty,
    } = equipment.lastState ?? { remaining: 0, timeToEmpty: 0 };

    const { getEquipmentLog } = useEquipmentsApi();
    const { id: equipmentId } = equipment;
    const [logType, setLogType] = useState<LogType>(LogType.RemainingChemical);
    const [isLoading, setIsLoading] = useState(false);
    const [sensorLog, setSensorLog] = useState<ISensorLog[]>();
    useForceRefreshEffect(() => {
        fetchData();
    });

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data: log } = await getEquipmentLog(equipmentId, {
                startDateTime: dayjs().subtract(3, 'month').startOf('day').format(),
                pageSize: 50000,
                sortExpression: 'Timestamp',
                logType: logType,
            });
            setSensorLog(log);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [getEquipmentLog, equipmentId, logType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const actualCapacity = getOrCalcActualCapacity(equipment.chemicalTankSettings as IChemicalTankSettings);

    return (
        <div className="mm-chemical-graphs">
            <div className="mm-chemical-graphs__header">
                <div className="mm-chemical-graphs__text">
                    <div className="mm-chemical-graphs__title">{getNameByCode(logType)}</div>
                    <div className="mm-chemical-graphs__stats">
                        {Numbers.roundAndFormat(remaining, 1, 1)} Gals / {timeToEmpty ?? '?'} days
                    </div>
                </div>
                <div className="mm-chemical-graphs__buttons">
                    <div className="mm-button-group">
                        <span
                            className={classNames({
                                active: logType === LogType.RemainingChemical,
                            })}
                            onClick={() => setLogType(LogType.RemainingChemical)}
                        >
                            Level
                        </span>
                        <span
                            className={classNames({
                                active: logType === LogType.WashReport,
                            })}
                            onClick={() => setLogType(LogType.WashReport)}
                        >
                            Usage
                        </span>
                    </div>
                </div>
            </div>
            <div className="mm-chemical-graphs__content">
                {isLoading && <LoadingSpinnerFullScreen />}
                {!isLoading &&
                    sensorLog &&
                    sensorLog.length > 0 &&
                    logType === LogType.RemainingChemical && (
                        <LevelRemainingGraph log={sensorLog} capacity={actualCapacity} />
                    )}
                {!isLoading &&
                    sensorLog &&
                    sensorLog.length > 0 &&
                    logType === LogType.WashReport && (
                        <UsePerWashGraph
                            log={sensorLog}
                            targetUsePerWash={equipment.chemicalTankSettings?.targetWithdrawal}
                        />
                    )}
                {!isLoading && (!sensorLog || sensorLog.length === 0) && (
                    <div className="mm-chemical-graphs__no-data">No Data</div>
                )}
            </div>
        </div>
    );
};

export default ChemicalGraphs;
