import { EffectCallback, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../contexts/AppProvider';

const MIN_MS = 1;
const MIN_SUBSCRIPTIONS = 0;

type ForceRefreshTriggerPayload = {
    /**
     * The stateful value indicating that the system has at least one component which uses refresh state.
     *
     * @type {boolean}
     */
    hasSubscription: boolean;

    /**
     * Triggers the status of force refresh.
     *
     */
    triggerForceRefresh: () => void;
};

/**
 * Accepts a function that will be execute when the system changes the force refresh status.
 */
export const useForceRefreshEffect = (effect: EffectCallback): void => {
    const { isForceRefresh, setSubscriptionsCount } = useContext(AppContext);
    useEffect(() => {
        setSubscriptionsCount(count => ++count);

        return () => setSubscriptionsCount(count => --count);
    }, []);

    const isMounted = useRef(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        if (isForceRefresh) {
            effect();
        }
    }, [isForceRefresh]);
};

export const useForceRefreshTrigger = (): ForceRefreshTriggerPayload => {
    const { subscriptionsCount, setIsForceRefresh } = useContext(AppContext);

    return {
        hasSubscription: subscriptionsCount > MIN_SUBSCRIPTIONS,
        triggerForceRefresh: () => {
            setIsForceRefresh(true);
            const id = setTimeout(() => {
                setIsForceRefresh(false);
                clearTimeout(id);
            }, MIN_MS);
        },
    };
};
