import React from 'react';
import { Objects } from '../../utils/objects';
import './Checkbox.scss';

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    children?: React.ReactNode;
}

export const Checkbox: React.FC<ICheckboxProps> = ({ className, children, ...rest }) => {
    let classes = 'mm-checkbox';
    if (Objects.isSet(className)) {
        classes = `${classes} ${className}`;
    }

    return (
        <label className={classes}>
            <input type="checkbox" {...rest} />
            {children}
        </label>
    );
};
