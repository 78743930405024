import React, { useEffect, useRef, useState } from 'react';
import { useHomeFilter } from '../../../hooks/contexts/useHomeFilter';
import { IGetFarmsQueryParams, useGetFarms } from '../../../hooks/useGetFarms';
import { IFarm } from '../../../interfaces/dto/IFarm';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import { DairiesView } from '../Dairies';
import { DairiesList } from './DairiesList';
import { DairiesListFilter } from './DairiesListFilter';
import Paginate from '../../../components/Paginate/Paginate';
import './DairiesListView.scss';

export interface IDairy {
    id: number;
    name: string;
    address: string;
    contact: string;
    hasAlert: boolean;
}

interface IDairiesListViewProps {
    onViewChange: (view: DairiesView) => void;
}

export const DairiesListView: React.FC<IDairiesListViewProps> = ({ onViewChange }) => {
    const { isInitialized, dairyTypes, alertTypes, chemicals } = useHomeFilter();
    const pageIndex = useRef(1);
    const pageCount = useRef(1);
    const [search, setSearch] = useState('');
    const { isLoading, getFarms } = useGetFarms();
    const [dairies, setDairies] = useState<IDairy[]>([]);

    const loadFarms = async (): Promise<any> => {
        const params: IGetFarmsQueryParams = {
            pageIndex: pageIndex.current,
            pageSize: 50,
            sortExpression: '-AlertsCount, Name',
            farmTypes: dairyTypes.filter(d => d.checked).map(d => d.key),
            alertTypes: alertTypes.filter(a => a.checked).map(a => a.key),
            chemicalIds: chemicals.filter(c => c.checked).map(c => c.key),
        };

        if (search) {
            params.search = search;
        }

        const { data: farms, headers } = await getFarms(params);

        const dairies = farms.map(farm => {
            return {
                id: farm.farmId,
                name: farm.name,
                address: getAddress(farm),
                contact: getContact(farm),
                hasAlert: farm.alertsCount > 0,
            } as IDairy;
        });

        pageIndex.current = Number(headers.pageindex);
        pageCount.current = Number(headers.pagecount);

        setDairies(dairies);
    };

    useEffect(() => {
        if (isInitialized) {
            loadFarms();
        }
    }, [isInitialized, dairyTypes, alertTypes, chemicals, search]);

    const getAddress = (farm: IFarm): string => {
        if (!farm || !farm.address) {
            return '';
        }

        return `${farm.address.city}, ${farm.address.state}`;
    };

    const getContact = (farm: IFarm): string => {
        if (!farm || !farm.contacts || farm.contacts.length === 0) {
            return '';
        }

        return `${farm.contacts[0].name} ${farm.contacts[0].phone}`;
    };

    const handleFilterChange = (search: string) => {
        setSearch(search);
    };

    const handleViewChange = () => {
        onViewChange(DairiesView.MAP);
    };

    const handlePageChange = ({ selected }: { selected: number }) => {
        pageIndex.current = selected + 1;
        loadFarms();
    };

    return (
        <div className="mm-dairies-list-view">
            <DairiesListFilter
                isLoading={isLoading}
                onFilterChange={handleFilterChange}
                onViewChange={handleViewChange}
            />
            {!isLoading && <DairiesList dairies={dairies} />}
            {isLoading && <LoadingSpinnerFullScreen />}
            <Paginate pageCount={pageCount.current} onPageChange={handlePageChange}></Paginate>
        </div>
    );
};
