import React, { CSSProperties } from 'react';
import { PERCENT } from '../../common/enums';
import { calcRemainingPercent } from '../../utils';

interface IToteIconProps {
    tank: Tank | undefined;
    remaining: number;
    hasAlerts: boolean;
}

const ALERT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '40px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const TEXT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const ToteIcon: React.FC<IToteIconProps> = ({ tank, remaining, hasAlerts }) => {
    if (!tank) {
        return null;
    }

    const remainingPercent =
        remaining > PERCENT.Empty
            ? calcRemainingPercent(tank.actualCapacity, remaining)
            : PERCENT.Empty;

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 64.44 59.63"
            enableBackground="new 0 0 64.44 59.63;"
            xmlSpace="preserve"
            height="110px"
        >
            <style type="text/css">
                {`.tote-st0{opacity:0.52;fill:#2693CA;}
                .tote-st1{opacity:0.52;fill:url(#TOTE_GRAD_1);stroke:#000000;stroke-miterlimit:10;}
                .tote-st2{fill:#2B2B2B;}
                .tote-st3{fill:#070808;stroke:#000000;stroke-miterlimit:10;}
                .tote-st4{fill:none;stroke:#3D3D3D;stroke-miterlimit:10;}`}
            </style>
            <defs>
                <clipPath id="toteShape">
                    <path
                        d="M59.46,53.16H4.98c-2.11,0-3.82-1.71-3.82-3.82V6.26c0-2.11,1.71-3.82,3.82-3.82h54.49
	c2.11,0,3.82,1.71,3.82,3.82v43.07C63.29,51.45,61.58,53.16,59.46,53.16z"
                    />
                </clipPath>
                <linearGradient
                    id="TOTE_GRAD_1"
                    gradientUnits="userSpaceOnUse"
                    x1="1.1522"
                    y1="27.7989"
                    x2="63.2878"
                    y2="27.7989"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="0.1235" stopColor="#F5F6F6" />
                    <stop offset="1" stopColor="#000000" />
                </linearGradient>
            </defs>
            <rect
                className="tote-st0"
                x="0"
                y={`${PERCENT.Full - remainingPercent}%`}
                width="100%"
                height="100%"
                clipPath="url(#toteShape)"
            ></rect>
            <path
                className="tote-st1"
                d="M59.46,53.16H4.98c-2.11,0-3.82-1.71-3.82-3.82V6.26c0-2.11,1.71-3.82,3.82-3.82h54.49
	c2.11,0,3.82,1.71,3.82,3.82v43.07C63.29,51.45,61.58,53.16,59.46,53.16z"
            />
            <polygon
                className="tote-st2"
                points="0,53.16 0,59.63 10.1,59.63 10.1,57.71 19.96,57.71 19.96,59.63 27.29,59.63 27.29,57.71 37.15,57.71 
	37.15,59.63 44.48,59.63 44.48,57.71 54.34,57.71 54.34,59.63 64.44,59.63 64.44,53.16 "
            />
            <rect x="24.51" y="0.5" className="tote-st3" width="15.42" height="1.11" />
            <g>
                <rect x="0.53" y="5.37" className="tote-st4" width="62.96" height="47.79" />
                <line className="tote-st4" x1="13.81" y1="53.16" x2="13.81" y2="5.83" />
                <line className="tote-st4" x1="25.57" y1="53.16" x2="25.57" y2="5.83" />
                <line className="tote-st4" x1="37.33" y1="53.16" x2="37.33" y2="5.83" />
                <line className="tote-st4" x1="49.08" y1="52.93" x2="49.08" y2="5.6" />
                <line className="tote-st4" x1="0.53" y1="14.7" x2="63.49" y2="14.7" />
                <line className="tote-st4" x1="0.95" y1="25.79" x2="63.91" y2="25.79" />
                <line className="tote-st4" x1="0.95" y1="37.44" x2="63.91" y2="37.44" />
                <line className="tote-st4" x1="0.53" y1="48.31" x2="63.49" y2="48.31" />
            </g>            
            {hasAlerts && (
                <text style={ALERT_STYLE} x="50%" y="60%">
                    !
                </text>
            )}
            {!hasAlerts && remainingPercent >= 100 && (
                <>
                    <text style={TEXT_STYLE} x="50%" y="40%">
                        Max
                    </text>
                    <text style={TEXT_STYLE} x="50%" y="60%">
                        capacity
                    </text>
                </>
            )}
        </svg>
    );
};

export default ToteIcon;
