import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { EquipmentType } from '../../enums/EquipmentType';

interface ISelectEquipmentTypeDialogProps {
    onClose: (newValue?: { equipmentType: string }) => void;
}

const SelectEquipmentTypeDialog: React.FC<ISelectEquipmentTypeDialogProps> = ({
    onClose,
}) => {
    const [selectedType, setSelectedType] = useState(EquipmentType.CHEMICAL_TANK.toString());

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose({ equipmentType: selectedType });
    };


    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open
            >
                <DialogTitle>Select equipment type</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label="type"
                                name="type"
                                style={{ gap: 14 }}
                                value={selectedType}
                                onChange={event => setSelectedType(event.target.value)}
                            >
                                <FormControlLabel
                                    key={EquipmentType.CHEMICAL_TANK}
                                    value={EquipmentType.CHEMICAL_TANK}
                                    control={<Radio />}
                                    label="Chemical tank"
                                />
                                <FormControlLabel
                                    key={EquipmentType.MILK_TANK}
                                    value={EquipmentType.MILK_TANK}
                                    control={<Radio />}
                                    label="Milk tank"
                                />
                                <FormControlLabel
                                    key={EquipmentType.PIPELINE}
                                    value={EquipmentType.PIPELINE}
                                    control={<Radio />}
                                    label="Pipeline"
                                />
                            </RadioGroup>
                        </Grid>                        
                    </Grid>
            </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>           
        </>
    );
};

export default SelectEquipmentTypeDialog;
