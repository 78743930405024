import styled from 'styled-components';

export const MapMarker = styled.div`
    background-color: #000000;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    border: 4px solid #ffffff;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`;
