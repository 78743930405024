import React from 'react';
import { PERCENT } from '../../common/enums';
import { calcRemainingPercent } from '../../utils';
import { Indicator } from './Indicator/Indicator';

interface IDrumIconProps {
    tank: Tank | undefined;
    remaining: number;
    hasAlerts: boolean;
}

const DrumIcon: React.FC<IDrumIconProps> = ({ tank, remaining, hasAlerts }) => {
    if (!tank) {
        return null;
    }

    const remainingPercent =
        remaining > PERCENT.Empty
            ? calcRemainingPercent(tank.actualCapacity, remaining)
            : PERCENT.Empty;

    return (
        <>
            {tank.type === 'small' && (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 26.51 50.62"
                    enableBackground="new 0 0 26.51 50.62;"
                    xmlSpace="preserve"
                    height="110px"
                >
                    <style type="text/css">
                        {`.st0{fill:#2693CA;}
.st1{opacity:0.52;}
.st2{fill:url(#DRUM_1_GRAD_1);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}

.st3{fill:url(#SVGID_00000163775274937298585990000010458805922577093016_);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}

.st4{fill:url(#SVGID_00000140721651631915913320000017895035787353445812_);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}

.st5{fill:url(#SVGID_00000054963771496018020800000000000912114398877622_);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}

.st6{fill:url(#SVGID_00000039095167838622110520000006988130274900458940_);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}

.st7{fill:url(#SVGID_00000021814642229923842180000008164247055075333818_);stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
.st8{fill:none;stroke:#232121;stroke-width:0.5;stroke-miterlimit:10;}
.st9{fill:#C83F4D;}`}
                    </style>
                    <defs>
                        <linearGradient
                            id="DRUM_1_GRAD_1"
                            gradientUnits="userSpaceOnUse"
                            x1="0.25"
                            y1="26.5115"
                            x2="26.2597"
                            y2="26.5115"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="DRUN_1_GRAD_2"
                            gradientUnits="userSpaceOnUse"
                            x1="0.25"
                            y1="4.3627"
                            x2="8.4999"
                            y2="4.3627"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="DRUN_1_GRAD_3"
                            gradientUnits="userSpaceOnUse"
                            x1="18.0097"
                            y1="4.3627"
                            x2="26.2597"
                            y2="4.3627"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="DRUN_1_GRAD_4"
                            gradientUnits="userSpaceOnUse"
                            x1="0.25"
                            y1="7.4107"
                            x2="26.2597"
                            y2="7.4107"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="DRUN_1_GRAD_5"
                            gradientUnits="userSpaceOnUse"
                            x1="10.3287"
                            y1="5.846"
                            x2="15.937"
                            y2="5.846"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="DRUN_1_GRAD_6"
                            gradientUnits="userSpaceOnUse"
                            x1="0.25"
                            y1="47.5022"
                            x2="26.2597"
                            y2="47.5022"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <clipPath id="drum1Shape">
                            <rect x="0.25" y="8.39" className="st2" width="26.01" height="36.25" />
                            <path
                                d="
        M8.5,6.44H0.25V3.08c0-0.44,0.35-0.79,0.79-0.79h5.45L8.5,6.44z"
                            />
                            <path
                                d="
        M26.26,3.08v3.35h-8.25l2.01-4.15h5.45C25.91,2.29,26.26,2.64,26.26,3.08z"
                            />
                            <rect x="0.25" y="6.44" width="26.01" height="1.95" />
                            <rect x="10.33" y="5.26" width="5.61" height="1.18" />
                            <path
                                d="
        M26.26,44.64v3.63c0,1.16-0.94,2.1-2.1,2.1H2.55c-1.27,0-2.3-1.03-2.3-2.3v-3.43H26.26z"
                            />
                            <path
                                d="M18.47,2.3c-0.63,1.94-4.79,1.9-5.51,1.9c-1.01-0.01-4.41-0.04-4.97-1.71C7.78,1.87,7.96,1,8.5,0.57
        c0.94-0.75,2.11,0.52,4.56,0.56c2.69,0.04,4.08-1.45,5.02-0.63C18.56,0.92,18.66,1.73,18.47,2.3z"
                            />
                        </clipPath>
                    </defs>
                    <g>
                        <rect
                            className="st0"
                            x="0"
                            y={`${PERCENT.Full - remainingPercent}%`}
                            width="100%"
                            height="100%"
                            clipPath="url(#drum1Shape)"
                        ></rect>
                        <g>
                            <g className="st1">
                                <rect
                                    x="0.25"
                                    y="8.39"
                                    className="st2"
                                    width="26.01"
                                    height="36.25"
                                />
                                <path
                                    fill="url(#DRUN_1_GRAD_2)"
                                    stroke="#000000"
                                    strokeWidth="0.5"
                                    strokeMiterlimit="10"
                                    d="
        M8.5,6.44H0.25V3.08c0-0.44,0.35-0.79,0.79-0.79h5.45L8.5,6.44z"
                                />
                                <path
                                    fill="url(#DRUN_1_GRAD_3)"
                                    stroke="#000000"
                                    strokeWidth="0.5"
                                    strokeMiterlimit="10"
                                    d="
        M26.26,3.08v3.35h-8.25l2.01-4.15h5.45C25.91,2.29,26.26,2.64,26.26,3.08z"
                                />
                                <rect
                                    x="0.25"
                                    y="6.44"
                                    fill="url(#DRUN_1_GRAD_4)"
                                    stroke="#000000"
                                    strokeWidth="0.5"
                                    strokeMiterlimit="10"
                                    width="26.01"
                                    height="1.95"
                                />
                                <rect
                                    x="10.33"
                                    y="5.26"
                                    fill="url(#DRUN_1_GRAD_5)"
                                    stroke="#000000"
                                    strokeWidth="0.5"
                                    strokeMiterlimit="10"
                                    width="5.61"
                                    height="1.18"
                                />
                                <path
                                    fill="url(#DRUN_1_GRAD_6)"
                                    stroke="#000000"
                                    strokeMiterlimit="10"
                                    d="
        M26.26,44.64v3.63c0,1.16-0.94,2.1-2.1,2.1H2.55c-1.27,0-2.3-1.03-2.3-2.3v-3.43H26.26z"
                                />
                                <path
                                    className="st8"
                                    d="M18.47,2.3c-0.63,1.94-4.79,1.9-5.51,1.9c-1.01-0.01-4.41-0.04-4.97-1.71C7.78,1.87,7.96,1,8.5,0.57
        c0.94-0.75,2.11,0.52,4.56,0.56c2.69,0.04,4.08-1.45,5.02-0.63C18.56,0.92,18.66,1.73,18.47,2.3z"
                                />
                            </g>
                        </g>
                        <Indicator hasAlerts={hasAlerts} maxCapacity={remainingPercent >= 100} />
                    </g>
                </svg>
            )}

            {(tank.type === 'medium' || tank.type === 'large') && (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 27.26 47.18"
                    enableBackground="new 0 0 27.26 47.18;"
                    xmlSpace="preserve"
                    height="110px"
                >
                    <style type="text/css">
                        {`.st0{ fill:#2693CA;}
   .st1{opacity:0.52;}
   .st2{fill:url(#DRUM_2_GRAD_1);stroke:#000000;stroke-miterlimit:10;}
   .st3{fill:url(#SVGID_00000067915150729598866770000016327759527157669260_);stroke:#000000;stroke-miterlimit:10;}
   .st4{fill:url(#SVGID_00000075129578192450154610000000746153455536040378_);stroke:#000000;stroke-miterlimit:10;}
   .st5{fill:#C83F4D;}`}
                    </style>
                    <defs>
                        <clipPath id="drum2Shape">
                            <path
                                d="M26.76,3.09l0,13.63c-4.27,0.25-8.79,0.4-13.55,0.38c-4.45,0-8.69-0.15-12.72-0.38l0-13.63
           c0-0.84,0.61-1.56,1.44-1.69C5.12,0.91,8.77,0.56,12.79,0.5c4.73-0.05,8.94,0.34,12.54,0.89C26.16,1.52,26.76,2.25,26.76,3.09z"
                            />
                            <path
                                d="
           M26.76,30.69v13.5c0,0.85-0.62,1.58-1.46,1.7c-3.72,0.51-8,0.85-12.73,0.78c-3.89-0.04-7.46-0.35-10.64-0.79
           c-0.83-0.11-1.43-0.83-1.43-1.66l0-13.53c3.66,0.54,7.9,0.95,12.63,0.98C18.26,31.7,22.86,31.28,26.76,30.69z"
                            />
                            <path
                                d="
           M26.76,16.72v13.97c-3.91,0.59-8.5,1.01-13.64,0.98C8.4,31.64,4.16,31.23,0.5,30.69V16.72c4.02,0.23,8.27,0.38,12.72,0.38
           C17.97,17.11,22.49,16.97,26.76,16.72z"
                            />
                        </clipPath>
                        <linearGradient
                            id="DRUM_2_GRAD_1"
                            gradientUnits="userSpaceOnUse"
                            x1="0.5"
                            y1="8.7992"
                            x2="26.7649"
                            y2="8.7992"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="SVGID_00000172411343365235972220000003562329208381678732_"
                            gradientUnits="userSpaceOnUse"
                            x1="0.5"
                            y1="38.6839"
                            x2="26.7648"
                            y2="38.6839"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                        <linearGradient
                            id="SVGID_00000049920372858448052160000005414776616883506323_"
                            gradientUnits="userSpaceOnUse"
                            x1="0.5001"
                            y1="24.1947"
                            x2="26.7648"
                            y2="24.1947"
                        >
                            <stop offset="0" stopColor="#FFFFFF" />
                            <stop offset="0.1235" stopColor="#F5F6F6" />
                            <stop offset="1" stopColor="#000000" />
                        </linearGradient>
                    </defs>
                    <g>
                        <rect
                            className="st0"
                            x="0"
                            y={`${PERCENT.Full - remainingPercent}%`}
                            width="100%"
                            height="100%"
                            clipPath="url(#drum2Shape)"
                        ></rect>
                        <g className="st1">
                            <path
                                className="st2"
                                d="M26.76,3.09l0,13.63c-4.27,0.25-8.79,0.4-13.55,0.38c-4.45,0-8.69-0.15-12.72-0.38l0-13.63
           c0-0.84,0.61-1.56,1.44-1.69C5.12,0.91,8.77,0.56,12.79,0.5c4.73-0.05,8.94,0.34,12.54,0.89C26.16,1.52,26.76,2.25,26.76,3.09z"
                            />
                            <path
                                fill="url(#SVGID_00000172411343365235972220000003562329208381678732_)"
                                stroke="#000000"
                                strokeMiterlimit="10"
                                d="
           M26.76,30.69v13.5c0,0.85-0.62,1.58-1.46,1.7c-3.72,0.51-8,0.85-12.73,0.78c-3.89-0.04-7.46-0.35-10.64-0.79
           c-0.83-0.11-1.43-0.83-1.43-1.66l0-13.53c3.66,0.54,7.9,0.95,12.63,0.98C18.26,31.7,22.86,31.28,26.76,30.69z"
                            />
                            <path
                                fill="url(#SVGID_00000049920372858448052160000005414776616883506323_)"
                                stroke="#000000"
                                strokeMiterlimit="10"
                                d="
           M26.76,16.72v13.97c-3.91,0.59-8.5,1.01-13.64,0.98C8.4,31.64,4.16,31.23,0.5,30.69V16.72c4.02,0.23,8.27,0.38,12.72,0.38
           C17.97,17.11,22.49,16.97,26.76,16.72z"
                            />
                        </g>
                        <Indicator hasAlerts={hasAlerts} maxCapacity={remainingPercent >= 100} />
                    </g>
                </svg>
            )}
        </>
    );
};

export default DrumIcon;
