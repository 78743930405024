import './EditChemicalTankForm.scss';

import { useState } from 'react';
import * as Yup from 'yup';
import { Button, FormControlLabel, Grid, MenuItem, Radio, Stack, Typography } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import { INotificationContact } from '../../interfaces/dto/IEquipmentEdit';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, TextField, RadioGroup, Select } from 'formik-mui';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { IChemical } from '../../interfaces/dto/IChemical';
import { TankType } from '../../enums/TankType';
import { IEquipmentEdit } from '../../interfaces/dto/IEquipmentEdit';
import { useEquipmentsApi } from '../../hooks/useEquipmentsApi';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EquipmentAlertsSubform, { IEquipmentAlertsFormValues } from './EquipmentAlertsSubform';


// All messages for this component.
const MESSAGES = {
    evaluatedSettingsDialog: {
        content: 'The form fields will be recalculated based on device log for the last 3 days. Do you want to continue?',
        confirm: 'Yes',
        cancel: 'No',
    },
};

const tankTypes = [TankType.DRUM, TankType.TOTE, TankType.SILO];
const drumSizes = [15, 30, 55];
const toteSizes = [250, 275, 350];

export interface IFormValues extends IEquipmentAlertsFormValues{
    name: string;
    chemicalId: number;
    chemicalName: string | undefined;
    targetWithdrawal: number;
    tankType: string;

    capacity: number;
    drumCapacity: number;
    toteCapacity: number;
    siloCapacity: number;
    siloDiameter: number | null;

    capacityHeight: number;
    sensorHeight: number;

    chemNotificationLevel: number;
    chemUsageToleranceLevel: number;
    chemUsageToleranceDays: number;

    notificationContacts: INotificationContact[];
}

const getInitialValues = (equipment: IEquipmentInfo): IFormValues => {
    const { name, chemicalTankSettings, notificationContacts, alertCategories } = equipment;

    let initialValues: IFormValues = {
        name: name,
        chemicalId: 0,
        chemicalName: '',
        targetWithdrawal: 0,

        tankType: TankType.DRUM,

        capacity: 0,
        drumCapacity: drumSizes[0],
        toteCapacity: toteSizes[0],
        siloCapacity: 0,
        siloDiameter: 0,

        capacityHeight: 0,
        sensorHeight: 0,

        chemNotificationLevel: 0,
        chemUsageToleranceLevel: 0,
        chemUsageToleranceDays: 0,

        notificationContacts: notificationContacts,
        alertCategories: alertCategories
    };

    if (chemicalTankSettings) {
        initialValues = {
            name: name,
            chemicalId: chemicalTankSettings.chemicalId,
            chemicalName: chemicalTankSettings.chemicalName,
            targetWithdrawal: chemicalTankSettings.targetWithdrawal,

            tankType: chemicalTankSettings.tankType,

            capacity: chemicalTankSettings.capacity,
            drumCapacity: drumSizes[0],
            toteCapacity: toteSizes[0],
            siloCapacity: 0,
            siloDiameter: chemicalTankSettings.diameter,

            capacityHeight: chemicalTankSettings.capacityHeight,
            sensorHeight: chemicalTankSettings.sensorHeight,

            chemNotificationLevel: chemicalTankSettings.chemNotificationLevel,
            chemUsageToleranceLevel: chemicalTankSettings.chemUsageToleranceLevel,
            chemUsageToleranceDays: chemicalTankSettings.chemUsageToleranceDays,

            notificationContacts: notificationContacts,
            alertCategories: alertCategories
        };

        const tankCapacity = chemicalTankSettings.capacity;
        if (chemicalTankSettings.tankType === TankType.DRUM && drumSizes.includes(tankCapacity)) {
            initialValues.drumCapacity = tankCapacity;
        } else if (chemicalTankSettings.tankType === TankType.TOTE && toteSizes.includes(tankCapacity)) {
            initialValues.toteCapacity = tankCapacity;
        } else if (chemicalTankSettings.tankType === TankType.SILO) {
            initialValues.siloCapacity = tankCapacity;
        }
    }

    return initialValues;
}

const getValidationSchema = (): any => {
    let validationSchema: any = Yup.object().shape({
        name: Yup.string()
            .required(),
        targetWithdrawal: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Expected daily usage, OZ'),
        tankType: Yup.string()
            .required(),
        siloDiameter: Yup.number()
            .integer()
            .nullable()
            .when('tankType', {
                is: TankType.SILO,
                then: Yup.number().integer().positive().required()
            })
            .label('Silo Diameter')
            .typeError('Silo Diameter must be a number'),
        siloCapacity: Yup.number()
            .integer()
            .nullable()
            .when('tankType', {
                is: TankType.SILO,
                then: Yup.number().integer().positive().required()
            })
            .label('Silo Capacity')
            .typeError('Silo Capacity must be a number'),
        capacityHeight: Yup.number()
            .integer()
            .label('Height of Sensor')
            .typeError('Height of Sensor must be a number'),
        sensorHeight: Yup.number()
            .integer()
            .label('Depth at Capacity Mark')
            .typeError('Depth at Capacity Mark must be a number'),
        chemNotificationLevel: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Chemical notification level')
            .typeError('Chemical notification level must be a number'),
        chemUsageToleranceLevel: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Chemical usage alert tolerance')
            .typeError('Chemical usage alert tolerance must be a number'),
        chemUsageToleranceDays: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Chemical usage alert tolerance')
            .typeError('Chemical usage alert tolerance must be a number'),
    });

    return validationSchema;
}

interface IEditChemicalTankFormProps{
    equipment: IEquipmentInfo;
    chemicals: IChemical[];
    farmContacts: IDairyContact[];
    farmName: string;
    onCancel: () => void;
    onSave: () => void;
    onChangeEvaluatedState: () => void;
}

const EditChemicalTankForm: React.FC<IEditChemicalTankFormProps> = ({
    equipment,
    chemicals,
    farmContacts,
    farmName,
    onCancel,
    onSave,
    onChangeEvaluatedState,
}) => {
    const { farmId, equipmentId } = useParams();
    const { setEquipment, createEquipment } = useEquipmentsApi();
    const [notificationContacts, setNotificationContacts] = useState<INotificationContact[]>(equipment.notificationContacts);
    const [isOpen, setIsOpen] = useState(false);

    const initialValues = getInitialValues(equipment);
    const validationSchema = getValidationSchema();

    const chemicalNames = chemicals.map(c => c.name);

    const handleEdit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        const chemical = chemicals.find(item => item.name === values.chemicalName);

        let tankCapacity = 0;
        if (values.tankType === TankType.DRUM) {
            tankCapacity = values.drumCapacity;
        } else if (values.tankType === TankType.TOTE) {
            tankCapacity = values.toteCapacity;
        } else if (values.tankType === TankType.SILO) {
            tankCapacity = values.siloCapacity;
        }

        const equipmentEdit: IEquipmentEdit = {
            farmId: parseInt(farmId!),
            name: values.name,
            type: "ChemicalTank",
            notificationContacts: notificationContacts,
            alertCategories: values.alertCategories,
            chemicalTankSettings: {
                chemicalId: chemical?.id!,
                chemicalName: chemical?.name,
                tankType: values.tankType,
                capacity: tankCapacity,
                capacityHeight: +values.capacityHeight,
                sensorHeight: +values.sensorHeight,
                diameter: values.siloDiameter ? +values.siloDiameter : null,
                targetWithdrawal: values.targetWithdrawal,
                chemNotificationLevel: +values.chemNotificationLevel,
                chemUsageToleranceLevel: +values.chemUsageToleranceLevel,
                chemUsageToleranceDays: +values.chemUsageToleranceDays
            },
        };

        if (parseInt(equipmentId!) === 0) {
            await createEquipment(equipmentEdit);
        }
        else {
            await setEquipment(parseInt(equipmentId!), equipmentEdit);
        }

        helpers.setSubmitting(false);
        onSave();
    }

    const handleSubmit = (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            helpers.setSubmitting(true);
            handleEdit(values, helpers);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Dialog open={isOpen}>
                <DialogContent>{MESSAGES.evaluatedSettingsDialog.content}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onChangeEvaluatedState();
                            setIsOpen(false);
                        }}
                    >
                        {MESSAGES.evaluatedSettingsDialog.confirm}
                    </Button>
                    <Button onClick={() => setIsOpen(false)}>
                        {MESSAGES.evaluatedSettingsDialog.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, submitForm, isSubmitting, isValid, setFieldValue }) => {
                    return (
                        <Form
                            className="mm-edit-chemical-tank-form"
                            noValidate
                            autoComplete="off"
                        >
                            <div className="mm-edit-chemical-tank-form__header">
                                <div className="mm-edit-chemical-tank-form__header__subtitle">{farmName}</div>
                                <div className="mm-edit-chemical-tank-form__header__title">{equipment.id === 0 ? 'New chemical tank': equipment.name}</div>
                            </div>
                            <div className="mm-edit-chemical-tank-form__content">
                                <div className="mm-edit-chemical-tank-form__first-block">
                                    <Stack gap={2}>
                                        <Field
                                            name="name"
                                            label="Name"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                        <Field
                                            name="chemicalName"
                                            component={Autocomplete}
                                            options={chemicalNames}
                                            disableClearable
                                            renderInput={(
                                                params: AutocompleteRenderInputParams
                                            ) => (
                                                <MuiTextField
                                                    {...params}
                                                    placeholder="Chemical Name"
                                                    name="chemicalId"
                                                    variant="standard"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        <Field
                                            name="targetWithdrawal"
                                            label="Expected daily usage, OZ"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                        <Typography
                                            sx={{ textAlign: 'center', fontWeight: 'bold', pt: 1 }}
                                        >
                                            Tank Settings
                                        </Typography>
                                        <Grid item container spacing={2}>
                                            <Grid item xs={4}>
                                                <Field
                                                    name="tankType"
                                                    component={RadioGroup}
                                                >
                                                    {tankTypes.map(type => (
                                                        <FormControlLabel
                                                            key={type}
                                                            value={type}
                                                            control={<Radio />}
                                                            label={type}
                                                            sx={{ pt: 1 }}
                                                        />
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Stack direction="column" spacing={1}>
                                                    <Field
                                                        component={Select}
                                                        name="drumCapacity"
                                                        size="small"
                                                        disabled={values.tankType !== TankType.DRUM}
                                                        variant="standard"
                                                    >
                                                        {drumSizes.map(size => (
                                                            <MenuItem key={size} value={size}>
                                                                {size}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                    <Field
                                                        component={Select}
                                                        name="toteCapacity"
                                                        size="small"
                                                        disabled={values.tankType !== TankType.TOTE}
                                                        variant="standard"
                                                    >
                                                        {toteSizes.map(size => (
                                                            <MenuItem key={size} value={size}>
                                                                {size}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                    <Stack direction="row" spacing={1}>
                                                        <Field
                                                            name="siloDiameter"
                                                            label="Diameter"
                                                            component={TextField}
                                                            variant="standard"
                                                            size="small"
                                                            disabled={values.tankType !== TankType.SILO}
                                                        />
                                                        <Field
                                                            name="siloCapacity"
                                                            label="Capacity"
                                                            component={TextField}
                                                            variant="standard"
                                                            size="small"
                                                            disabled={values.tankType !== TankType.SILO}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                                Distance sensor
                                            </Grid>
                                            <Grid item xs={8} >
                                                <Stack direction={'row'} spacing={1}>
                                                    <Field
                                                        name="capacityHeight"
                                                        label="Depth at Capacity Mark"
                                                        component={TextField}
                                                        variant="standard"
                                                        size="small"
                                                    />
                                                    <Field
                                                        name="sensorHeight"
                                                        label="Height of Sensor"
                                                        component={TextField}
                                                        variant="standard"
                                                        size="small"
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </div>
                                <div className="mm-edit-chemical-tank-form__second-block">
                                    <EquipmentAlertsSubform
                                        farmContacts={farmContacts}
                                        notificationContacts={notificationContacts}
                                        setNotificationContacts={setNotificationContacts}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                    <Stack gap={2} sx={{ pt: 5 }}>
                                        <Field
                                            name="chemNotificationLevel"
                                            label="Chemical notification level, days"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                        <Field
                                            name="chemUsageToleranceLevel"
                                            label="Chemical usage alert tolerance, %"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                        <Field
                                            name="chemUsageToleranceDays"
                                            label="Chemical usage alert tolerance, days"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                    </Stack>
                                </div>
                            </div>
                            <div className="mm-edit-chemical-tank-form__footer">
                                <div className="mm-edit-chemical-tank-form__footer__actions">
                                    <Button
                                        variant="contained"
                                        // disabled={!isValid || isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Save & Exit
                                    </Button>
                                    <Button variant="contained" onClick={() => setIsOpen(true)}>
                                        Evaluate
                                    </Button>
                                </div>
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik >
        </>
    )
}

export default EditChemicalTankForm;

