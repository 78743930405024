import React from 'react';
import './ContactCard.scss';

interface IContactCardProps {
    number: number;
    name: string;
    title: string;
    phone: string;
    enableNotifications: boolean;
}

export const ContactCard: React.FC<IContactCardProps> = ({ number, name, title, phone, enableNotifications }) => {
    return (
        <div className="mm-contact-card">
            <div className="mm-contact-card__header">
                <h2 className="mm-contact-card__header-text">{title}</h2>
                <span className="material-icons">{ enableNotifications ? 'notifications' : 'notifications_off' }</span>
            </div>            
            <div className="mm-contact-card__content">
                <p className="mm-contact-card__name">{name}</p>
                <p className="mm-contact-card__phone">{phone}</p>
            </div>
        </div>
    );
};
