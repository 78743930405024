import './SidePanel.scss';
import React from 'react';
import { Divider } from "@mui/material";

interface ISidePanelProps {
  title: string;
  children?: React.ReactNode;
}

export const SidePanel: React.FC<ISidePanelProps> = ({ title, children }) => (
  <div className="mm-side-panel">
    <div className="mm-side-panel__header">
      {title}
    </div>
    <Divider className="mm-side-panel__divider" />
    <div className="mm-side-panel__content">
      {children}
    </div>
  </div>
);
