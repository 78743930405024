import React, { useState } from 'react';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import './DairiesListFilter.scss';

interface IDairiesListFilterProps {
    isLoading: boolean;
    onFilterChange: (search: string) => void;
    onViewChange: () => void;
}

export const DairiesListFilter: React.FC<IDairiesListFilterProps> = ({
    isLoading,
    onFilterChange,
    onViewChange,
}) => {
    const [search, setSearch] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            handleButtonClick();
        }
    };

    const handleButtonClick = (): void => {
        onFilterChange(search);
    };

    return (
        <div className="mm-dairies-list-filter">
            <div className="mm-dairies-list-filter__search">
                <Input
                    className="mm-dairies-list-filter__search--input"
                    value={search}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    disabled={isLoading}
                    placeholder="Search dairies"
                />
                <Button
                    className="mm-dairies-list-filter__search--icon-button"
                    type="button"
                    onClick={handleButtonClick}
                >
                    Search
                </Button>
            </div>
            <div className="mm-dairies-list-filter__link" onClick={onViewChange}>
                <span className="mm-dairies-list-filter__link--text">
                    Map view
                </span>
                <span className="mm-dairies-list-filter__link--icon material-icons-outlined">
                    chevron_right
                </span>
            </div>
        </div>
    );
};
