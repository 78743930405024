import React from 'react';
import './NoEquipmentCard.scss';

export const NoEquipmentCard: React.FC = () => {
    
    return (
            <div className="mm-equipment-card">
                <div className="mm-equipment-card__row">
                    <span className="mm-equipment-card__text">
                        No devices connected.
                    </span>
                </div>
                <div className="mm-equipment-card__row">
                    <span className="mm-equipment-card__text">
                        Click "Add Device" to assign new devices.
                    </span>
                </div>
            </div>
    );
};
