import React from 'react';
import classNames from 'classnames';
import './DairyMarker.scss';
import { IDairy } from './DairiesMapView';

export enum MarkerType {
    Dairy = 0,
    CurrentLocation = 1,
    ManualLocation = 2,
}

interface IDairyMarkerProps {
    lat: number;
    lng: number;
    dairy: IDairy;
}

export const DairyMarker: React.FC<IDairyMarkerProps> = ({ dairy }) => {
    let classNameModifier: string;
    let content: string | null = null;

    if (!dairy.online) {
        classNameModifier = 'mm-dairy-marker--offline';
        content = 'X';
    } else if (!dairy.equipped) {
        classNameModifier = 'mm-dairy-marker--disconnected';
    } else if (dairy.equipped && dairy.alertsCount > 0) {
        classNameModifier = 'mm-dairy-marker--with-alerts';
        content = '' + dairy.alertsCount;
    } else if (dairy.alertsCount === 0 && dairy.totalAlertsCount > 0) {
        classNameModifier = 'mm-dairy-marker--no-visible-alerts';
    } else {
        classNameModifier = 'mm-dairy-marker--no-alerts';
    }

    return (
        <div className={classNames('mm-dairy-marker', classNameModifier)}>
            {content}
            <span className='mm-dairy-marker__tooltip'>
                {dairy.name}
            </span>
        </div>
    );
};
