import { IObject, Objects } from './objects';

export enum StorageKey {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    USER_INFO = 'userInfo',
    DAIRIES_VIEW = 'dairiesView',
    HOME_FILTER = 'homeFilter',
}

export class Storage {
    public static getItem(key: StorageKey): string | null {
        return localStorage.getItem(key);
    }

    public static setItem(key: StorageKey, value: IObject | string): void {
        localStorage.setItem(key, Objects.isObject(value) ? JSON.stringify(value) : value);
    }

    public static removeItem(key: StorageKey): void {
        localStorage.removeItem(key);
    }
}
