import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import { LogType } from '../../../hooks/equipments';
import { useEquipmentsApi } from '../../../hooks/useEquipmentsApi';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import TemperatureGraph from './TemperatureGraph';
import './TemperatureGraphs.scss';
import { useForceRefreshEffect } from '../../../hooks/useForceRefresh.hook';
import { IPipelineEquipmentInfo } from '../../../interfaces/dto/IEquipmentInfo';

dayjs.extend(dayjsUtcPlugin);

interface ITemperatureGraphsProps {
    equipment: IPipelineEquipmentInfo;
}

const TemperatureGraphs: React.FC<ITemperatureGraphsProps> = ({
    equipment,
}) => {
    const { id: equipmentId } = equipment;
    const { getEquipmentLog } = useEquipmentsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [sensorLog, setSensorLog] = useState<ISensorLog[]>();
    useForceRefreshEffect(() => {
        fetchData();
    });

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data: log } = await getEquipmentLog(equipmentId, {
                startDateTime: dayjs().subtract(3, 'month').startOf('day').format(),
                pageSize: 50000,
                sortExpression: 'Timestamp',
                logType: LogType.Temperature,
            });
            setSensorLog(log);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [getEquipmentLog, equipmentId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="mm-temperature-graphs">
            <div className="mm-temperature-graphs__header">
                <div className="mm-temperature-graphs__title">Temperature</div>
            </div>
            <div className="mm-temperature-graphs__content">
                {isLoading && <LoadingSpinnerFullScreen />}
                {!isLoading && sensorLog && sensorLog.length > 0 && (
                    <TemperatureGraph
                        log={sensorLog}
                        targetMilkTemperature={equipment.pipelineSettings?.targetMilkTemperature}
                        targetWashTemperature={equipment.pipelineSettings?.targetWashTemperature}
                    />
                )}
                {!isLoading && (!sensorLog || sensorLog.length === 0) && (
                    <div className="mm-temperature-graphs__no-data">No Data</div>
                )}
            </div>
        </div>
    );
};

export default TemperatureGraphs;
