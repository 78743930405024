export const TOTE_TANKS: Tank[] = [
    {
        type: 'small',
        capacity: 250,
        actualCapacity: 280,
    },
    {
        type: 'medium',
        capacity: 275,
        actualCapacity: 305,
    },
    {
        type: 'large',
        capacity: 350,
        actualCapacity: 380,
    },
];

export const DRUM_TANKS: Tank[] = [
    {
        type: 'small',
        capacity: 15,
        actualCapacity: 18,
    },
    {
        type: 'medium',
        capacity: 30,
        actualCapacity: 33,
    },
    {
        type: 'large',
        capacity: 55,
        actualCapacity: 58,
    },
];


export const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 599px)",
    large: "(min-width: 600px)"
};