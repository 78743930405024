import { useAuth } from './contexts/useAuth';
import { axiosPublic } from '../utils/axios';
import { IUser } from '../interfaces/IUser';
import { useAuthApi } from './useAuthApi';

export interface ILoginResponse {
    userId: string;
    accessToken: string;
    refreshToken: string;
}

export const useLogin = () => {
    const { setAccessToken, setRefreshToken, setUserInfo } = useAuth();
    const { login } = useAuthApi();

    return async (userName: string, password: string, rememberMe: boolean): Promise<IUser> => {
        const { data } = await login(userName, password);

        setAccessToken(data.accessToken);

        if (rememberMe) {
            setRefreshToken(data.refreshToken);
        }

        // TODO: create a standalone hook and use axiosPrivate in there
        const { data: user } = await axiosPublic.get<IUser>('/1/users/me', {
            headers: {
                Authorization: `Bearer ${data.accessToken}`,
            },
        });

        setUserInfo({
            userId: user.userId,
            userName: user.userName,
            organizationId: user.organizationId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            organizationName: user.organizationName,
        });

        return user;
    };
};
