import React from 'react';
import './Switch.scss';

interface ISwitchProps {
    name: string;
    checked?: boolean;
    readOnly?: boolean;
    onChange?: (checked: boolean) => void;
    onClick?: () => void;
    children?: React.ReactNode;
}

export const Switch: React.FC<ISwitchProps> = ({
    name,
    checked,
    readOnly,
    onChange,
    onClick,
    children,
}) => {
    return (
        <div className="mm-switch">
            <input
                className="mm-switch__checkbox"
                id={name}
                type="checkbox"
                checked={checked}
                readOnly={readOnly}
                onChange={e => {
                    if (onChange) {
                        onChange(e.target.checked);
                    }
                }}
            />
            <label
                className="mm-switch__toggle"
                htmlFor={name}
                onClick={e => {
                    if (onClick) {
                        e.stopPropagation();
                        onClick();
                    }
                }}
            >
                <span className="mm-switch__button" />
            </label>
            {children && (
                <label className="mm-switch__label" htmlFor={name}>
                    {children}
                </label>
            )}
        </div>
    );
};
