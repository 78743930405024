import React from 'react';
import ReactPaginate from 'react-paginate';
import './Paginate.scss';

interface IPaginateProps {
    pageCount: number;
    onPageChange: (selectedItem: { selected: number }) => void;
}

const Paginate: React.FC<IPaginateProps> = ({ pageCount, onPageChange }) => {
    return (
        <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={onPageChange}
            renderOnZeroPageCount={() => null}
            containerClassName={'mm-pagination'}
            previousLinkClassName={'mm-pagination__link'}
            nextLinkClassName={'mm-pagination__link'}
            disabledClassName={'mm-pagination__link--disabled'}
            activeClassName={'mm-pagination__link--active'}
        />
    );
};

export default Paginate;
