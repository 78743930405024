import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import { LogType } from '../../../hooks/equipments';
import { useEquipmentsApi } from '../../../hooks/useEquipmentsApi';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import MilkTankTemperatureGraph from './MilkTankStackedGraph';
import './MilkTankGraphs.scss';
import { useForceRefreshEffect } from '../../../hooks/useForceRefresh.hook';
import { IMilkTankEquipmentInfo } from '../../../interfaces/dto/IEquipmentInfo';

dayjs.extend(dayjsUtcPlugin);

interface IMilkTankGraphsProps {
    equipment: IMilkTankEquipmentInfo;
}

const MilkTankGraphs: React.FC<IMilkTankGraphsProps> = ({
    equipment,
}) => {
    const { id: equipmentId } = equipment;    
    const { getEquipmentLog } = useEquipmentsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [temperatureLog, setTemperatureLog] = useState<ISensorLog[]>();
    const [levelLog, setLevelLog] = useState<ISensorLog[]>();
    const [continuityLog, setContinuityLog] = useState<ISensorLog[]>();
    useForceRefreshEffect(() => {
        fetchData();
    });

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data: tempLog } = await getEquipmentLog(equipmentId, {
                startDateTime: dayjs().subtract(3, 'month').startOf('day').format(),
                pageSize: 50000,
                sortExpression: 'Timestamp',
                logType: LogType.Temperature,
            });
            setTemperatureLog(tempLog);

            const { data: lvlLog } = await getEquipmentLog(equipmentId, {
                startDateTime: dayjs().subtract(3, 'month').startOf('day').format(),
                pageSize: 50000,
                sortExpression: 'Timestamp',
                logType: LogType.LiquidLevel,
            });
            setLevelLog(lvlLog);

            const { data: contLog } = await getEquipmentLog(equipmentId, {
                startDateTime: dayjs().subtract(3, 'month').startOf('day').format(),
                pageSize: 50000,
                sortExpression: 'Timestamp',
                logType: LogType.Continuity,
            });
            setContinuityLog(contLog);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [getEquipmentLog, equipmentId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function hasAnyLogs() {
        return (temperatureLog && temperatureLog.length > 0) || (levelLog && levelLog.length > 0) || (continuityLog && continuityLog.length > 0);
    }

    return (
        <div className="mm-temperature-graphs">
            <div className="mm-temperature-graphs__header">
                <div className="mm-temperature-graphs__title">Temperature</div>
            </div>
            <div className="mm-temperature-graphs__content">
                {isLoading && <LoadingSpinnerFullScreen />}
                {!isLoading && hasAnyLogs() && (
                    <MilkTankTemperatureGraph
                        temperatureLog={temperatureLog}
                        levelLog={levelLog}
                        continuityLog={continuityLog}
                        targetMilkTemperature={equipment.milkTankSettings?.targetMilkTemperature}
                        targetWashTemperature={equipment.milkTankSettings?.targetWashTemperature}
                        lastState={equipment.lastState}
                    />
                )}
                {!isLoading && !hasAnyLogs() && (
                    <div className="mm-temperature-graphs__no-data">No Data</div>
                )}
            </div>
        </div>
    );
};

export default MilkTankGraphs;
