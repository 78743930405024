import { useAuth } from './contexts/useAuth';
import { useHomeFilter } from './contexts/useHomeFilter';

export const useLogout = () => {
    const { setAccessToken, setRefreshToken, setUserInfo } = useAuth();
    const { destroy } = useHomeFilter();

    return () => {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                setAccessToken(null);
                setRefreshToken(null);
                setUserInfo(null);
                destroy();
                resolve();
            }, 200);
        });
    };
};
