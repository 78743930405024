import React from 'react';
import { IDairy } from './DairiesListView';
import { DairyCard } from './DairyCard';
import './DairiesList.scss';

interface IDairiesListProps {
    dairies: IDairy[];
}

export const DairiesList: React.FC<IDairiesListProps> = ({ dairies }) => {
    return (
        <div className="mm-dairies-list">
            {dairies &&
                dairies.map(dairy => (
                    <DairyCard
                        key={dairy.id}
                        id={dairy.id}
                        name={dairy.name}
                        address={dairy.address}
                        contact={dairy.contact}
                        hasAlerts={dairy.hasAlert}
                    />
                ))}
        </div>
    );
};
