import React, { useCallback, useState } from 'react';
import { Storage, StorageKey } from '../utils/storage';
import { IAlertType } from '../interfaces/dto/IAlertType';
import { IChemical } from '../interfaces/dto/IChemical';

interface IHomeFilterControl {
    key: string;
    name: string;
    checked: boolean;
}

interface IHomeFilterContext {
    dairyTypes: IHomeFilterControl[];
    toggleDairyType: (key: string) => void;
    allDairyTypesChecked: () => boolean;
    toggleAllDairyTypes: () => void;

    alertTypes: IHomeFilterControl[];
    toggleAlertType: (key: string) => void;
    allAlertTypesChecked: () => boolean;
    toggleAllAlertTypes: () => void;

    chemicals: IHomeFilterControl[];
    toggleChemical: (key: string) => void;
    allChemicalsChecked: () => boolean;
    toggleAllChemicals: () => void;

    isInitialized: boolean;
    initialize: (alertTypes: IAlertType[], chemicals: IChemical[]) => void;
    destroy: () => void;

    setDefault: () => void;
    restoreDefault: () => void;
    
    isFiltered: () => boolean;
}

export const HomeFilterContext = React.createContext<IHomeFilterContext>({
    dairyTypes: [],
    toggleDairyType: () => {},
    allDairyTypesChecked: () => false,
    toggleAllDairyTypes: () => {},

    alertTypes: [],
    toggleAlertType: () => {},
    allAlertTypesChecked: () => false,
    toggleAllAlertTypes: () => {},

    chemicals: [],
    toggleChemical: () => {},
    allChemicalsChecked: () => false,
    toggleAllChemicals: () => {},

    isInitialized: false,
    initialize: () => {},
    destroy: () => {},

    setDefault: () => {},
    restoreDefault: () => {},

    isFiltered: () => false,
});

interface IHomeFilterProvider {
    children: React.ReactNode;
}

interface IHomeFilterStorageSchema {
    dairyTypes: string[];
    alertTypes: string[];
    chemicals: string[];
}

// TODO: Use const variable instead function.
function getDefaultDairyTypes(): IHomeFilterControl[] {
    return [
        {
            key: 'WashWatch',
            name: 'WashWatch',
            checked: true,
        },
        {
            key: 'WirelessMonitor',
            name: 'Wireless monitor',
            checked: true,
        },
        {
            key: 'NotEquipped',
            name: 'Not equipped',
            checked: true,
        },
        {
            key: 'Other',
            name: 'Other dairies',
            checked: true,
        },
    ];
}

export const HomeFilterProvider: React.FC<IHomeFilterProvider> = ({ children }) => {
    const [dairyTypes, setDairyTypes] = useState<IHomeFilterControl[]>(getDefaultDairyTypes());
    const [alertTypes, setAlertTypes] = useState<IHomeFilterControl[]>([]);
    const [chemicals, setChemicals] = useState<IHomeFilterControl[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const toggleDairyType = (key: string) => {
        setDairyTypes(dairyTypes => {
            return dairyTypes.map(dairyType => {
                if (dairyType.key === key) {
                    dairyType.checked = !dairyType.checked;
                }

                return dairyType;
            });
        });
    };

    const allDairyTypesChecked = () => {
        return dairyTypes.length === dairyTypes.filter(d => d.checked).length;
    };

    const toggleAllDairyTypes = () => {
        const allChecked = allDairyTypesChecked();
        setDairyTypes(dairyTypes => {
            return dairyTypes.map(dairyType => {
                dairyType.checked = !allChecked;
                return dairyType;
            });
        });
    };

    const toggleAlertType = (key: string) => {
        setAlertTypes(alertsTypes => {
            return alertsTypes.map(alertType => {
                if (alertType.key === key) {
                    alertType.checked = !alertType.checked;
                }

                return alertType;
            });
        });
    };

    const allAlertTypesChecked = () => {
        return alertTypes.length === alertTypes.filter(a => a.checked).length;
    };

    const toggleAllAlertTypes = () => {
        const allChecked = allAlertTypesChecked();
        setAlertTypes(alertTypes => {
            return alertTypes.map(alertType => {
                alertType.checked = !allChecked;
                return alertType;
            });
        });
    };

    const toggleChemical = (key: string) => {
        setChemicals(chemicals => {
            return chemicals.map(chemical => {
                if (chemical.key === key) {
                    chemical.checked = !chemical.checked;
                }

                return chemical;
            });
        });
    };

    const allChemicalsChecked = () => {
        return chemicals.length === chemicals.filter(c => c.checked).length;
    };

    const toggleAllChemicals = () => {
        const allChecked = allChemicalsChecked();
        setChemicals(chemicals => {
            return chemicals.map(chemical => {
                chemical.checked = !allChecked;
                return chemical;
            });
        });
    };

    const isFiltered = () => {
        return chemicals.filter(c => !c.checked).length > 0 
            || dairyTypes.filter(d => !d.checked).length > 0 
            || alertTypes.filter(a => !a.checked).length > 0 
    } 

    const initialize = useCallback((alertTypes: IAlertType[], chemicals: IChemical[]) => {
        setAlertTypes(
            alertTypes.map(alertType => {
                return {
                    key: alertType.code,
                    name: alertType.name,
                    checked: true,
                } as IHomeFilterControl;
            })
        );

        setChemicals(
            chemicals.map(chemical => {
                return {
                    key: chemical.id.toString(),
                    name: chemical.name,
                    checked: true,
                } as IHomeFilterControl;
            })
        );

        setIsInitialized(true);
        restoreDefault();
    }, []);

    const destroy = useCallback(() => {
        setAlertTypes([]);
        setChemicals([]);
        setIsInitialized(false);
    }, []);

    const setDefault = () => {
        Storage.setItem(
            StorageKey.HOME_FILTER,
            JSON.stringify({
                dairyTypes: dairyTypes.filter(d => d.checked).map(d => d.key),
                alertTypes: alertTypes.filter(d => d.checked).map(d => d.key),
                chemicals: chemicals.filter(d => d.checked).map(d => d.key),
            })
        );
    };

    const restoreDefault = () => {
        try {
            const prevHomeFilterStr = Storage.getItem(StorageKey.HOME_FILTER);
            if (prevHomeFilterStr) {
                const prevHomeFilter = JSON.parse(prevHomeFilterStr) as IHomeFilterStorageSchema;

                setDairyTypes(dairyTypes => {
                    return dairyTypes.map(dairyType => {
                        dairyType.checked = prevHomeFilter.dairyTypes.includes(dairyType.key);
                        return dairyType;
                    });
                });

                setAlertTypes(alertTypes => {
                    return alertTypes.map(alertType => {
                        alertType.checked = prevHomeFilter.alertTypes.includes(alertType.key);
                        return alertType;
                    });
                });

                setChemicals(chemicals => {
                    return chemicals.map(chemical => {
                        chemical.checked = prevHomeFilter.chemicals.includes(chemical.key);
                        return chemical;
                    });
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const value: IHomeFilterContext = {
        dairyTypes,
        toggleDairyType,
        allDairyTypesChecked,
        toggleAllDairyTypes,
        alertTypes,
        toggleAlertType,
        allAlertTypesChecked,
        toggleAllAlertTypes,
        chemicals,
        toggleChemical,
        allChemicalsChecked,
        toggleAllChemicals,
        isInitialized,
        initialize,
        destroy,
        setDefault,
        restoreDefault,
        isFiltered
    };

    return <HomeFilterContext.Provider value={value}>{children}</HomeFilterContext.Provider>;
};
