import { useCallback } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { IContact } from '../interfaces/dto/IContact';

export const useContactsApi = () => {
    const axios = useAxiosPrivate();

    const getContacts = useCallback(
        async (organizationId: number) => {
            return axios.get<IContact[]>(`/2/contacts`, { params: { organizationId } });
        },
        [axios]
    );

    return {
        getContacts,
    };
};
