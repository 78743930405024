import { useState, useCallback } from 'react';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { IFarm } from '../interfaces/dto/IFarm';

export interface IGetFarmsQueryParams {
    organizationId?: number;
    pageIndex?: number;
    pageSize?: number;
    sortExpression?: string;
    search?: string;
    farmTypes?: string[];
    alertTypes?: string[];
    chemicalIds?: string[];
}

export const useGetFarms = () => {
    const axios = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState(false);
    const [farms, setFarms] = useState<IFarm[]>([]);
    const [error, setError] = useState<string | null>(null);

    const getFarms = useCallback(
        async (params?: IGetFarmsQueryParams): Promise<AxiosResponse<IFarm[], any>> => {
            setIsLoading(true);
            setError(null);

            return axios
                .get<IFarm[]>('/1/farms', {
                    params,
                    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
                })
                .then(response => {
                    setFarms(response.data);
                    setIsLoading(false);
                    return response;
                })
                .catch(error => {
                    setIsLoading(false);
                    setError(error.message);
                    return error;
                });
        },
        []
    );

    return {
        isLoading,
        farms,
        error,
        getFarms,
    };
};
