import './SidePanelProfile.scss';
import React from 'react';
import { redirect } from 'react-router-dom';
import { useLogout } from '../../../hooks/useLogout';
import { SidePanel } from './SidePanel';
import { useAuth } from '../../../hooks/contexts/useAuth';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export const SidePanelProfile: React.FC = () => {
  const { userInfo } = useAuth();
  const logout = useLogout();

  const handleLogoutClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    await logout();
    redirect('/login');
  };

  return (
    <SidePanel title="Profile">
      <div className="mm-side-panel__profile">
        <div className="mm-side-panel__profile__section">
          <div className="mm-side-panel__profile__section__title">Username</div>
          <div className="mm-side-panel__profile__section__content">{userInfo?.userName}</div>
        </div>
        <div className="mm-side-panel__profile__section">
          <div className="mm-side-panel__profile__section__title">Full Name</div>
          <div className="mm-side-panel__profile__section__content">{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
        </div>
        <div className="mm-side-panel__profile__section">
          <div className="mm-side-panel__profile__section__title">Email</div>
          <div className="mm-side-panel__profile__section__content">{userInfo?.email}</div>
        </div>
        <div className="mm-side-panel__profile__section">
          <div className="mm-side-panel__profile__section__title">Organization</div>
          <div className="mm-side-panel__profile__section__content">{userInfo?.organizationName}</div>
        </div>
        <div className="mm-side-panel__profile__footer">
          <Button
            variant="contained"
            onClick={handleLogoutClick}
            endIcon={<LogoutIcon />}
            disableElevation
            sx={{ textTransform: 'none' }}
          >
            Logout
          </Button>
        </div>
      </div>
    </SidePanel>
  )
};
