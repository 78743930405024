import './EditDevice.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import EditDeviceForm from './EditDeviceForm';
import { ISensor } from "../../interfaces/dto/ISensor";
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { useDevicesApi } from '../../hooks/useDevicesApi';
import { DeviceType } from '../../enums/DeviceType';

export interface IDeviceView {
    id: number;
    type: string;
    iccid: string;
    farmId: number;
    farmName: string;
    phoneNumber: string;
    sensors: ISensor[];
    dairyEquipments: IEquipmentInfo[];
}

const EditDevice: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { getDevice, setupAdvanced } = useDevicesApi();
    const { getFarm, getFarmEquipment } = useFarmsApi();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [device, setDevice] = useState<IDeviceView>();
    const [assignToFarmId] = useState(searchParams.get('assignToFarmId'));

    useEffect(() => {
        (async () => {
            const fetchDataForEditing = async () => {
                const deviceId = parseInt(id!);
                const { data: device } = await getDevice(deviceId);
                const { data: dairyEquipments } = await getFarmEquipment(device.farmId ?? parseInt(assignToFarmId!), false);
                const { data: farm } = await getFarm(device.farmId ?? parseInt(assignToFarmId!));

                setDevice({
                    id: device.id,
                    type: device.type === DeviceType.WashWatch ? DeviceType.WashWatch : "Wireless monitor",
                    farmId: device.farmId ?? assignToFarmId,
                    farmName: farm.name,
                    iccid: device.iccid,
                    phoneNumber: device.phoneNumber,
                    sensors: device.sensors,
                    dairyEquipments: dairyEquipments
                } as IDeviceView);
            };

            try {
                setIsLoading(true);
                await fetchDataForEditing();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [id, assignToFarmId, getDevice, getFarmEquipment]);

    const handleSaveClick = (farmId: number) => {
        navigate(`/dairy-details/${farmId}`);
    };

    const handleSetupWWDeviceClick = () => {
        if(device) {
            setupAdvanced(device?.id);
        }
    };

    const handleCancelClick = (farmId: number) => {
        navigate(`/dairy-details/${farmId}`);
    };

    if (isLoading || !device) {
        return (
            <div className="mm-edit-device">
                <LoadingSpinnerFullScreen />
            </div>
        );
    }

    return (
        <div className="mm-edit-device">
            <EditDeviceForm
                device={device}
                onSave={handleSaveClick}
                onWWDeviceSetup={handleSetupWWDeviceClick}
                onCancel={handleCancelClick}
            />
        </div>
    );
};

export default EditDevice;
