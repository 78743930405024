import React, { useEffect, useState } from 'react';
import { IUser } from '../interfaces/IUser';
import { Objects } from '../utils/objects';
import { Storage, StorageKey } from '../utils/storage';

export interface IAuthContext {
    accessToken: string | null;
    getAccessToken: () => string | null;
    setAccessToken: (accessToken: string | null) => void;
    refreshToken: string | null;
    getRefreshToken: () => string | null;
    setRefreshToken: (refreshToken: string | null) => void;
    userInfo: IUser | null;
    setUserInfo: (userInfo: IUser | null) => void;
}

export const AuthContext = React.createContext<IAuthContext>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [userInfo, setUserInfo] = useState<IUser | null>(null);

    const getAccessToken = () => Storage.getItem(StorageKey.ACCESS_TOKEN);
    const getRefreshToken = () => Storage.getItem(StorageKey.REFRESH_TOKEN);

    useEffect(() => {
        const accessToken = getAccessToken();
        if (Objects.isSet(accessToken)) {
            setAccessToken(accessToken);
        }

        const refreshToken = getRefreshToken();
        if (Objects.isSet(refreshToken)) {
            setRefreshToken(refreshToken);
        }

        const info = Storage.getItem(StorageKey.USER_INFO);
        if (Objects.isSet(info)) {
            setUserInfo(JSON.parse(info!) as IUser);
        }
    }, []);

    useEffect(() => {
        if (Objects.isSet(accessToken)) {
            Storage.setItem(StorageKey.ACCESS_TOKEN, accessToken!);
        } else {
            Storage.removeItem(StorageKey.ACCESS_TOKEN);
        }
    }, [accessToken]);

    useEffect(() => {
        if (Objects.isSet(refreshToken)) {
            Storage.setItem(StorageKey.REFRESH_TOKEN, refreshToken!);
        } else {
            Storage.removeItem(StorageKey.REFRESH_TOKEN);
        }
    }, [refreshToken]);

    useEffect(() => {
        if (Objects.isSet(userInfo)) {
            Storage.setItem(StorageKey.USER_INFO, userInfo!);
        } else {
            Storage.removeItem(StorageKey.USER_INFO);
        }
    }, [userInfo]);

    const value = {
        accessToken,
        getAccessToken,
        setAccessToken,
        refreshToken,
        getRefreshToken,
        setRefreshToken,
        userInfo,
        setUserInfo,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
