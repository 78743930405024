export enum LogType {
    Undefined = 0,
    Temperature = 1,
    RemainingChemical = 2,
    WashReport = 3,
    Refill = 4,
    Continuity = 5,
    LiquidLevel = 6,
}

export interface IGetSensorLogQueryParams {
    farmId?: number;
    logType?: LogType;
    startDateTime?: string;
    endDateTime?: string;
    pageSize?: number;
    pageIndex?: number;
    sortExpression?: string;
}