import React, { CSSProperties } from 'react';
import { PERCENT } from '../../common/enums';
import { calcRemainingPercent } from '../../utils';

interface ISiloIconProps {
    capacity: number;
    remaining: number;
    hasAlerts: boolean;
}

const ALERT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '90px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const TEXT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '18px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const SiloIcon: React.FC<ISiloIconProps> = ({ capacity, remaining, hasAlerts }) => {
    if (!capacity) {
        return null;
    }

    const remainingPercent =
        remaining > PERCENT.Empty ? calcRemainingPercent(capacity, remaining) : PERCENT.Empty;

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 74.15 130.87"
            enableBackground="new 0 0 74.15 130.87;"
            xmlSpace="preserve"
            height="110px"
        >
            <style type="text/css">
                {`.st0{fill:#2693CA;}
                .st1{opacity:0.52;fill:url(#SILO_GRAD_1);stroke:#000000;stroke-miterlimit:10;}`}
            </style>
            <defs>
                <clipPath id="siloShape">
                    <path
                        d="M73.65,4.77v124.5c0,0.61-0.49,1.1-1.1,1.1H1.6c-0.61,0-1.1-0.49-1.1-1.1V12.71c0-3.92,16.38-7.1,36.58-7.1
    c3.37,0,6.62,0.09,9.72,0.25c0.63,0.03,1.16-0.47,1.16-1.1v0c0-0.61,0.49-1.1,1.1-1.1h23.48C73.16,3.66,73.65,4.16,73.65,4.77z"
                    />
                </clipPath>
                <linearGradient
                    id="SILO_GRAD_1"
                    gradientUnits="userSpaceOnUse"
                    x1="0.5"
                    y1="67.0185"
                    x2="73.65"
                    y2="67.0185"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="0.1235" stopColor="#F5F6F6" />
                    <stop offset="1" stopColor="#000000" />
                </linearGradient>
            </defs>
            <rect
                className="st0"
                x="0"
                y={`${PERCENT.Full - remainingPercent}%`}
                width="100%"
                height="100%"
                clipPath="url(#siloShape)"
            ></rect>
            <path
                className="st1"
                d="M73.65,4.77v124.5c0,0.61-0.49,1.1-1.1,1.1H1.6c-0.61,0-1.1-0.49-1.1-1.1V12.71c0-3.92,16.38-7.1,36.58-7.1
    c3.37,0,6.62,0.09,9.72,0.25c0.63,0.03,1.16-0.47,1.16-1.1v0c0-0.61,0.49-1.1,1.1-1.1h23.48C73.16,3.66,73.65,4.16,73.65,4.77z"
            />
            <rect x="49.8" width="21.73" height="3.05" />
            {hasAlerts && (
                <text style={ALERT_STYLE} x="50%" y="60%">
                    !
                </text>
            )}
            {!hasAlerts && remainingPercent >= 100 && (
                <>
                    <text style={TEXT_STYLE} x="50%" y="40%">
                        Max
                    </text>
                    <text style={TEXT_STYLE} x="50%" y="60%">
                        capacity
                    </text>
                </>
            )}
        </svg>
    );
};

export default SiloIcon;
