import './EquipmentAlertsSubform.scss';

import { FormControlLabel, Stack, Typography } from "@mui/material";
import ContactsAutocomplete from "./ContactsAutocomplete";
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import { IEquipmentAlert, INotificationContact } from "../../interfaces/dto/IEquipmentEdit";
import { Switch } from '../../components/Switch';


export interface IEquipmentAlertsFormValues {
    alertCategories: IEquipmentAlert[];
}

interface IEquipmentAlertsSubformProps {    
    values: IEquipmentAlertsFormValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    farmContacts: IDairyContact[];
    notificationContacts: INotificationContact[];
    setNotificationContacts: (values: INotificationContact[]) => void;
}

const EquipmentAlertsSubform: React.FC<IEquipmentAlertsSubformProps> = ({ values, setFieldValue, farmContacts, notificationContacts, setNotificationContacts }) => {

    const handleChange = (value: IDairyContact[], alertCategory: string) => {                
        setNotificationContacts([
            ...notificationContacts.filter(item => item.alertCategory !== alertCategory),
            ...value.map(item => ({ alertCategory, farmContactId: item.id }))
        ]);
    };

    const getContactsByAlertType = (alertCategory: string): IDairyContact[] => {
        return farmContacts.filter(item =>
            notificationContacts
                .filter(el => el.alertCategory === alertCategory)
                .map(el => el.farmContactId)
                .includes(item.id)
        );
    };


    return (
        <div className='mm-alerts-subform'>
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Notification Settings</Typography>
            <Stack gap={2}>
                {values.alertCategories.map((eqAlertCategory, index) => (
                    <>                        
                        <FormControlLabel                             
                            className="mm-alerts-subform__switch"
                            control={ <Switch
                                name={`alertCategories[${index}].enabled`}
                                checked={ eqAlertCategory.enabled }
                                onChange={checked => {
                                    setFieldValue(`alertCategories[${index}].enabled`, checked );
                                }}
                            /> }
                            labelPlacement='start'
                            label={eqAlertCategory.alertCategoryName}
                        />
                        <ContactsAutocomplete
                            options={farmContacts}
                            value={getContactsByAlertType(eqAlertCategory.alertCategory)}
                            onChange={(value) => {
                                handleChange(value, eqAlertCategory.alertCategory);
                            }}
                        />
                    </>
                ))}
            </Stack>
        </div>
    );
}

export default EquipmentAlertsSubform;