import React from 'react';
import classNames from 'classnames';
import './AddEquipmentTile.scss';

interface IAddEquipmentTileProps {
    onClick: () => void;
}

const MilkTankEquipmentTile: React.FC<IAddEquipmentTileProps> = ({
    onClick,
}) => {
    return (
        <div
            className={classNames('mm-add-equipment-tile')}
            onClick={onClick}
        >
            <div className="mm-add-equipment-tile__image material-icons-outlined">
                add_circle_outline
            </div>
            <div className="mm-add-equipment-tile__text">Add new equipment</div>
        </div>
    );
};

export default MilkTankEquipmentTile;
