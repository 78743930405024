import React from 'react';
import GoogleMapReact, { ChangeEventValue, ClickEventValue } from 'google-map-react';
import { Objects } from '../../utils/objects';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? '';

interface IMapProps {
    center?: { lat: number; lng: number };
    zoom?: number;
    onChildClick?: (key: string) => void;
    onClick?(e: ClickEventValue): any;
    onChange?(e: ChangeEventValue): void;
    onGoogleApiLoaded?(map: any, maps: any): void;
    children?: React.ReactNode;
}

export const Map: React.FC<IMapProps> = ({
    center,
    zoom,
    onChildClick,
    onClick,
    onChange,
    onGoogleApiLoaded,
    children,
}) => {
    const _defaultZoom = 10;

    const _onChildClick = (key: string) => {
        if (Objects.isSet(onChildClick)) {
            onChildClick!(key);
        }
    };

    const _onClick = (e: ClickEventValue) => {
        if (Objects.isSet(onClick) && e.event.target.type === undefined) {
            onClick!(e);
        }
    };

    const _onChange = (e: ChangeEventValue): void => {
        if (Objects.isSet(onChange)) {
            onChange!(e);
        }
    }

    const _handleOnGoogleApiLoaded = (map: any, maps: any) => {
        if (Objects.isSet(onGoogleApiLoaded)) {
            onGoogleApiLoaded!(map, maps);
        }
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={center}
            zoom={zoom ?? _defaultZoom}
            options={(maps: GoogleMapReact.Maps) => ({
                fullscreenControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: maps.MapTypeControlStyle.DEFAULT,
                    position: maps.ControlPosition.TOP_LEFT,
                    mapTypeIds: [
                        maps.MapTypeId.ROADMAP,
                        maps.MapTypeId.SATELLITE,
                        maps.MapTypeId.TERRAIN,
                    ]
                },
            })}
            onChildClick={_onChildClick}
            onClick={_onClick}
            onChange={_onChange}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                _handleOnGoogleApiLoaded(map, maps);
            }}
        >
            {children}
        </GoogleMapReact>
    );
};
