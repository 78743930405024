import React, { useEffect, useRef, useState } from 'react';
import './EquipmentQrCodeReader.scss';
import Button from '@mui/material/Button';
import QrScanner from 'qr-scanner';

interface IEquipmentQrCodeReaderProps {
    onResult: (data: string) => void,
    onClose: () => void;
}

export const EquipmentQrCodeReader: React.FC<IEquipmentQrCodeReaderProps> = ({ onResult, onClose }) => {
    const [qrScanner, setQrScanner] = useState<QrScanner | null>(null);

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef?.current && qrScanner === null) {
            const scanner = new QrScanner(
                videoRef.current,
                (result: QrScanner.ScanResult) => onResult(result.data),
                {
                    highlightScanRegion: true,
                }
            );

            scanner.start();
            setQrScanner(scanner);
        }

        return () => {
            if (qrScanner) qrScanner.destroy();
        }
    }, [onResult, qrScanner, videoRef])


    return (
        <div className="mm-equipment-qr-code-reader">
            <video ref={videoRef}></video>
            <Button
                className='mm-equipment-qr-code-reader__button-close'
                variant='contained'
                onClick={onClose}
                disableElevation
            >
                Close
            </Button>
        </div>
    );
};
