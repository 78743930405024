import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Objects } from '../../utils/objects';
import { useAuth } from '../../hooks/contexts/useAuth';

export const RequireAuth = () => {
    const { accessToken, refreshToken } = useAuth();
    const location = useLocation();

    if (Objects.isUnset(accessToken) && Objects.isUnset(refreshToken)) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
};
