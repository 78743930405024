import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

export const axiosPublic = axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
    },
});
