import React from 'react';
import ReactECharts from 'echarts-for-react';
import classNames from 'classnames';
import { Numbers } from '../../../utils/numbers';
import { TemperatureUnit } from '../../../utils/temperature';
import './TemperatureSensorTile.scss';
import { Button } from '../../../components/Button';

interface ITemperatureSensorTileProps {
    isActive: boolean;
    equipmentName: string;
    currentTemperature?: number;
    hasAlerts: boolean;
    onClick: () => void;
    onEdit: () => void;
}

const TemperatureSensorTile: React.FC<ITemperatureSensorTileProps> = ({
    isActive,
    equipmentName,
    currentTemperature,
    hasAlerts,
    onClick,
    onEdit,
}) => {
    const options = {
        animation: false,
        series: [
            {
                type: 'gauge',
                min: 20,
                max: 200,
                splitNumber: 9,
                axisLine: {
                    lineStyle: {
                        color: [[1, '#000']],
                        width: 2,
                    },
                },
                splitLine: {
                    distance: -12,
                    length: 9,
                    lineStyle: {
                        color: '#000',
                    },
                },
                axisTick: {
                    distance: -8,
                    length: 5,
                    lineStyle: {
                        color: '#000',
                    },
                },
                axisLabel: {
                    distance: -25,
                    color: '#000',
                    fontSize: 12,
                },
                pointer: {
                    offsetCenter: [0, '10%'],
                    icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                    length: '125%',
                    itemStyle: {
                        color: '#E62A2A',
                    },
                },
                anchor: {
                    show: false,
                },
                title: {
                    show: false,
                },
                detail: {
                    show: true,
                    fontSize: 20,
                    offsetCenter: [0, '100%'],
                    formatter: (value: Number) => value ? `${value} ${TemperatureUnit.FAHRENHEIT}` : 'No data'
                },
                data: [
                    {
                        value: currentTemperature,
                        name: TemperatureUnit.FAHRENHEIT,
                    },
                ],
            },
            {
                type: 'gauge',
                min: -7,
                max: 93,
                splitNumber: 9,
                axisLine: {
                    lineStyle: {
                        color: [[1, '#000']],
                        width: 2,
                    },
                },
                splitLine: {
                    distance: 3,
                    length: 9,
                    lineStyle: {
                        color: '#000',
                    },
                },
                axisTick: {
                    distance: 3,
                    length: 5,
                    lineStyle: {
                        color: '#000',
                    },
                },
                axisLabel: {
                    distance: 8,
                    fontSize: 10,
                    color: '#000',
                    formatter: (value: number) => Numbers.round(value),
                },
                pointer: {
                    show: false,
                },
                anchor: {
                    show: false,
                },
                title: {
                    show: false,
                },
            },
        ],
    };

    return (
        <div
            className={classNames('mm-temperature-sensor-tile', {
                'mm-temperature-sensor-tile--active': isActive,
            })}
            onClick={onClick}
        >
            <ReactECharts option={options} />
            {hasAlerts && <div className="mm-temperature-sensor-tile__alert">!</div> }
            <div className="mm-temperature-sensor-tile__equipment-name">{equipmentName}</div>
            {isActive && (
                <div className="mm-temperature-sensor-tile__menu">
                    <Button className="mm-temperature-sensor-tile__button" onClick={onEdit}>Edit</Button>
                </div>
            )}
        </div>

    );
};

export default TemperatureSensorTile;
