import './DairiesMapView.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHomeFilter } from '../../../hooks/contexts/useHomeFilter';
import { IGetFarmsQueryParams, useGetFarms } from '../../../hooks/useGetFarms';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import { DairiesView } from '../Dairies';
import { ClusteredDairiesMap } from './ClusteredDairiesMap';

export interface IDairy {
    id: number;
    name: string;
    lat: number;
    lng: number;
    equipped: boolean;
    online: boolean;
    alertsCount: number;
    totalAlertsCount: number;
}

interface IDairiesMapViewProps {
    onViewChange: (view: DairiesView) => void;
}

export const DairiesMapView: React.FC<IDairiesMapViewProps> = ({ onViewChange }) => {
    const [isMapLoading, setIsMapLoading] = useState<boolean>(true);

    // farms
    const { isInitialized, dairyTypes, alertTypes, chemicals } = useHomeFilter();
    const { getFarms } = useGetFarms();
    const [dairies, setDairies] = useState<IDairy[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInitialized) return;

        const loadFarms = async () => {
            const params: IGetFarmsQueryParams = {
                pageIndex: 1,
                pageSize: 9999,
                farmTypes: dairyTypes.filter(d => d.checked).map(d => d.key),
                alertTypes: alertTypes.filter(a => a.checked).map(a => a.key),
                chemicalIds: chemicals.filter(c => c.checked).map(c => c.key),
            };

            const { data: farms } = await getFarms(params);
            const dairies = farms.map(farm => {
                return {
                    id: farm.farmId,
                    name: farm.name,
                    lat: farm.location.latitude,
                    lng: farm.location.longitude,
                    equipped: farm.equipped,
                    online: farm.online,
                    alertsCount: farm.alertsCount,
                    totalAlertsCount: farm.totalAlertsCount,
                } as IDairy;
            });

            setDairies(dairies);
            setIsMapLoading(false);
        };

        loadFarms();
    }, [getFarms, isInitialized, dairyTypes, alertTypes, chemicals]);

    if (isMapLoading) {
        return <LoadingSpinnerFullScreen />;
    }

    const handleDairyClick = async (id: number) => {
        const dairy = dairies.find(dairy => dairy.id === id);
        if (!dairy) return;

        navigate(`/dairy-sensors/${id}`);
    };

    const handleMarkerClick = async (key: string) => {
        handleDairyClick(parseInt(key));
    };

    const handleChangeView = () => {
        onViewChange(DairiesView.LIST);
    };

    return (
        <div className="mm-dairies-map-view">
            <button className="mm-dairies-map-view__button" onClick={handleChangeView}>
                List view
            </button>
            <ClusteredDairiesMap
                isLoading={isMapLoading}
                dairies={dairies}
                onMarkerClick={handleMarkerClick}
                onDairyLinkClick={handleDairyClick}
            />
        </div>
    );
};
