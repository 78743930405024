import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Stack } from '@mui/system';
import { IGetFarmsQueryParams, useGetFarms } from '../../hooks/useGetFarms';
import { IFarm } from '../../interfaces/dto/IFarm';
import { MenuItem, Select } from '@mui/material';
import { useHomeFilter } from '../../hooks/contexts/useHomeFilter';
import { useDevicesApi } from '../../hooks/useDevicesApi';

interface ISelectDairyDialogProps {
    deviceId: number;
    onClose: (dairyId: number | null ) => void;
}

const SelectDairyDialog: React.FC<ISelectDairyDialogProps> = ({
    deviceId,
    onClose,
}) => {
    const { isInitialized } = useHomeFilter();
    const [selectedDairyId, setDairyId] = useState<number | null>(null);
    const { getFarms } = useGetFarms();
    const { getDevice } = useDevicesApi();
    const [dairies, setDairies] = useState<IFarm[]>([]);

    const loadFarms = async (): Promise<any> => {

        const { data: device } = await getDevice(deviceId);

        const params: IGetFarmsQueryParams = {
            pageIndex: 1,
            pageSize: 9999,
            farmTypes: ['WashWatch', 'WirelessMonitor', 'NotEquipped', 'Other'],
            organizationId: device.organizationId,
            sortExpression: 'Name'
        };
        const { data: dairies } = await getFarms(params);

        setDairies(dairies);
    };  

    useEffect(() => {
        loadFarms();
    }, [isInitialized]);    

    const handleCancel = () => {
        onClose(null);
    };

    const handleOk = () => {
        onClose(selectedDairyId);
    };

    const handleOkDisabled = (): boolean => {
        return !selectedDairyId;
    };

    return (
        <>        
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open
            >
                <DialogTitle>The device will be added to the selected dairy</DialogTitle>
                <DialogContent dividers>
                    <Stack direction="column" spacing={2}>
                        <Select
                            labelId="dairies-dropdown-label"
                            id="dairies-dropdown"
                            size="small"
                            value={selectedDairyId}
                            onChange={event => setDairyId(+event.target.value!)}
                            >
                            {dairies.map(dairy => (
                                <MenuItem key={dairy.farmId} value={dairy.farmId}>
                                    {dairy.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk} disabled={handleOkDisabled()}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SelectDairyDialog;
