import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPrivate, axiosPublic } from '../utils/axios';
import { useAuth } from './contexts/useAuth';
import { ILoginResponse } from './useLogin';
import { AxiosResponse } from 'axios';
import { useAuthApi } from './useAuthApi';

let renewTokensFunc: Promise<AxiosResponse<ILoginResponse, any>> | undefined = undefined;

const useAxiosPrivate = () => {
    const { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken } = useAuth();
    const { renewTokens } = useAuthApi();
    const navigate = useNavigate();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config: any) => {
                if (config && config.headers) {
                    config.headers.Authorization = `Bearer ${getAccessToken()}`;
                }

                return config;
            },
            (error: any) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response: any) => response,
            async (error: any) => {
                const originalRequest = error?.config;
                const responseStatus = error?.response?.status;

                const accessToken = getAccessToken();
                const refreshToken = getRefreshToken();

                if ((responseStatus === 401 && !refreshToken) || responseStatus === 403) {
                    setAccessToken(null);
                    navigate('/login');
                }

                if (responseStatus === 401 && refreshToken && !originalRequest?.sent) {
                    originalRequest.sent = true;

                    try {
                        if (!renewTokensFunc)
                            renewTokensFunc = renewTokens(accessToken, refreshToken);

                        const { data } = await renewTokensFunc;

                        setAccessToken(data.accessToken);
                        setRefreshToken(data.refreshToken);

                        if (data.accessToken) {
                            originalRequest.headers = {
                                ...originalRequest.headers,
                                Authorization: `Bearer ${data.accessToken}`,
                            };
                        }

                        return axiosPublic(originalRequest);
                    }
                    catch (error) {
                        console.log(error);
                    }
                    finally {
                        renewTokensFunc = undefined;
                    }
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, []);

    return axiosPrivate;
};

export default useAxiosPrivate;
