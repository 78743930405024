import React, { CSSProperties } from 'react';
import { TemperatureUnit } from '../../utils/temperature';

interface IHorizontalMilkTankIconProps {
    currentTemperature?: number;
    level: number;
    hasAlerts: boolean;
}

const ALERT_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '80px',
    fill: '#C83F4D',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const TEMPERATURE_STYLE: CSSProperties = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '22px',
    dominantBaseline: 'middle',
    textAnchor: 'middle',
};

const HorizontalMilkTankIcon: React.FC<IHorizontalMilkTankIconProps> = ({ currentTemperature, level, hasAlerts }) => {

    function calcMilkLevel(level: number): number {
        const yPosition = (126 - level);
        return yPosition > 0 ? yPosition : 0;
    }

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 115.1 138.7" height="140px" enable-background="new 0 0 115.1 138.7" xmlSpace="preserve">
            <style type="text/css">
                {`.mth0{fill:#383334;}
                .mth1{fill:#3F3A3B;}
                .mth2{fill:#2793CB;}
                .mth3{clip-path:url(#SVGID_00000172428064733655827640000003740020297656935583_);fill:#EEE9E0;}
                .mth6{fill:none;stroke:#BCBEC0;stroke-width:4;stroke-miterlimit:10;}`}
            </style>
            <g>
                <path className="mth0" d="M74.4,11.6v8.1c-5.3-1.7-11-2.6-16.9-2.6c-5.9,0-11.5,0.9-16.9,2.6v-7.9c0-1.9,1.6-3.5,3.5-3.5h26.9
                    C72.9,8.3,74.4,9.7,74.4,11.6z"/>
                <path className="mth1" d="M93.7,113.6v21.7c0,1.9-1.5,3.4-3.4,3.4H24.5c-1.7,0-3.1-1.4-3.1-3.1v-22c9.7,8.4,22.4,13.4,36.2,13.4
                    C71.4,127,84,121.9,93.7,113.6z"/>
                <path className="mth2" d="M113.1,72.6c0,16.8-7.5,31.9-19.3,42.1c-9.7,8.4-22.4,13.4-36.2,13.4c-13.8,0-26.5-5.1-36.2-13.4
                    C9.5,104.5,2,89.4,2,72.6c0-24.8,16.2-45.8,38.7-52.9c5.3-1.7,11-2.6,16.9-2.6c5.9,0,11.5,0.9,16.9,2.6
                    C96.8,26.8,113.1,47.8,113.1,72.6z"/>
                <g>
                    <defs>
                        <path id="SVGID_1_" d="M113.1,72.6c0,16.8-7.5,31.9-19.3,42.1c-9.7,8.4-22.4,13.4-36.2,13.4c-13.8,0-26.5-5.1-36.2-13.4
                            C9.5,104.5,2,89.4,2,72.6c0-24.8,16.2-45.8,38.7-52.9c5.3-1.7,11-2.6,16.9-2.6c5.9,0,11.5,0.9,16.9,2.6
                            C96.8,26.8,113.1,47.8,113.1,72.6z"/>
                    </defs>
                    <clipPath id="SVGID_00000067204002500934106420000007212504741662118314_">
                        <use xlinkHref="#SVGID_1_" overflow="visible"/>
                    </clipPath>
                    
                    <rect x="2" 
                        y={calcMilkLevel(level)}
                        clip-path="url(#SVGID_00000067204002500934106420000007212504741662118314_)" 
                        fill="#EEE9E0"
                        width="111.1"
                        height="100"/>
                    <use xlinkHref="#SVGID_1_" overflow="visible" fill="none" stroke="#575052" stroke-width="4" stroke-miterlimit="10"/>
                </g>
                <text style={TEMPERATURE_STYLE} x="50%" y="80%">{`${ currentTemperature ? Math.round(currentTemperature) + TemperatureUnit.FAHRENHEIT: "N/A"}`}</text>
            </g>
            <g>
                <line className="mth6" x1="73.1" y1="27.6" x2="77.9" y2="0.3"/>
                <line className="mth6" x1="42" y1="27.6" x2="37.2" y2="0.3"/>
                <polyline className="mth6" points="73.1,27.6 73.1,77.1 42,77.1 42,27.6 73.1,27.6 	"/>
                <line className="mth6" x1="42" y1="60.9" x2="73.1" y2="60.9"/>
                <line className="mth6" x1="42" y1="44.9" x2="73.1" y2="44.9"/>
            </g>
            {hasAlerts && (
                <text style={ALERT_STYLE} x="50%" y="50%">
                    !
                </text>
                )}
            </svg>   
    );
};

export default HorizontalMilkTankIcon;
