import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { useHomeFilter } from '../../hooks/contexts/useHomeFilter';
import { SwitchList } from '../SwitchList';
import { Switch } from '../Switch';
import { Button } from '../Button';
import './HomeFilter.scss';

const HomeFilter: React.FC = () => {
    const homeFilter = useHomeFilter();

    return (
        <div className="mm-nav-filter">
            <Accordion
                className="mm-nav-filter__accordion"
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={["dairies", "alerts", "chemicals"]}
            >
                <AccordionItem uuid="dairies">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <label className="accordion__button-label">Dairies</label>
                            <Switch
                                name="allDairiesSwitch"
                                checked={homeFilter.allDairyTypesChecked()}
                                readOnly={true}
                                onClick={homeFilter.toggleAllDairyTypes}
                            />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <SwitchList
                            options={homeFilter.dairyTypes}
                            onChange={homeFilter.toggleDairyType}
                        />
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="alerts">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <label className="accordion__button-label">Alerts</label>
                            <Switch
                                name="allAlertsSwitch"
                                checked={homeFilter.allAlertTypesChecked()}
                                readOnly={true}
                                onClick={homeFilter.toggleAllAlertTypes}
                            />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <SwitchList
                            options={homeFilter.alertTypes}
                            onChange={homeFilter.toggleAlertType}
                        />
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="chemicals">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <label className="accordion__button-label">Chems</label>
                            <Switch
                                name="allChemsSwitch"
                                checked={homeFilter.allChemicalsChecked()}
                                readOnly={true}
                                onClick={homeFilter.toggleAllChemicals}
                            />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <SwitchList
                            options={homeFilter.chemicals}
                            onChange={homeFilter.toggleChemical}
                        />
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            <Button className="mm-nav-filter__button" onClick={homeFilter.setDefault}>
                Set default
            </Button>
            <Button className="mm-nav-filter__button" onClick={homeFilter.restoreDefault}>
                Restore default
            </Button>
        </div>
    );
};

export default HomeFilter;
