import React, { useEffect, useRef, useState } from 'react';
import { useHomeFilter } from '../../../hooks/contexts/useHomeFilter';
import { IGetFarmsQueryParams, useGetFarms } from '../../../hooks/useGetFarms';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import { MarkerType } from './DairyMarker';
import './EditDairyLocationMapView.scss';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { ClickEventValue, Coords } from 'google-map-react';
import { IDairy } from './DairiesMapView';
import { ClusteredDairiesMap, IClusteredDairiesMapRef, ICustomMarker } from './ClusteredDairiesMap';

interface IFarm {
    id?: number;
    location?: Coords;
}

interface IDairyMarkersMapViewProps {
    currentFarm: IFarm;
    onClose: (location: Coords | undefined) => void;
}

export const EditDairyLocationMapView: React.FC<IDairyMarkersMapViewProps> = ({
    currentFarm,
    onClose
}) => {
    // map
    const [isMapLoading, setIsMapLoading] = useState(true);

    // farms
    const { isInitialized, dairyTypes, alertTypes, chemicals } = useHomeFilter();
    const { getFarms } = useGetFarms();
    const [dairies, setDairies] = useState<IDairy[]>([]);
    const [currentDairy, setCurrentDairy] = useState<ICustomMarker>();
    const [currentLocation, setCurrentLocation] = useState<ICustomMarker>();

    const mapRef = useRef<IClusteredDairiesMapRef>();

    useEffect(() => {
        if (!isInitialized) return;

        const loadFarms = async () => {
            const params: IGetFarmsQueryParams = {
                pageIndex: 1,
                pageSize: 9999,
                farmTypes: dairyTypes.filter(d => d.checked).map(d => d.key),
                alertTypes: alertTypes.filter(a => a.checked).map(a => a.key),
                chemicalIds: chemicals.filter(c => c.checked).map(c => c.key),
            };

            const { data: farms } = await getFarms(params);
            const dairies = farms.map(farm => {
                return {
                    id: farm.farmId,
                    name: farm.name,
                    lat: farm.location.latitude,
                    lng: farm.location.longitude,
                    equipped: farm.equipped,
                    online: farm.online,
                    alertsCount: farm.alertsCount,
                    totalAlertsCount: farm.totalAlertsCount,
                } as IDairy;
            });

            setDairies(dairies.filter(item => item.id !== currentFarm.id));

            const dairy = dairies.find(item => item.id === currentFarm.id);
            if (dairy) {
                setCurrentDairy({
                    id: dairy.id,
                    lat: currentFarm.location ? currentFarm.location?.lat : dairy.lat,
                    lng: currentFarm.location ? currentFarm.location?.lng : dairy.lng,
                    type: MarkerType.ManualLocation
                });
            }

            setIsMapLoading(false);
        };

        loadFarms();
    }, [getFarms, isInitialized, dairyTypes, alertTypes, chemicals, currentFarm]);

    if (isMapLoading) {
        return <LoadingSpinnerFullScreen />;
    }

    const handleClick = async (e: ClickEventValue) => {
        if (currentDairy)
            setCurrentDairy({
                ...currentDairy,
                lat: e.lat,
                lng: e.lng
            });
    }

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                ({ coords }: GeolocationPosition) => {
                    const { latitude, longitude } = coords;

                    setCurrentLocation({
                        id: -1,
                        lat: latitude,
                        lng: longitude,
                        type: MarkerType.CurrentLocation
                    });

                    if (mapRef.current)
                        mapRef.current.setCenterAndZoom({ lat: latitude, lng: longitude }, 18);
                },
                (positionError: GeolocationPositionError) => {
                    alert(positionError.message);
                }
            );
        } else {
            alert('The browser doesn\'t support Geolocation');
        }
    }

    const handleClose = () => {
        const location = currentDairy
            ? { lat: currentDairy?.lat, lng: currentDairy?.lng }
            : undefined;

        onClose(location);
    }

    const getCustomMarkers = (): ICustomMarker[] => {
        const customMarkers = [];
        if (currentDairy)
            customMarkers.push(currentDairy);
        if (currentLocation)
            customMarkers.push(currentLocation);

        return customMarkers;
    };

    return (
        <div className="mm-edit-dairy-location-map-view">
            <div className="mm-edit-dairy-location-map-view__buttons-container">
                <button
                    className="mm-edit-dairy-location-map-view__button--current_location"
                    onClick={handleCurrentLocation}
                    type="button"
                >
                    <MyLocationIcon />
                </button>
                <button
                    className="mm-edit-dairy-location-map-view__button--close"
                    onClick={handleClose}
                    type="button"
                >
                    Close
                </button>
            </div>
            <ClusteredDairiesMap
                isLoading={isMapLoading}
                dairies={dairies}
                customMarkers={getCustomMarkers()}
                onMapClick={handleClick}
                ref={mapRef}
            />
        </div>
    );
};
