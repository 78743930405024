import { useCallback } from 'react';
import { IChemical } from '../interfaces/dto/IChemical';
import useAxiosPrivate from './useAxiosPrivate';

export const useGetChemicals = () => {
    const axios = useAxiosPrivate();

    const getChemicals = useCallback(async (organizationId: number) => {
        return axios
            .get<IChemical[]>(`/1/chemicals`, { params: { organizationId } })
            .then(response => response.data);
    }, []);

    return getChemicals;
};
