import './Navigation.scss';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useHomeFilter } from '../../hooks/contexts/useHomeFilter';
import { useAppAlerts } from '../../hooks/contexts/useAppAlerts';
import smallLogo from '../../assets/img/small-logo.png';
import { useForceRefreshTrigger } from '../../hooks/useForceRefresh.hook';
import { SidePanelProfile } from './SidePanel/SidePanelProfile';
import { SidePanelFilters } from './SidePanel/SidePanelFilters';
import { ClickAwayListener } from '@mui/base';

export enum SidePanelType {
    None = 0,
    Filters = 1,
    Profile = 2,
}

export const Navigation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { hasAlerts } = useAppAlerts();
    const [visibleSidePanel, setVisibleSidePanel] = useState<SidePanelType>(SidePanelType.None);
    const { hasSubscription, triggerForceRefresh } = useForceRefreshTrigger();
    const { isFiltered } = useHomeFilter();

    const switchVisiblePanelTo = (sidePanelType: SidePanelType) => {
        setVisibleSidePanel(visibleSidePanel === sidePanelType ? SidePanelType.None : sidePanelType);
    };

    const handleFiltersClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        switchVisiblePanelTo(SidePanelType.Filters);
    };

    const handleNewDairyClick = () => {
        navigate('/new-dairy');
    };

    const handleAlertsClick = () => {
        navigate('/alerts');
    };

    const handleProfileClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        switchVisiblePanelTo(SidePanelType.Profile);
    };

    const isHomePage = () => {
        return location && location.pathname === '/';
    };

    const handleSidePanelOutsideClick = (e: MouseEvent | TouchEvent): void => {
        if (visibleSidePanel !== SidePanelType.None)
            switchVisiblePanelTo(SidePanelType.None);
    };

    const getSidePanelContainerClassName = () => {
        return visibleSidePanel === SidePanelType.None
            ? "mm-side-panel__container--hidden"
            : "mm-side-panel__container";
    };

    return (
        <>
            <nav className="nav">
                <div className="nav__logo-wrapper">
                    <Link to="/">
                        <img className="nav__logo" src={smallLogo} alt="Logo" />
                    </Link>
                </div>
                <div className="nav__buttons">
                    {isHomePage() && (
                        <div className={isFiltered() ? "nav__filters-button-active" : 'nav__filters-button'} onClick={handleFiltersClick}>
                            <span className="material-icons-outlined">filter_alt</span>
                        </div>
                    )}
                    {isHomePage() && (
                        <div className="nav__add-dairy-button" onClick={handleNewDairyClick}>
                            <span className="material-icons-outlined">add</span>
                        </div>
                    )}
                    {hasAlerts && (
                        <div className="nav__alerts-button" onClick={handleAlertsClick}>
                            <span className="material-icons-outlined">notifications</span>
                        </div>
                    )}
                    {hasSubscription && (
                        <div className="nav__refresh-button" onClick={triggerForceRefresh}>
                            <span className="material-icons-outlined">refresh</span>
                        </div>
                    )}
                    <div className="nav__profile-button" onClick={handleProfileClick}>
                        <span className="material-icons-outlined">person</span>
                    </div>
                </div>
            </nav>
            <ClickAwayListener
                onClickAway={handleSidePanelOutsideClick}
                touchEvent={false}
            >
                <div className={getSidePanelContainerClassName()}>
                    {isHomePage() && visibleSidePanel === SidePanelType.Filters && (
                        <SidePanelFilters />
                    )}
                    {visibleSidePanel === SidePanelType.Profile && (
                        <SidePanelProfile />
                    )}
                </div>
            </ClickAwayListener>
        </>
    );
};
