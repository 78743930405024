export class Numbers {
    public static round(value: number, precision: number = 0): number {
        const multiplier = Math.pow(10, precision);
        return Math.round(value * multiplier) / multiplier;
    }

    public static roundAndFormat(value: number, precision: number = 0, digits: number = 0): string {
        return Numbers.round(value, precision).toFixed(digits);
    }

    public static regExp = /^[0-9\b]+$/;
}
