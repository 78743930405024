import './DairyClusterMarker.scss';

import React, { useState } from 'react';
import classNames from 'classnames';
import { Objects } from '../../../utils/objects';
import { Box, ClickAwayListener } from '@mui/material';

interface IDairy {
    id: number;
    name: string;
    alertsCount: number;
}

interface IDairyClusterMarkerProps {
    lat: number;
    lng: number;
    dairies: IDairy[];
    onDairyLinkClick: (id: number) => void;
}

export const DairyClusterMarker: React.FC<IDairyClusterMarkerProps> = ({
    dairies,
    onDairyLinkClick,
}) => {
    const [showDairiesList, setShowDairiesList] = useState<boolean>(false);
    const alertsCount = dairies.reduce(
        (accumulator, currentValue) => accumulator + currentValue.alertsCount,
        0,
    );

    const dairiesCountToShow = 3;
    const pointsCount = dairies.length;
    const markerSize = 30 + pointsCount / 30 * 50;
    const fontSize = 11 + pointsCount / 2;

    const handleClick = () => {
        setShowDairiesList(!showDairiesList);
    };

    const handleDairyLinkClick = (id: number) => {
        if (Objects.isSet(onDairyLinkClick)) {
            onDairyLinkClick!(id);
        }
    };

    const handleOutsideClick = () => {
        setShowDairiesList(false);
    };

    const classNameModifier = alertsCount > 0
        ? "mm-dairy-cluster-marker--with-alerts"
        : "mm-dairy-cluster-marker--no-alerts";
    const clusterMarkerText = alertsCount > 0 ? alertsCount.toString() : '';

    return (
        <div
            className={classNames('mm-dairy-cluster-marker', classNameModifier)}
            style={{
                height: `${markerSize}px`,
                width: `${markerSize}px`,
                marginTop: `-${markerSize / 2}px`,
                marginLeft: `-${markerSize / 2}px`,
                fontSize: `${fontSize}px`
            }}
            onClick={handleClick}
        >
            {clusterMarkerText}
            <Box
                className='mm-dairy-cluster-marker__tooltip'
                style={{
                    bottom: `${markerSize / 2 + 6}px`,
                    left: `-${markerSize / 2}px`,
                }}
                sx={{
                    '&::after': {
                        marginLeft: `${markerSize / 2 - 16}px`,
                    }
                }}
            >
                {showDairiesList && dairies.length > 0 && (
                    <ClickAwayListener onClickAway={handleOutsideClick}>
                        <ul>
                            {dairies.slice(0, dairiesCountToShow).map(item => (
                                <li
                                    key={item.id}
                                    className='mm-dairy-cluster-marker__tooltip__item-link'
                                    onClick={() => handleDairyLinkClick(item.id)}
                                    title={item.name}
                                >
                                    {item.name}
                                </li>
                            ))}
                            {dairies.length > dairiesCountToShow && (
                                <li className='mm-dairy-cluster-marker__tooltip__item-more'>
                                    ...
                                </li>
                            )}
                        </ul>
                    </ClickAwayListener>
                )}
                {!showDairiesList && (
                    <div className='mm-dairy-cluster-marker__tooltip__item-text'>
                        {`Locations: ${dairies.length}`}
                    </div>
                )}
            </Box>
        </div>
    );
};
