import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IAddress } from '../../interfaces/dto/IAddress';
import { IContact } from '../../interfaces/dto/IContact';
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import { ILocation } from '../../interfaces/dto/ILocation';
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { states } from '../../utils/states';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import EditDairyProfileForm from './EditDairyProfileForm';
import './EditDairyProfile.scss';
import { useContactsApi } from '../../hooks/useContactsApi';
import { useAuth } from '../../hooks/contexts/useAuth';

export interface IFarmView {
    id?: number;
    name: string;
    address: IAddress;
    contacts?: IDairyContact[];
    location?: ILocation;
    organizationContacts: IContact[];
}

const EditDairyProfile: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { userInfo } = useAuth();
    const [searchParams] = useSearchParams();
    const { getFarm } = useFarmsApi();
    const { getContacts } = useContactsApi();
    
    const [isLoading, setIsLoading] = useState(true);
    const [farm, setFarm] = useState<IFarmView>();

    useEffect(() => {
        const fetchDataForEditing = async () => {
            const farmId = parseInt(id!);
            const { data: farm } = await getFarm(farmId);
            const { data: orgContacts } = await getContacts(farm.organizationId);
            
            setFarm({
                id: farm.farmId,
                name: farm.name,
                address: farm.address,
                contacts: farm.contacts,
                location: farm.location,
                organizationContacts: orgContacts,
            } as IFarmView);
        };

        const fetchDataForAdding = async () => {
            const { data: orgContacts } = await getContacts(userInfo!.organizationId);            

            const farm: IFarmView = {
                name: '',
                address: {
                    address1: '',
                    address2: '',
                    state: states[0],
                    city: '',
                    zip: '',
                },
                location: undefined,
                organizationContacts: orgContacts,
            };

            setFarm(farm);
        };

        const fetchData = () => {
            try {
                setIsLoading(true);
                id ? fetchDataForEditing() : fetchDataForAdding();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, getFarm, searchParams]);

    const handleSaveClick = (farmId: number) => {
        navigate(`/dairy-details/${farmId}`);
    };

    const handleCancelClick = () => {
        navigate(-1);
    };

    if (isLoading || !farm) {
        return (
            <div className="mm-edit-dairy">
                <LoadingSpinnerFullScreen />
            </div>
        );
    }

    return (
        <div className="mm-edit-dairy">
            <EditDairyProfileForm
                farm={farm}
                onSave={handleSaveClick}
                onCancel={handleCancelClick}
            />
        </div>
    );
};

export default EditDairyProfile;
