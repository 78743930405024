import './DairiesMapView.scss';

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { LoadingSpinnerFullScreen } from '../../../components/LoadingSpinnerFullScreen';
import { DairyMarker, MarkerType } from './DairyMarker';
import { ChangeEventValue, ClickEventValue, Coords } from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Map } from '../../../components/Map';
import Supercluster from 'supercluster';
import { DairyClusterMarker } from './DairyClusterMarker';
import { IDairy } from './DairiesMapView';
import { CustomMarker } from './CustomMarker';
import { Objects } from '../../../utils/objects';

export interface ICustomMarker extends Coords {
    id: number;
    type: MarkerType
}

interface IClusteredDairiesMapProps {
    isLoading: boolean;
    dairies: IDairy[];
    customMarkers?: ICustomMarker[];
    onMapClick?: (e: ClickEventValue) => void;
    onMarkerClick?: (key: string) => void;
    onDairyLinkClick?: (id: number) => void;
}

export interface IClusteredDairiesMapRef {
    setCenterAndZoom: (location: Coords, zoom: number) => void;
}

export const ClusteredDairiesMap = forwardRef<IClusteredDairiesMapRef | undefined, IClusteredDairiesMapProps>((
    {
        isLoading,
        dairies,
        customMarkers,
        onMapClick,
        onMarkerClick,
        onDairyLinkClick
    },
    ref
) => {
    // map
    const [center, setCenter] = useState<Coords>({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState<number>();
    const [bounds, setBounds] = useState<GeoJSON.BBox | undefined>(undefined);

    useImperativeHandle(ref, () => ({
        setCenterAndZoom(location, zoom,) {
            setCenter(location);
            setZoom(zoom);
        },
    }));

    const _onDairyLinkClick = (id: number) => {
        if (Objects.isSet(onDairyLinkClick)) {
            onDairyLinkClick!(id);
        }
    };

    const points: Supercluster.PointFeature<Supercluster.AnyProps>[] = dairies.map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            id: item.id,
            category: "",
            dairy: item,
        },
        geometry: {
            type: "Point",
            coordinates: [item.lng, item.lat]
        }
    }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: zoom ?? 10,
        options: { radius: 200, maxZoom: 20 },
    });

    if (isLoading) {
        return <LoadingSpinnerFullScreen />;
    }

    const handleMapChange = ({ center, zoom, bounds }: ChangeEventValue) => {
        setCenter(center);
        setZoom(zoom);
        setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
        ]);
    };

    const getDairiesInCluster = (clusterId: number): any => {
        return supercluster?.getLeaves(clusterId, Infinity)
            .map(item => ({
                id: item.properties.dairy.id,
                name: item.properties.dairy.name,
                alertsCount: item.properties.dairy.alertsCount
            }));
    };

    const handleGoogleApiLoaded = (map: any, maps: any) => {
        const bounds = new maps.LatLngBounds();
        dairies.forEach(item => {
            bounds.extend(new maps.LatLng(item.lat, item.lng));
        });

        customMarkers?.filter(item => item.type === MarkerType.ManualLocation)
            .forEach(item => {
                bounds.extend(new maps.LatLng(item.lat, item.lng));
            });

        map.fitBounds(bounds);
    };

    return (
        <Map
            center={center}
            zoom={zoom}
            onClick={onMapClick}
            onChildClick={onMarkerClick}
            onChange={handleMapChange}
            onGoogleApiLoaded={handleGoogleApiLoaded}
        >
            {clusters.length > 0 && clusters.map(item => {
                const [longitude, latitude] = item.geometry.coordinates;
                const {
                    cluster: isCluster,
                    cluster_id: clusterId
                } = item.properties;

                if (isCluster) {
                    return (
                        <DairyClusterMarker
                            key={`cluster_${clusterId}`}
                            lat={latitude}
                            lng={longitude}
                            dairies={getDairiesInCluster(clusterId)}
                            onDairyLinkClick={_onDairyLinkClick}
                        />
                    );
                }

                const { dairy } = item.properties;
                return (
                    <DairyMarker
                        key={dairy.id}
                        lat={dairy.lat}
                        lng={dairy.lng}
                        dairy={dairy}
                    />
                );
            })}
            {customMarkers && customMarkers.map(item => {
                return (
                    <CustomMarker
                        key={item.id}
                        lat={item.lat}
                        lng={item.lng}
                        type={item.type}
                    />
                )
            })}
        </Map>
    );
});
