export class Temperature {
    public static convertCelsiusToFahrenheit(value: number): number {
        return value * (9 / 5) + 32;
    }

    public static convertFahrenheitToCelsius(value: number): number {
        return (value - 32) * (5 / 9);
    }
}

export enum TemperatureUnit {
    CELSIUS = '°C',
    FAHRENHEIT = '°F',
}
