import './CustomMarker.scss';

import React from 'react';
import classNames from 'classnames';
import { MarkerType } from './DairyMarker';

interface ICustomMarkerProps {
    lat: number;
    lng: number;
    type: MarkerType;
}

export const CustomMarker: React.FC<ICustomMarkerProps> = ({ type }) => {
    let classNameModifier: string = "";
    let content: string | null = null;

    if (type === MarkerType.ManualLocation) {
        classNameModifier = 'mm-custom-marker--manual material-icons';
        content = 'flag';
    } else if (type === MarkerType.CurrentLocation) {
        classNameModifier = 'mm-custom-marker--current';
    }

    return (
        <div className={classNames('mm-custom-marker', classNameModifier)}>
            {content}
        </div>
    );
};
