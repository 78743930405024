import React from 'react';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';
import { TemperatureUnit } from '../../../utils/temperature';

interface ITemperatureGraphProps {
    log: ISensorLog[];
    targetWashTemperature?: number;
    targetMilkTemperature?: number;
}

const TemperatureGraph: React.FC<ITemperatureGraphProps> = ({
    log,
    targetWashTemperature,
    targetMilkTemperature,
}) => {
    const options: any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                label: {
                    formatter: (param: { value: string }) =>
                        dayjs(param.value).format('MM/DD/YYYY h:mm A'),
                },
            },
        },
        grid: {
            left: 25,
            right: 30,
            top: 10,
            bottom: 45,
            containLabel: 'true'
        },
        legend: {
            show: true,
            data: ['Target Wash Temperature', 'Target Milk Temperature'],
            selectedMode: false,
            bottom: 0,
            itemWidth: 45,
            itemGap: 10,
            itemStyle: {
                color: 'transparent',
            },
        },
        textStyle: {
            fontFamily: 'Roboto',
            fontWeight: '500',
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
            name: `Temperature, ${TemperatureUnit.FAHRENHEIT}`,
            nameLocation: 'center',
            nameTextStyle: {
                fontSize: 16,
            },
            nameGap: 35,
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: 0,
                minSpan: 1,
                orient: 'horizontal',
                zoomOnMouseWheel: true,
                moveOnMouseMove: true,
                moveOnMouseWheel: false,
                startValue: dayjs().subtract(7, 'day').startOf('day').format(),
            }
        ],
        animation: false,
        series: [
            {
                data: log.map(l => [l.timestamp, l.value]),
                name: `Temperature, ${TemperatureUnit.FAHRENHEIT}`,
                symbol: 'none',
                type: 'line',
                sampling: 'lttb',
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'blue',
                            },
                            {
                                offset: 0.6,
                                color: 'white',
                            },
                        ],
                        global: false,
                    },
                    opacity: 0.3,
                },
            },
        ],
        useUtc: true,
    };

    const maxTemp = Math.max(...log.map(l => Number(l.value)), 150);
    options.yAxis.max = maxTemp;

    if (targetWashTemperature) {
        options.series.push({
            data: [],
            type: 'line',
            name: 'Target Wash Temperature',
            symbol: 'none',
            lineStyle: {
                color: '#ff0000',
                type: 'solid',
            },
            markLine: {
                silent: true,
                symbol: 'none',
                data: [
                    {
                        yAxis: targetWashTemperature,
                        lineStyle: {
                            color: '#ff0000',
                            type: 'solid',
                        },
                    },
                ],
            },
        });
    }

    if (targetMilkTemperature) {
        options.series.push({
            data: [],
            type: 'line',
            name: 'Target Milk Temperature',
            symbol: 'none',
            lineStyle: {
                color: '#ff0000',
                type: 'dashed',
            },
            markLine: {
                silent: true,
                symbol: 'none',
                data: [
                    {
                        yAxis: targetMilkTemperature,
                        lineStyle: {
                            color: '#ff0000',
                            type: 'dashed',
                        },
                    },
                ],
            },
        });
    }

    return <ReactECharts style={{ height: '100%', width: '100%' }} option={options} />;
};

export default TemperatureGraph;
