import React from 'react';
import { PulseLoader } from 'react-spinners';

export interface ILoadingSpinnerProps {
    color?: string;
    cssOverride?: React.CSSProperties;
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
    color = '#1c1c24',
    cssOverride,
}) => {
    return <PulseLoader color={color} cssOverride={cssOverride} />;
};
