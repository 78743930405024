import React, { useState } from 'react';

export interface IAppAlertsContext {
    hasAlerts: boolean;
    setHasAlerts: (newValue: boolean) => void;
}

export const AppAlertsContext = React.createContext<IAppAlertsContext>({
    hasAlerts: false,
    setHasAlerts: () => {},
});

export const AppAlertsProvider = ({ children }: { children: React.ReactNode }) => {
    const [hasAlerts, setHasAlerts] = useState(false);

    const value = {
        hasAlerts,
        setHasAlerts,
    };

    return <AppAlertsContext.Provider value={value}>{children}</AppAlertsContext.Provider>;
};
