import './EditDeviceForm.scss';

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Divider, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { IFormSensorValues, IFormValues } from './EditDeviceForm';
import { TextField } from 'formik-mui';
import { DeviceType } from '../../enums/DeviceType';
import { EquipmentType } from '../../enums/EquipmentType';

export enum SensorType {
    Distance = 'Distance',
    Temperature = 'Temperature',
    Gateway = 'Gateway'
}

interface ISensorsListProps {
    values: IFormValues;
    dairyEquipments: IEquipmentInfo[];
}

const SensorsList: React.FC<ISensorsListProps> = ({
    values,
    dairyEquipments,
}) => {

    function getIsSensorMatchEquipment(equipment: IEquipmentInfo, sensor: IFormSensorValues): boolean {
        switch(equipment.type) {
            case EquipmentType.PIPELINE:
                return sensor.type === SensorType.Temperature;
            default:
                return true;
        }
    }

    return (
        <Box>
            <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold' }}
            >
                Sensors
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: 'bold' }}
                    >
                        Type
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: 'bold' }}
                    >
                        Equipment
                    </Typography>
                </Grid>
            </Grid>
            {values.sensors.map((sensor, index) => (
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={4}>
                        <Typography>
                            { (values.type === DeviceType.WashWatch)
                                && (<div className='mm-edit-device-form__subtitle'>Port #{ sensor.port }</div>)} 
                            <div>{sensor.type}</div>                            
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Field
                            component={TextField}
                            select={true}
                            key={index}                            
                            name={`sensors[${index}].equipmentId`}                            
                            size="small"
                            variant="standard" 
                            fullWidth                            
                        >
                            {dairyEquipments.map(eq => (
                                <MenuItem key={eq.id} value={eq.id} disabled={ !getIsSensorMatchEquipment(eq, sensor) }>
                                    <div className='mm-edit-device-form__subtitle'>{ eq.type }:&nbsp;</div>
                                    <div>{ eq.name }</div>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem key={0} value={0} >
                                Not connected
                            </MenuItem>
                        </Field>
                    </Grid>
                </Grid>
            ))}
        </Box >
    );
};

export default SensorsList;
