import styled from 'styled-components';

export const MapMarkerTooltip = styled.div`
    position: relative;
    bottom: 70px;
    left: -35px;
    transform: translateY(-100%);
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 10px;
    z-index: 100;
    width: 300px;

    &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: -8px;
        left: 25px;
        border-left: 1px solid #707070;
        border-bottom: 1px solid #707070;
        background: #ffffff;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
`;
