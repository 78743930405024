export interface IObject {
    [key: string]: any;
}

export class Objects {
    public static isSet(obj: unknown): boolean {
        return obj !== null && obj !== undefined && !Number.isNaN(obj);
    }

    public static isUnset(obj: unknown): boolean {
        return !Objects.isSet(obj);
    }

    public static isObject(value: unknown): value is IObject {
        return value !== null && typeof value === 'object';
    }
}
