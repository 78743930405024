import React, { useEffect, useRef, useState } from 'react';
import { useAlertsApi } from '../../hooks/useAlertsApi';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import { IAlert } from '../../interfaces/dto/IAlert';
import AlertsList from './AlertsList';
import Paginate from '../../components/Paginate/Paginate';
import './Alerts.scss';
import SelectDairyDialog from './SelectDairyDialog';
import { AlertCode } from '../../components/ActiveAlertCard/AlertService';
import { useNavigate } from 'react-router-dom';

export const Alerts: React.FC = () => {
    const { getAlerts } = useAlertsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [alerts, setAlerts] = useState<IAlert[]>([]);
    const [isDairyDialogOpen, setIsDairyDialogOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState<IAlert|null>(null);

    const navigate = useNavigate();
    const pageIndex = useRef(1);
    const pageCount = useRef(1);

    const loadAlerts = async (): Promise<any> => {
        try {
            setIsLoading(true);

            const { data, headers } = await getAlerts(pageIndex.current, 50);

            pageIndex.current = Number(headers.pageindex);
            pageCount.current = Number(headers.pagecount);

            setAlerts(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadAlerts();
    }, []);

    const handlePageChange = ({ selected }: { selected: number }) => {
        pageIndex.current = selected + 1;
        loadAlerts();
    };

    async function handleSelectDairyDialogClose(dairyId: number | null) {
        setIsDairyDialogOpen(false);
        if (!dairyId) 
            return;
        
        navigate(`/edit-device/${selectedAlert?.deviceId}?assignToFarmId=${dairyId}`);       
    }

    function handleAlertClick(alert: IAlert): void {
        setSelectedAlert(alert);

        if (alert.code === AlertCode.ALERT_SYSTEM_ALIVE_0) {
            setIsDairyDialogOpen(true);
        }
        else if (alert.code === AlertCode.ALERT_EVAL_PERIOD_FINISHED_101) {
            navigate(`/dairy-details/${alert.farmId}/edit-equipment/${alert.equipmentId}?evaluated=true`);
        }
        else if (alert.farmId !== null && alert.farmId !== undefined) {
            navigate(`/dairy-sensors/${alert.farmId}`);
        }
    }

    return (
        <>
            <div className="mm-alerts">
                {!isLoading && <AlertsList items={alerts} onAlertClick={handleAlertClick}/>}
                {isLoading && <LoadingSpinnerFullScreen />}
                <Paginate pageCount={pageCount.current} onPageChange={handlePageChange}></Paginate>
            </div>
            {isDairyDialogOpen && selectedAlert && (
                <SelectDairyDialog
                    deviceId={selectedAlert?.deviceId}
                    onClose={handleSelectDairyDialogClose}
                />
            )}
        </>
    );
};
