import './DairySensors.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import SensorsList from './SensorsList/SensorsList';
import ChemicalGraphs from './ChemicalGraphs/ChemicalGraphs';
import TemperatureGraphs from './TemperatureGraphs/TemperatureGraphs';
import AlertMessage from './AlertMessage/AlertMessage';
import dayjs from 'dayjs';
import { useForceRefreshEffect } from '../../hooks/useForceRefresh.hook';
import { IFarm } from '../../interfaces/dto/IFarm';
import Media from 'react-media';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import { GLOBAL_MEDIA_QUERIES } from '../../common/constants';
import { IChemicalTankEquipmentInfo, IEquipmentInfo, IEquipmentLastStateInfo, IMilkTankEquipmentInfo, IPipelineEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { EquipmentType } from '../../enums/EquipmentType';
import MilkTankGraphs from './MilkTankGraphs/MilkTankGraphs';

const DairySensors: React.FC = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getFarmEquipment, getFarm } = useFarmsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [farm, setFarm] = useState<IFarm>();
    const [equipments, setEquipments] = useState<IEquipmentInfo[]>([]);
    const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentInfo>();
    useForceRefreshEffect(() => {
        fetchData();
    });

    const fetchData = useCallback(async (): Promise<IEquipmentInfo[]> => {
        const farmId = parseInt(id!);
        const { data: farm } = await getFarm(farmId);
        setFarm(farm);

        const { data: equipments } = await getFarmEquipment(farmId, true);

        setEquipments(equipments);

        return equipments;
    }, [id, getFarmEquipment]);

    const selectEquipment = (equipments: IEquipmentInfo[]) => {
        const equipmentId = searchParams.get('equipmentId');
        const equipment = equipments.find(s => s.id === parseInt(equipmentId!));
        if (equipment) {
            setSelectedEquipment(equipment);
        } else {
            equipments = equipments.sort(( s1, s2  )=> {
                const eq1 = s1.type + s1.id;
                const eq2 = s2.type + s2.id;
                return eq1.localeCompare(eq2); } );
            setSelectedEquipment(equipments[0]);
        }
    };

    useEffect(() => {
        initializeEffect();
    }, []);

    const initializeEffect = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const equipments = await fetchData();
            selectEquipment(equipments);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleItemClick = (equipment: IEquipmentInfo) => {
        if (selectedEquipment && selectedEquipment.id === equipment.id) {
            return;
        }

        setSelectedEquipment(equipment);
    };

    const handleEditClick = (equipment: IEquipmentInfo) => {
        navigate(`/dairy-details/${farm?.farmId}/edit-equipment/${equipment.id}`);
    };

    const onAddEquipmentClick = () => {
        navigate(`/dairy-details/${farm?.farmId}/edit-equipment/0`);
    }

    const handleFarmNameClick = () => {
        navigate(`/dairy-details/${farm?.farmId}`);
    };

    const formatDate = (template: string): string => {
        const timestamp = (selectedEquipment as any).lastState?.timestamp;

        return timestamp ? dayjs(new Date(timestamp)).format(template) : "";
    };

    const getShortDatetime = (): string => formatDate(farm?.online ? 'h:mm A' : 'M/DD');
    const getLongDatetime = (): string => formatDate('dddd, MMMM D [at] h:mm A');

    const getReadingStatusClassName = (): string => {
        return farm?.online ? 'last-reading-status__online' : 'last-reading-status__offline';
    }

    if (isLoading) {
        return (
            <div className="mm-dairy-sensors">
                <LoadingSpinnerFullScreen />
            </div>
        );
    }

    return (
        <div className="mm-dairy-sensors">
            <div className="mm-dairy-sensors__farm-name">
                {selectedEquipment && (
                    <div className={getReadingStatusClassName()}>
                        {farm?.online ? <SensorsIcon /> : <SensorsOffIcon />}
                        <Media queries={GLOBAL_MEDIA_QUERIES}>
                            {matches => (
                                matches.small ? getShortDatetime() : getLongDatetime()
                            )}
                        </Media>
                    </div>
                )}
                <div className="link" onClick={handleFarmNameClick}>
                    <span className="link__name">{farm?.name}</span>
                    <span className="material-icons-outlined">chevron_right</span>
                </div>
            </div>
            <div className="mm-dairy-sensors__sensors">
                <SensorsList
                    items={equipments}
                    active={selectedEquipment}
                    onItemClick={handleItemClick}
                    onItemEdit={handleEditClick}
                    onAddEquipmentClick={onAddEquipmentClick}
                />
            </div>            
            {selectedEquipment && (selectedEquipment as IEquipmentLastStateInfo).lastState?.hasAlerts && (
                <div className="mm-dairy-sensors__alert">
                    <AlertMessage equipmentId={selectedEquipment.id}  />
                </div>                
            )}
            <div className="mm-dairy-sensors__graphs">
                {selectedEquipment && selectedEquipment.type === EquipmentType.CHEMICAL_TANK && (
                    <ChemicalGraphs
                        equipment={selectedEquipment as IChemicalTankEquipmentInfo}
                    />
                )}
                {selectedEquipment && selectedEquipment.type === EquipmentType.PIPELINE && (
                    <TemperatureGraphs
                        equipment={selectedEquipment as IPipelineEquipmentInfo}
                    />
                )}
                {selectedEquipment && selectedEquipment.type === EquipmentType.MILK_TANK && (
                    <MilkTankGraphs
                        equipment={selectedEquipment as IMilkTankEquipmentInfo}
                    />
                )}
            </div>
        </div >
    );
};

export default DairySensors;
