import React from 'react';
import './EquipmentCard.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { IDeviceDetails } from '../../interfaces/dto/IDeviceDetails';

interface IEquipmentCardProps {
    equipment: IDeviceDetails,
    online: boolean;
}

export const EquipmentCard: React.FC<IEquipmentCardProps> = ({ equipment, online }) => {
    const navigate = useNavigate();

    const handleEditClick = () => {
        navigate(`/edit-device/${equipment.id}`);
    };

    return (
        <div className="mm-equipment-card">
            <div className="mm-equipment-card__row">
                <span className="mm-equipment-card__text">
                    Device: {equipment.displayType}
                    {online
                        ? <span className="mm-equipment-card__online">Online</span>
                        : <span className="mm-equipment-card__offline">Offline</span>
                    }
                </span>
                <Button className="mm-equipment-card__button" onClick={handleEditClick}>Edit</Button>
            </div>
            <div className="mm-equipment-card__row">
                <span className="mm-equipment-card__text">
                    {equipment.type === 'WashWatch' ? 'ICCID' : 'Hardware ID'}
                </span>
                <span className="mm-equipment-card__text">{equipment.iccid}</span>
            </div>
            { equipment.phoneNumber && (
            <div className="mm-equipment-card__row">
                <span className="mm-equipment-card__text">
                    Phone number
                </span>
                <span className="mm-equipment-card__text">{equipment.phoneNumber}</span>
            </div>)}

            {equipment.sensors && equipment.sensors.sort((a, b) => { return a.port - b.port }).map((sensor, index) => (
                <div key={index} className="mm-equipment-card__row">
                    <span className="mm-equipment-card__text">{sensor.type} sensor</span>
                    <span className="mm-equipment-card__text">{sensor.equipmentId ? sensor.equipmentName : 'No equipment connected'}</span>
                </div>
            ))}
        </div>
    );
};
