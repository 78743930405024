import React, { useState } from 'react';
import { Field, FieldArray } from 'formik';
import { TextField, Autocomplete } from 'formik-mui';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import MuiTextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IFormValues } from './EditDairyProfileForm';
import { AutocompleteRenderInputParams, FormControlLabel } from '@mui/material';
import { Switch } from '../../components/Switch';
import { IContact } from '../../interfaces/dto/IContact';
import SmsNotificationsConsentDialog from './SmsNotificationsConsentDialog';


interface IContactsSubFormProps {    
    values: IFormValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    orgContacts: IContact[];
}

const ContactsSubForm: React.FC<IContactsSubFormProps> = ({ values, setFieldValue, orgContacts }) => {

    const [isConsentPopupOpen, setIsConsentPopupOpen] = useState(false);
    const [newContactIndex, setContactIndex] = useState(0);

    return (
        <>
            <SmsNotificationsConsentDialog 
                open={isConsentPopupOpen}                
                onClose={(agreed, phoneNumber, fullName) => { 
                    setIsConsentPopupOpen(false);
                    if(agreed){
                        // do not create new contact here, only on save
                        setFieldValue(`contacts[${newContactIndex}].contact`, { id: 0, name: fullName, phone: phoneNumber} );
                        setFieldValue(`contacts[${newContactIndex}].contactId`, 0);
                        setFieldValue(`contacts[${newContactIndex}].name`, fullName);
                        setFieldValue(`contacts[${newContactIndex}].phone`, phoneNumber);
                    }
                    else{                        
                    }

                }}
            />            

        <Box>
            <FieldArray name="contacts">
                {({ remove, push }) => (
                    <Stack direction="column" gap={2} alignItems="center">
                        {values.contacts.length === 0 && (
                            <p style={{ color: '#e74c3c' }}>Please add at least one contact</p>
                        )}
                        {values.contacts.length > 0 &&
                            values.contacts.map((contact, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={10}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Field
                                                    name={`contacts[${index}].title`}
                                                    component={TextField}
                                                    size="small"
                                                    variant="standard"
                                                    label="Title"
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                               <Field
                                                    name={`contacts[${index}].contact`}
                                                    freeSolo
                                                    required
                                                    control
                                                    size="small"
                                                    variant="standard"
                                                    component={Autocomplete}                                                    
                                                    options={[ { id: -1, name: '', phone: '' }].concat(orgContacts)}
                                                    getOptionLabel={(x: IContact)=> x.id == -1 ? 'Create new contact ...': `${x.name}: ${x.phone}`}
                                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                                        <MuiTextField 
                                                            variant="standard"                                                            
                                                            required
                                                            placeholder="Search by name or phone number"
                                                            label="Contact"
                                                            {...params} />
                                                    )}                                                    
                                                    onChange={(event:object, value:IContact)=>{
                                                        if(value)
                                                        {
                                                            if(value.id == -1){                                                            
                                                                setContactIndex(index);
                                                                setIsConsentPopupOpen(true);                                                            
                                                            }
                                                            else{
                                                                setFieldValue(`contacts[${index}].contact`, value);
                                                                setFieldValue(`contacts[${index}].contactId`, value?.id);
                                                                setFieldValue(`contacts[${index}].name`, value?.name);
                                                                setFieldValue(`contacts[${index}].phone`, value?.phone);
                                                            }
                                                        }
                                                    }}
                                                    />                                                
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel                                                
                                                    control={ <Switch
                                                        name={`contacts[${index}].enableNotifications`} 
                                                    /> }
                                                    checked={values.contacts[index].enableNotifications}
                                                    labelPlacement='end'
                                                    label="&nbsp;Send notifications"
                                                    onChange={checked => {setFieldValue(`contacts[${index}].enableNotifications`, checked)}}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="100%"
                                        >
                                            <IconButton
                                                color="error"
                                                disabled={values.contacts.length <= 1}
                                                onClick={() => remove(index)}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        {values.contacts.length < 5 && (
                            <IconButton
                                color="success"
                                onClick={() =>
                                    push({
                                        id: 0,
                                        contact: {id: 0, name: '', phone: ''},
                                        name: '',
                                        title: '',
                                        phone: '',
                                        smsNotificationsConsent: 'None'
                                    })
                                }
                            >
                                <AddCircleIcon />
                            </IconButton>
                        )}
                    </Stack>
                )}
            </FieldArray>
        </Box>
        </>
    );
};

export default ContactsSubForm;
