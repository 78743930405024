import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { IFarm } from '../../interfaces/dto/IFarm';
import { LoadingSpinnerFullScreen } from '../../components/LoadingSpinnerFullScreen';
import { AddressCard } from './AddressCard';
import { ContactCard } from './ContactCard';
import { EquipmentCard } from './EquipmentCard';
import { Button } from '../../components/Button';
import './DairyDetails.scss';
import InputEquipmentIdDialog from './InputEquipmentIdDialog';
import { NoEquipmentCard } from './NoEquipmentCard';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IDeviceDetails } from '../../interfaces/dto/IDeviceDetails';
import { useDevicesApi } from '../../hooks/useDevicesApi';

const MESSAGES = {
    deviceNotFoundDialog: {
        content: 'The specified hardware ID was not found in current organization',
        close: 'Close',
    },
};

const DairyDetails: React.FC = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { getFarm, getFarmDevices } = useFarmsApi();
    const { getDevices } = useDevicesApi();
    const [isLoading, setIsLoading] = useState(false);
    const [farm, setFarm] = useState<IFarm>();
    const [devices, setDevices] = useState<IDeviceDetails[]>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const farmId = parseInt(id!);
                const { data: farm } = await getFarm(farmId);
                const { data: devices } = await getFarmDevices(farmId);

                setFarm(farm);
                setDevices(devices);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, getFarm, getFarmDevices]);

    if (!farm || isLoading) {
        return (
            <div className="mm-dairy-details">
                <LoadingSpinnerFullScreen />
            </div>
        );
    }

    const handleEditDairyClick = () => {
        navigate(`/edit-dairy-profile/${farm.farmId}`);
    };

    const handleAddDeviceClick = () => {
        setIsPopupOpen(true);
    };

    const handleSensorsClick = () => {
        navigate(`/dairy-sensors/${id}`);
    };

    async function handleInputEquipmentIdDialogClose(params: any) {
        setIsPopupOpen(false);
        if (!params) return;

        // find equipment by hardware ID and type
        const { data: foundEquipments } = await getDevices({
            organizationId: farm?.organizationId,
            hardwareId: params.hardwareId,
            isFarmAssigned: false
        });

        if (foundEquipments.length > 0) {
            navigate(`/edit-device/${foundEquipments[0]?.id}?assignToFarmId=${farm?.farmId}`);
        } else {
            setIsErrorPopupOpen(true);
        }
    }

    return (
        <>
            <Dialog open={isErrorPopupOpen}>
                <DialogContent>{MESSAGES.deviceNotFoundDialog.content}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsErrorPopupOpen(false);
                        }}
                    >
                        {MESSAGES.deviceNotFoundDialog.close}
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="mm-dairy-details">
                <div className="mm-dairy-details__farm-name">
                    <div className="link" onClick={handleSensorsClick}>
                        <span>Equipment</span>
                        <span className="material-icons-outlined">chevron_right</span>
                    </div>
                </div>
                <div className="mm-dairy-details__content">
                    <div className="mm-dairy-details__first-block">
                        <AddressCard
                            name={farm.name}
                            address1={farm.address.address1}
                            address2={farm.address.address2}
                            city={farm.address.city}
                            state={farm.address.state}
                            zip={farm.address.zip}
                        />
                        {farm.contacts &&
                            farm.contacts.map((contact, index) => (
                                <ContactCard
                                    key={index}
                                    number={++index}
                                    name={contact.name}
                                    title={contact.title}
                                    phone={contact.phone}
                                    enableNotifications={contact.enableNotifications}
                                />
                            ))}
                        <div className='mm-dairy-details__buttons-bar'>
                            <Button className="mm-dairy-details__edit-dairy-button" onClick={handleEditDairyClick}>Edit dairy</Button>
                        </div>
                    </div>
                    <div className="mm-dairy-details__second-block">
                        {devices && devices.length > 0 ? devices.map(device =>
                            <EquipmentCard
                                key={device.id}
                                equipment={device}
                                online={farm.online}
                            />)
                            : <NoEquipmentCard />
                        }
                        <Button className="mm-dairy-details__add-device-button" onClick={handleAddDeviceClick}>Add device</Button>
                    </div>
                    {isPopupOpen && (
                        <InputEquipmentIdDialog
                            hardwareId=''
                            onClose={async params => await handleInputEquipmentIdDialogClose(params)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default DairyDetails;
