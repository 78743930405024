import React from 'react';
import classNames from 'classnames';
import { Numbers } from '../../../utils/numbers';
import DrumIcon from '../../../components/DrumIcon/DrumIcon';
import SiloIcon from '../../../components/SiloIcon/SiloIcon';
import ToteIcon from '../../../components/ToteIcon/ToteIcon';
import './ChemicalSensorTile.scss';
import { DRUM_TANKS, TOTE_TANKS } from '../../../common/constants';
import { find } from 'lodash';
import { calcSiloActualCapacity } from '../../../utils';
import { Button } from '../../../components/Button';

interface IChemicalSensorTileProps {
    isActive: boolean;
    tankType: string;
    capacity: number;
    remaining?: number;
    timeToEmpty?: number;
    chemicalName: string;
    diameter?: number;
    hasAlerts: boolean;
    onClick: () => void;
    onEdit: () => void;
}

const ChemicalSensorTile: React.FC<IChemicalSensorTileProps> = ({
    isActive,
    tankType,
    capacity,
    diameter,
    remaining,
    timeToEmpty,
    chemicalName,
    hasAlerts,
    onClick,
    onEdit,
}) => {
    return (
        <div
            className={classNames('mm-chemical-sensor-tile', {
                'mm-chemical-sensor-tile--active': isActive,
            })}
            onClick={onClick}
        >
            <div className="mm-chemical-sensor-tile__capacity">{capacity} Gals [Max]</div>
            <div className="mm-chemical-sensor-tile__image">
                {tankType === 'Drum' && (
                    <DrumIcon 
                        tank={find(DRUM_TANKS, { capacity })} 
                        remaining={remaining ?? 0} 
                        hasAlerts={hasAlerts} 
                    />
                )}
                {tankType === 'Silo' && (
                    <SiloIcon
                        capacity={calcSiloActualCapacity(capacity, diameter as number)}
                        remaining={remaining ?? 0}
                        hasAlerts={hasAlerts}
                    />
                )}
                {tankType === 'Tote' && (
                    <ToteIcon 
                        tank={find(TOTE_TANKS, { capacity })} 
                        remaining={remaining ?? 0} 
                        hasAlerts={hasAlerts}
                    />
                )}
            </div>
            <div className="mm-chemical-sensor-tile__remaining">
                {remaining && timeToEmpty ? (
                    `${Numbers.roundAndFormat(remaining, 1, 1)} Gals / ${timeToEmpty} days`
                ): 
                remaining ? (`${Numbers.roundAndFormat(remaining, 1, 1)} Gals / ? days`) :
                'No data'}
            </div>
            <div className="mm-chemical-sensor-tile__chemical-name">{chemicalName}</div>
            {isActive && (
                <div className="mm-chemical-sensor-tile__menu">
                    <Button className="mm-chemical-sensor-tile__button" onClick={onEdit}>Edit</Button>
                </div>
            )}
        </div>
    );
};

export default ChemicalSensorTile;
