import React from 'react';
import classNames from 'classnames';
import { Numbers } from '../../../utils/numbers';
import './MilkTankEquipmentTile.scss';
import HorizontalMilkTankIcon from '../../../components/HorizontalMilkTankIcon/HorizontalMilkTankIcon';
import VerticalMilkTankIcon from '../../../components/VerticalMilkTankIcon/VerticalMilkTankIcon';
import { Button } from '../../../components/Button';

interface IMilkTankEquipmentTileProps {
    isActive: boolean;
    equipmentName: string;
    tankType: string;
    tankHeight?: number;
    capacity: number;
    level?: number;
    isFull: boolean;
    currentTemperature?: number;
    timeToFull?: number;
    hasAlerts: boolean;
    onClick: () => void;
    onEdit: () => void;
}

const MilkTankEquipmentTile: React.FC<IMilkTankEquipmentTileProps> = ({
    isActive,
    equipmentName,
    tankType,
    tankHeight,
    capacity,
    level,
    isFull,
    currentTemperature,
    timeToFull,
    hasAlerts,
    onClick,
    onEdit,
}) => {
    function calculateMilkLevel(): number {
        if(level && tankHeight && tankHeight > 0) {
            return Math.round(level / tankHeight * 100);
        }
        else if (isFull) {
            return 100;
        }
        else {
            return 0;
        }
    }

    return (
        <div
            className={classNames('mm-milk-tank-tile', {
                'mm-milk-tank-tile--active': isActive,
            })}
            onClick={onClick}
        >
            <div className="mm-milk-tank-tile__capacity">{capacity} Gals [Max]</div>
            <div className="mm-milk-tank-tile__image">
                {tankType === 'Horizontal' && (
                    <HorizontalMilkTankIcon 
                        currentTemperature={ currentTemperature }
                        level={ calculateMilkLevel()} 
                        hasAlerts={hasAlerts} 
                    />
                )}
                {tankType === 'Vertical' && (
                    <VerticalMilkTankIcon
                        currentTemperature={ currentTemperature }
                        level={calculateMilkLevel()} 
                        hasAlerts={hasAlerts} 
                    />
                )}
            </div>
            <div className="mm-milk-tank-tile__remaining">
                {
                    timeToFull && calculateMilkLevel() ? (
                        `${calculateMilkLevel()}% / full in ${Numbers.roundAndFormat(timeToFull)} min`
                    ): 
                    calculateMilkLevel() ?
                        `${calculateMilkLevel()}% full`
                    : ''
                }
            </div>
            <div className="mm-milk-tank-tile__chemical-name">{equipmentName}</div>
            {isActive && (
                <div className="mm-milk-tank-tile__menu">
                    <Button className="mm-milk-tank-tile__button" onClick={onEdit}>Edit</Button>
                </div>
            )}
        </div>
    );
};

export default MilkTankEquipmentTile;
