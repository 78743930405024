import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import { IconButton, InputAdornment } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { EquipmentQrCodeReader } from './EquipmentQrCodeReader';

interface IInputEquipmentIdDialogProps {
    hardwareId: string;
    onClose: (newValue?: { hardwareId: string }) => void;
}

const InputEquipmentIdDialog: React.FC<IInputEquipmentIdDialogProps> = ({
    hardwareId,
    onClose,
}) => {
    const [selectedHardwareId, setHardwareId] = useState(hardwareId);
    const [qrCodeReaderVisible, setQrCodeReaderVisible] = useState(false);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose({ hardwareId: selectedHardwareId });
    };

    const handleOkDisabled = (): boolean => {
        return !selectedHardwareId;
    };

    const showQrCodeReader = () => setQrCodeReaderVisible(true);
    const hideQrCodeReader = () => setQrCodeReaderVisible(false);

    const handleQrCodeReaderResult = (result: string) => {
        setHardwareId(result);
        setQrCodeReaderVisible(false);
    };

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open
            >
                <DialogTitle>Add new device</DialogTitle>
                <DialogContent dividers>
                    <Stack direction="column" spacing={2}>
                        <TextField
                            id="device-id-input"
                            size="small"
                            label="Hardware ID"
                            value={selectedHardwareId}
                            onChange={event => setHardwareId(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="scan qr code"
                                            onClick={showQrCodeReader}
                                            edge="end"
                                        >
                                            <QrCodeScannerIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk} disabled={handleOkDisabled()}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            {qrCodeReaderVisible && <EquipmentQrCodeReader onResult={handleQrCodeReaderResult} onClose={hideQrCodeReader} />}
        </>
    );
};

export default InputEquipmentIdDialog;
