import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/contexts/useAuth';
import { useHomeFilter } from '../../hooks/contexts/useHomeFilter';
import { useGetAlertTypes } from '../../hooks/useGetAlertTypes';
import { useGetChemicals } from '../../hooks/useGetChemicals';
import { Navigation } from '../Navigation';
import './Layout.scss';

export const Layout: React.FC = () => {
    const { userInfo } = useAuth();
    const { initialize } = useHomeFilter();
    const getAlertTypes = useGetAlertTypes();
    const getChemicals = useGetChemicals();

    useEffect(() => {
        if (userInfo && userInfo.organizationId) {
            (async function () {
                const alertTypes = await getAlertTypes();
                const chemicals = await getChemicals(userInfo.organizationId!);
                initialize(alertTypes, chemicals);
            })();
        }
    }, [userInfo, getAlertTypes, getChemicals, initialize]);

    return (
        <div className="layout">
            <Navigation />
            <main>
                <Outlet />
            </main>
        </div>
    );
};
