import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from 'formik-mui';
import Stack from '@mui/material/Stack';
import MuiTextField from '@mui/material/TextField';
import { states } from '../../utils/states';
import { IFormValues } from './EditDairyProfileForm';

interface INameAndAddressSubFormProps {
    touched: FormikTouched<IFormValues>;
    errors: FormikErrors<IFormValues>;
}

const NameAndAddressSubForm: React.FC<INameAndAddressSubFormProps> = ({ touched, errors }) => {
    return (
        <Stack direction="column" gap={2}>
            <Field
                name="name"
                component={TextField}
                size="small"
                variant="standard"
                label="Name"
                fullWidth
                required
            />
            <Field
                name="address1"
                component={TextField}
                size="small"
                variant="standard"
                label="Address 1"
                fullWidth
                required
            />
            <Field
                name="address2"
                component={TextField}
                size="small"
                variant="standard"
                label="Address 2"
                fullWidth
            />
            <Stack direction="row" gap={1}>
                <Field
                    name="city"
                    component={TextField}
                    size="small"
                    variant="standard"
                    label="City"
                    fullWidth
                    required
                />
                <Field
                    name="state"
                    component={Autocomplete}
                    options={states}
                    size="small"
                    style={{ width: 250 }}
                    disableClearable={true}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                            {...params}
                            name="state"
                            error={touched.state && !!errors.state}
                            helperText={errors.state}
                            variant="standard"
                            label="State"
                            fullWidth
                            required
                        />
                    )}
                />
                <Field
                    name="zip"
                    component={TextField}
                    size="small"
                    variant="standard"
                    fullWidth
                    style={{ width: 100 }}
                    label="Zip"
                    required
                />
            </Stack>
        </Stack>
    );
};

export default NameAndAddressSubForm;
