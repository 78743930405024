import React from 'react';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { ISensorLog } from '../../../interfaces/dto/ISensorLog';
import { TemperatureUnit } from '../../../utils/temperature';

interface IMilkTankTemperatureGraphProps {
    temperatureLog?: ISensorLog[];
    levelLog?: ISensorLog[];
    continuityLog?: ISensorLog[];
    targetWashTemperature?: number;
    targetMilkTemperature?: number;
    lastState?: MilkTankState;
}

const MilkTankTemperatureGraph: React.FC<IMilkTankTemperatureGraphProps> = ({
    temperatureLog,
    levelLog,
    continuityLog,
    targetWashTemperature,
    targetMilkTemperature,
    lastState,
}) => {
    const options: any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                label: {
                    formatter: (param: { value: string }) =>
                        dayjs(param.value).format('MM/DD/YYYY h:mm A'),
                },
            },
        },
        grid: {
            left: 25,
            right: 25,
            top: 10,
            bottom: 45,
            containLabel: 'true'
        },
        legend: {
            show: true,
            data: ['Target Wash Temperature', 'Target Milk Temperature'],
            selectedMode: false,
            bottom: 0,
            itemWidth: 45,
            itemGap: 10,
            itemStyle: {
                color: 'transparent',
            },
        },
        textStyle: {
            fontFamily: 'Roboto',
            fontWeight: '500',
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
        },
        yAxis: [],
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: 0,
                minSpan: 1,
                orient: 'horizontal',
                zoomOnMouseWheel: true,
                moveOnMouseMove: true,
                moveOnMouseWheel: false,
                startValue: dayjs().subtract(1, 'month').startOf('day').format(),
            }
        ],
        animation: false,
        useUtc: true,
        series: []
    };

    var yAxisIndex = 0;
    if(temperatureLog && temperatureLog.length > 0){

        const maxTemp = Math.max(...temperatureLog.map(l => Number(l.value)), 150);
        options.yAxis.push(
            {
                type: 'value',
                name: `Temperature, ${TemperatureUnit.FAHRENHEIT}`,
                nameLocation: 'center',
                nameTextStyle: {
                    fontSize: 16,
                },
                nameGap: 35,
                position: 'left',
                max: maxTemp,
            },
        );

        options.series.push(
            {
                data: temperatureLog.map(l => [l.timestamp, l.value]),
                name: `Temperature, ${TemperatureUnit.FAHRENHEIT}`,
                symbol: 'none',
                type: 'line',
                yAxisIndex: yAxisIndex,
                smooth: true,
                lineStyle: {
                    //color: '#c83e4d',
                    type: 'solid',
                },
            },
        );

        if (targetWashTemperature) {
            options.series.push({
                data: [],
                type: 'line',
                yAxisIndex: yAxisIndex,
                name: 'Target Wash Temperature',
                symbol: 'none',
                lineStyle: {
                    color: '#ff0000',
                    type: 'solid',
                },
                markLine: {
                    silent: true,
                    symbol: 'none',
                    label: {
                        position: 'insideStartTop',
                    },
                    data: [
                        {
                            yAxis: targetWashTemperature,
                            lineStyle: {
                                color: '#ff0000',
                                type: 'solid',
                            },
                        },
                    ],
                },
            });
        }
    
        if (targetMilkTemperature) {
            options.series.push({
                data: [],
                type: 'line',
                name: 'Target Milk Temperature',
                yAxisIndex: yAxisIndex,
                symbol: 'none',
                lineStyle: {
                    color: '#ff0000',
                    type: 'dashed',
                },
                markLine: {
                    silent: true,
                    label: {
                        position: 'insideStartTop',
                    },
                    symbol: 'none',
                    data: [
                        {
                            yAxis: targetMilkTemperature,
                            lineStyle: {
                                color: '#ff0000',
                                type: 'dashed',
                            },
                        },
                    ],
                },
            });
        }
        yAxisIndex++;
    }

    if(levelLog && levelLog.length > 0) {
        options.series.push(
            {
                data: levelLog.map(l => [l.timestamp, l.value]),
                name: `Milk level, inches`,
                symbol: 'none',
                smooth: true,
                type: 'line',
                yAxisIndex: yAxisIndex++,
                lineStyle: {
                    color: '#aaaaff',
                    type: 'solid',
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'blue',
                            },
                            {
                                offset: 0.6,
                                color: 'white',
                            },
                        ],
                        global: false,
                    },
                    opacity: 0.3,
                },
            }
        );

        const maxLevel = Math.max(...levelLog.map(l => Number(l.value)));
        options.yAxis.push(
            {
                type: 'value',
                name: `Milk level, inches`,
                nameLocation: 'center',
                nameTextStyle: {
                    fontSize: 16,
                },
                nameGap: 35,
                position: 'right',
                max: maxLevel,
            }
        );
    }

    if(continuityLog && continuityLog.length > 0 && (!levelLog || levelLog.length === 0)) {
        const nonEmpty = 1;
        const empty = 0;
        options.series.push(
            {
                data: continuityLog
                    .map(l => {
                        return [l.timestamp, l.value];
                    }),
                name: `Milking`,
                symbol: 'none',
                type: 'line',
                sampling: 'lttb',
                step: true,
                yAxisIndex: yAxisIndex++,
                lineStyle: {
                    color: '#aaaaff',
                    type: 'solid',
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'blue',
                            },
                            {
                                offset: 0.9,
                                color: 'white',
                            },
                        ],
                        global: false,
                    },
                    opacity: 0.5,
                },
            }
        );
        
        options.yAxis.push(
            {
                show: false,
                type: 'value',
                name: `Milking`,
                nameLocation: 'center',
                nameTextStyle: {
                    fontSize: 16,
                },
                nameGap: 35,
                position: 'right',
                max: 1,                
            }
        );
    }

    return <ReactECharts style={{ height: '100%', width: '100%' }} option={options} />;
};

export default MilkTankTemperatureGraph;
