import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import Button from '@mui/material/Button';
import { Button as CustomButton } from '../../components/Button';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../hooks/contexts/useAuth';
import { useFarmsApi } from '../../hooks/useFarmsApi';
import { IFarmEdit } from '../../interfaces/dto/IFarmEdit';
import { IFarmCreate } from '../../interfaces/dto/IFarmCreate';
import NameAndAddressSubForm from './NameAndAddressSubForm';
import ContactsSubForm from './ContactsSubForm';
import { IFarmView } from './EditDairyProfile';
import './EditDairyProfileForm.scss';
import { EditDairyLocationMapView } from '../dairies/DairiesMapView/EditDairyLocationMapView';
import { IContact } from '../../interfaces/dto/IContact';
import { useContactsApi } from '../../hooks/useContactsApi';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? '';

const getCoordsByCityAndState = async (
    city: string,
    state: string
): Promise<{ lat: number; lng: number } | null> => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=+${city},+${state}&key=${GOOGLE_API_KEY}`;
    const response = await fetch(url);
    const response_1 = await response.json();
    if (response_1.status === 'OK') {
        return Promise.resolve({
            lat: response_1.results[0].geometry.location.lat,
            lng: response_1.results[0].geometry.location.lng,
        });
    } else {
        return Promise.resolve(null);
    }
};

const getTimezoneByLocation = async (lat: any, lng: any): Promise<string | null> => {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`;
    const response = await fetch(url);
    const responseData = await response.json();
    
    if (responseData.status === 'OK') {
        return Promise.resolve(responseData.timeZoneId);
    } else {        
        return Promise.resolve(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
}

const getInitialValues = (farm: IFarmView): IFormValues => {
    const initialValues: IFormValues = {
        name: farm.name,
        address1: farm.address.address1,
        address2: farm.address.address2 ?? '',
        state: farm.address.state,
        zip: farm.address.zip,
        city: farm.address.city,
        contacts: [],
        location: undefined,
    };

    if (farm.location)
        initialValues.location = { lat: farm.location.latitude, lng: farm.location.longitude };

    initialValues.contacts = farm.contacts
        ? farm.contacts.map(c => {
            return {
            id: c.id,
            contactId: c.contactId,
            contact: {id: c.contactId, name: c.name, phone: c.phone},
            name: c.name,
            title: c.title,
            phone: c.phone,
            enableNotifications: c.enableNotifications} as IFormContact
        })
        : [{ id: 0, contactId: 0, contact: null, name: '', title: '', phone: '', enableNotifications: false } as IFormContact];

    return initialValues;
};

const getValidationSchema = (): any => {
    let validationSchema: any = Yup.object().shape({
        name: Yup.string().required().max(255).label('Name'),
        address1: Yup.string().required().max(255).label('Address 1'),
        address2: Yup.string().optional().max(255).label('Address 2'),
        state: Yup.string().required().max(50).label('State'),
        zip: Yup.string()
            .required()
            .max(50)
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid')
            .label('Zip'),
        city: Yup.string().required().max(50).label('City'),
        contacts: Yup.array()
            .of(
                Yup.object().shape({
                    title: Yup.string().required().label('Title'),
                })
            )
            .min(1),
    });

    return validationSchema;
};

export interface IFormValues {
    name: string;
    address1: string;
    address2: string;
    state: string;
    zip: string;
    city: string;
    contacts: IFormContact[];
    location: { lat: number; lng: number } | undefined;
}

interface IFormContact {
    id: number;
    contactId: number;
    contact: IContact | null;
    name: string;
    title: string;
    phone: string;
    enableNotifications: boolean;
}

interface IEditDairyFormProps {
    farm: IFarmView;
    onSave: (id: number) => void;
    onCancel: () => void;
}

const EditDairyProfileForm: React.FC<IEditDairyFormProps> = ({
    farm,
    onSave,
    onCancel,
}) => {
    const { userInfo } = useAuth();
    const { addFarm, editFarm } = useFarmsApi();
    
    const [mapOpen, setMapOpen] = useState<boolean>(false);    

    const initialValues = getInitialValues(farm);
    const validationSchema = getValidationSchema();

    const getFarmCoords = async (values: IFormValues) => {
        if (values.location) return values.location;

        const coords = await getCoordsByCityAndState(values.city, values.state);
        if (coords === null)
            alert('Unable to process this location.');

        return coords;
    }

    const handleEdit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        const coords = await getFarmCoords(values);
        if (coords === null) return;

        const timezoneId = await getTimezoneByLocation(coords.lat, coords.lng);

        const data: IFarmEdit = {
            name: values.name,
            address: {
                address1: values.address1,
                address2: values.address2 || undefined,
                state: values.state,
                city: values.city,
                zip: values.zip,
            },
            location: {
                name: values.name,
                latitude: coords.lat,
                longitude: coords.lng,
            },
            contacts: values.contacts || [],
            timeZone: timezoneId,
        };

        await editFarm(farm.id!, data);
        helpers.setSubmitting(false);
        onSave(farm.id!);
    };

    const handleAdd = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        const coords = await getFarmCoords(values);
        if (coords === null) return;
        const timezoneId = await getTimezoneByLocation(coords.lat, coords.lng);

        const data: IFarmCreate = {
            name: values.name,
            organizationId: userInfo?.organizationId!,
            other: false,
            address: {
                address1: values.address1,
                address2: values.address2 || undefined,
                state: values.state,
                city: values.city,
                zip: values.zip,
            },
            location: {
                name: values.name,
                latitude: coords.lat,
                longitude: coords.lng,
            },
            contacts: values.contacts || [],
            timeZone: timezoneId,
        };

        const { data: farm } = await addFarm(data);
        helpers.setSubmitting(false);
        onSave(farm.farmId);
    };

    const handleSubmit = (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            helpers.setSubmitting(true);
            if (farm.id) {
                handleEdit(values, helpers);
            } else {
                handleAdd(values, helpers);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showMap = () => setMapOpen(true);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, touched, errors, submitForm, isSubmitting, isValid, setFieldValue }) => {
                return (
                    <Form className="mm-edit-dairy-form" noValidate={true} autoComplete="off">
                        {mapOpen && (
                            <EditDairyLocationMapView
                                currentFarm={{
                                    id: farm.id,
                                    location: values.location
                                }}
                                onClose={(location) => {
                                    setFieldValue("location", location);
                                    setMapOpen(false);
                                }}
                            />
                        )}
                        {!mapOpen && (
                            <>
                                <div className="mm-edit-dairy-form__header">{farm.id ? 'Edit Dairy' : 'New Dairy'}</div>
                                <div className="mm-edit-dairy-form__content">
                                    <div className="mm-edit-dairy-form__first-block">
                                        <Stack direction="column" spacing={2}>
                                            <NameAndAddressSubForm touched={touched} errors={errors} />
                                            <CustomButton onClick={showMap}>
                                                Set Location
                                            </CustomButton>
                                        </Stack>
                                    </div>
                                    <div className="mm-edit-dairy-form__second-block">
                                        <Stack direction="column" spacing={2}>
                                            <ContactsSubForm values={values} setFieldValue={setFieldValue} orgContacts={farm.organizationContacts} />
                                        </Stack>
                                    </div>
                                </div>
                                <div className="mm-edit-dairy-form__footer">
                                    <Button
                                        variant="contained"
                                        disabled={!values.name || !isValid || isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Save & Exit
                                    </Button>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditDairyProfileForm;
