import './EditMilkTankForm.scss';

import { useState } from 'react';
import * as Yup from 'yup';
import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { IDairyContact } from "../../interfaces/dto/IDairyContact";
import { INotificationContact } from '../../interfaces/dto/IEquipmentEdit';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField, Select } from 'formik-mui';
import { IEquipmentInfo } from '../../interfaces/dto/IEquipmentInfo';
import { IEquipmentEdit } from '../../interfaces/dto/IEquipmentEdit';
import { useEquipmentsApi } from '../../hooks/useEquipmentsApi';
import { useParams } from 'react-router-dom';
import { MilkTankType } from '../../enums/MilkTankType';
import { TemperatureUnit } from '../../utils/temperature';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EquipmentAlertsSubform, { IEquipmentAlertsFormValues } from './EquipmentAlertsSubform';


// All messages for this component.
const MESSAGES = {
    evaluatedSettingsDialog: {
        content: 'The form fields will be recalculated based on device log for the last 3 days. Do you want to continue?',
        confirm: 'Yes',
        cancel: 'No',
    },
};

const tankTypes = [MilkTankType.HORIZONTAL, MilkTankType.VERTICAL];

export interface IFormValues extends IEquipmentAlertsFormValues{
    name: string;
    tankType: string;
    capacity: number;
    tankHeight: number;
    refillTime: number;
    targetWashTemperature: number;
    targetMilkTemperature: number;
    warmTimer: number;
    fullReminderMinutes?: number;
    notificationContacts: INotificationContact[];
}

const getInitialValues = (equipment: IEquipmentInfo): IFormValues => {
    const { name, milkTankSettings, notificationContacts, alertCategories } = equipment;

    let initialValues: IFormValues = {
        name: name,
        tankType: MilkTankType.HORIZONTAL,
        capacity: 0,
        refillTime: 0,
        tankHeight: 0,
        targetWashTemperature: 120,
        targetMilkTemperature: 37,
        warmTimer: 0,

        notificationContacts: notificationContacts,
        alertCategories: alertCategories,
    };

    if (milkTankSettings) {
        initialValues = {
            name: name,
            tankType: milkTankSettings.tankType,
            capacity: milkTankSettings.capacity,
            tankHeight: milkTankSettings.tankHeight,
            refillTime: milkTankSettings.refillTime,
            targetWashTemperature: milkTankSettings.targetWashTemperature,
            targetMilkTemperature: milkTankSettings.targetMilkTemperature,
            warmTimer: milkTankSettings.warmTimer,
            notificationContacts: notificationContacts,
            fullReminderMinutes: milkTankSettings.fullReminderMinutes,
            alertCategories: alertCategories,
        };
    }

    return initialValues;
}

const getValidationSchema = (): any => {
    let validationSchema: any = Yup.object().shape({
        name: Yup.string()
            .required(),
        tankType: Yup.string()
            .required(),
        capacity: Yup.number()
            .integer()
            .label('Capacity')
            .typeError('Capacity must be a number'),
        tankHeight: Yup.number()
            .integer()
            .label('Tank height')
            .typeError('Tank height must be a number'),
        refillTime: Yup.number()
            .integer()
            .label('Refill time in minutes')
            .typeError('Tank Refill time must be a number'),
        targetWashTemperature: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Highest wash temperature')
            .typeError('Highest wash temperature must be a number'),
        targetMilkTemperature: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Target milk temperature')
            .typeError('Target milk temperature must be a number'),
        warmTimer: Yup.number()
            .positive()
            .integer()
            .required()
            .label('Warm milk timer')
            .typeError('Warm milk timer must be a number'),
        tankFullReminderMinutes: Yup.number()
            .positive()
            .integer()
            .label('Tank full reminder timer in minutes')
            .typeError('Tank full reminder timer must be a number'),
    });

    return validationSchema;
}

interface IEditMilkTankFormProps {
    equipment: IEquipmentInfo;
    farmContacts: IDairyContact[];
    farmName: string;
    onCancel: () => void;
    onSave: () => void;
    onChangeEvaluatedState: () => void;
}

const EditMilkTankForm: React.FC<IEditMilkTankFormProps> = ({
    equipment,
    farmContacts,
    farmName,
    onCancel,
    onSave,
    onChangeEvaluatedState,
}) => {
    const { farmId, equipmentId } = useParams();
    const { setEquipment, createEquipment } = useEquipmentsApi();
    const [notificationContacts, setNotificationContacts] = useState<INotificationContact[]>(equipment.notificationContacts);

    const initialValues = getInitialValues(equipment);
    const validationSchema = getValidationSchema();
    const [isOpen, setIsOpen] = useState(false);

    const handleEdit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
            
        const equipmentEdit: IEquipmentEdit = {
            farmId: parseInt(farmId!),
            name: values.name,
            type: "MilkTank",
            notificationContacts: notificationContacts,
            alertCategories: values.alertCategories,
            milkTankSettings: {
                tankType: values.tankType,
                capacity: +values.capacity,
                refillTime: +values.refillTime,
                tankHeight: +values.tankHeight,
                targetWashTemperature: +values.targetWashTemperature,
                targetMilkTemperature: +values.targetMilkTemperature,
                warmTimer: +values.warmTimer,
                fullReminderMinutes: values.fullReminderMinutes,
            },
        };

        if (parseInt(equipmentId!) === 0) {
            await createEquipment(equipmentEdit);
        }
        else {
            await setEquipment(parseInt(equipmentId!), equipmentEdit);
        }

        helpers.setSubmitting(false);
        onSave();
    }

    const handleSubmit = (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            helpers.setSubmitting(true);
            handleEdit(values, helpers);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <Dialog open={isOpen}>
                <DialogContent>{MESSAGES.evaluatedSettingsDialog.content}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onChangeEvaluatedState();
                            setIsOpen(false);
                        }}
                    >
                        {MESSAGES.evaluatedSettingsDialog.confirm}
                    </Button>
                    <Button onClick={() => setIsOpen(false)}>
                        {MESSAGES.evaluatedSettingsDialog.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, submitForm, isSubmitting, isValid, setFieldValue }) => {
                    return (
                        <Form
                            className="mm-edit-milk-tank-form"
                            noValidate
                            autoComplete="off"
                        >
                            <div className="mm-edit-milk-tank-form__header">
                                <div className="mm-edit-milk-tank-form__header__subtitle">{farmName}</div>
                                <div className="mm-edit-milk-tank-form__header__title">{equipment.id === 0 ? 'New milk tank': equipment.name}</div>
                            </div>
                            <div className="mm-edit-milk-tank-form__content">
                                <div className="mm-edit-milk-tank-form__first-block">
                                    <Stack gap={2}>
                                        <Field
                                            name="name"
                                            label="Name"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                        />
                                        <Typography
                                            sx={{ textAlign: 'center', fontWeight: 'bold', pt: 1 }}
                                        >
                                            Tank Settings
                                        </Typography>

                                        <Field
                                            component={Select}
                                            name="tankType"
                                            size="small"
                                            variant="standard"
                                        >
                                            {tankTypes.map(type => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <Field
                                            name="capacity"
                                            label="Capacity"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                        />
                                        <Field
                                            name="tankHeight"
                                            label="Tank height"
                                            component={TextField}
                                            variant="standard"
                                            size="small"
                                        />
                                        <Typography
                                            sx={{ textAlign: 'center', fontWeight: 'bold', pt: 1 }}
                                        >
                                            Alert Settings
                                        </Typography>
                                        <Field
                                                name="targetWashTemperature"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label={`Highest wash temperature, ${TemperatureUnit.FAHRENHEIT}`}
                                                fullWidth
                                            />
                                            <Field
                                                name="targetMilkTemperature"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label={`Target milk temperature, ${TemperatureUnit.FAHRENHEIT}`}
                                                fullWidth
                                            />
                                            <Field
                                                name="warmTimer"
                                                component={TextField}
                                                size="small"
                                                variant="standard"
                                                label="Warm milk timer, minutes"
                                                fullWidth
                                            />                                    
                                            <Field
                                                name="refillTime"
                                                label="Tank refill time in minutes"
                                                component={TextField}
                                                variant="standard"
                                                size="small"
                                            />
                                            <Field
                                                name="fullReminderMinutes"
                                                label="Tank full reminder timer in minutes"
                                                component={TextField}
                                                variant="standard"
                                                size="small"
                                            />
                                    </Stack>
                                </div>
                                <div className="mm-edit-milk-tank-form__second-block">
                                    <EquipmentAlertsSubform
                                        farmContacts={farmContacts}
                                        notificationContacts={notificationContacts}
                                        setNotificationContacts={setNotificationContacts}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />                              
                                </div>
                            </div>
                            <div className="mm-edit-milk-tank-form__footer">
                                <div className="mm-edit-milk-tank-form__footer__actions">
                                    <Button
                                        variant="contained"
                                        disabled={!isValid || isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Save & Exit
                                    </Button>
                                    <Button variant="contained" onClick={() => setIsOpen(true)}>
                                        Evaluate
                                    </Button>
                                </div>
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik >
        </>
    )
}

export default EditMilkTankForm;

