import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Switch } from '../../components/Switch';
import { Button, FormControlLabel, Link, TextField, Typography } from '@mui/material';

interface ISmsNotificationsConsentDialogProps {
    open: boolean;    
    onClose: (isConsentGiven: Boolean, phoneNumber: string, fullName: string) => void;
}

const SmsNotificationsConsentDialog: React.FC<ISmsNotificationsConsentDialogProps> = ({
    open,
    onClose,
}) => {

    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleCancel = () => {
        onClose(false, '', '');
        setPhoneNumber('');
        setFullName('');
    };

    const handleOk = () => {
        onClose(isConsentGiven, phoneNumber, fullName);
        setPhoneNumber('');
        setFullName('');
    };

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="sm"
                open={open}
            >
                <DialogTitle>Join Marketlink Mobile and MilkTrak notifications</DialogTitle>
                <DialogContent>
                    <Typography paragraph>
                        Text STOP to opt-out. Standard message and data rates apply.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Full Name"
                        fullWidth
                        required
                        variant="standard"
                        value={fullName}
                        onChange={e => {
                            setFullName(e.target.value);
                        }}                        
                    />                   
                    <TextField                        
                        margin="dense"
                        id="name"
                        label="Phone Number"
                        fullWidth
                        required
                        variant="standard"
                        value={phoneNumber}
                        onChange={e => {
                            setPhoneNumber(e.target.value);
                        }}                        
                    />
                    </DialogContent>
                    <DialogContent>
                    <Typography paragraph>
                        <Link target='_blank' href='https://marketlinkmobile.com/terms-of-service'>Terms of Service</Link>
                        <br/>
                        <Link target='_blank' href='https://marketlinkmobile.com/privacy-policy'>Privacy Policy</Link>
                    </Typography>

                    <FormControlLabel                                                                
                        control={ <Switch                                                
                            name="consent"
                            checked={ isConsentGiven }
                            onChange={checked => {
                                setIsConsentGiven(checked);
                            }}
                        /> }
                        labelPlacement='end'
                        label={<Typography paddingLeft='10px'>I would like to receive alerts and notifications regarding dairies.</Typography>}
                />                     
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' disabled={!(isConsentGiven && phoneNumber && fullName)} onClick={handleOk}>Submit</Button>
                    <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>           
        </>
    );
};

export default SmsNotificationsConsentDialog;
