import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './LoadingSpinnerFullScreen.scss';

export const LoadingSpinnerFullScreen: React.FC = () => {
    return (
        <>
            <div className="mm-loading-spinner-full-screen">
                <LoadingSpinner
                    cssOverride={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </div>
        </>
    );
};
